import { Page, TabBar, TextSkeleton } from '@revolut/ui-kit'
import { useQueryPermission } from 'queries/idave/permissions'
import { IDAVE_PERMISSIONS } from 'security'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { Permission as PermissionType } from 'api/idave/permissions'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { TabBarLink } from 'components/TabBarLink'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useHeaderActions } from 'components/HeaderActions'
import { NoAccessPage } from 'components/NoAccessWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import { PermissionLog } from './components/PermissionLog'
import { PermissionTab } from './types'
import { PermissionSkeleton } from './components/PermissionSkeleton'
import { PermissionDetails } from './components/PermissionDetails'
import { PermissionUsers } from './components/PermissionUsers'
import { PermissionRoles } from './components/PermissionRoles'

type PermissionProps = {
  permissionId: string
  tab: PermissionTab
}

export const Permission = ({ tab, permissionId }: PermissionProps) => {
  const {
    data: permission,
    status,
    refetch,
    fetchStatus,
  } = useQueryPermission(permissionId)
  const onBackClick = useNavigateToList({
    listUrl: Url.Permissions,
    entityUrl: generatePath(Url.Permissions, { permissionId }),
  })

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={permission}
      renderLoading={() => (
        <Outer permissionId={permissionId} toPermissions={onBackClick}>
          <PermissionSkeleton tab={tab} />
        </Outer>
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={permissionId}
          title="Something went wrong"
          description="Requested permission info fetch failed"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => (
        <NoAccessPage pageTitle={permissionId} onBackClick={onBackClick} />
      )}
      renderSuccess={({ data }) => (
        <Outer permissionId={permissionId} permission={data} toPermissions={onBackClick}>
          <Inner permission={data} tab={tab} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  permissionId: string
  children: React.ReactNode
  permission?: PermissionType
  toPermissions: () => void
}) => {
  const { children, permissionId, permission, toPermissions } = props

  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header onBack={toPermissions} actions={HeaderActions && <HeaderActions />}>
        {permission?.name || <TextSkeleton size={15} />}
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.PermissionDetails, { permissionId })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.ROLES_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.PermissionRoles, { permissionId })}>
              Roles
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.PermissionUsers, { permissionId })}>
              Users
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.PermissionAuditLogs, { permissionId })}>
              Audit Logs
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = (props: { permission: PermissionType; tab: PermissionTab }) => {
  const { permission, tab } = props
  switch (tab) {
    case 'roles':
      return <PermissionRoles permissionId={permission.id} />
    case 'auditLog':
      return <PermissionLog permissionId={permission.id} />
    case 'users':
      return <PermissionUsers permissionId={permission.id} />
    default:
    case 'details':
      return <PermissionDetails permission={permission} />
  }
}
