export const POLICY_ASSIGN = 'By policy'
export const MANUAL_ASSIGN = 'Manually'
export const UNKNOWN_ASSIGN = '-'

type AssignStatus = typeof POLICY_ASSIGN | typeof MANUAL_ASSIGN | typeof UNKNOWN_ASSIGN

export type Role = {
  id: string
  roleName: string
  assigned: AssignStatus
  client?: string
  expirationDate?: number
}
