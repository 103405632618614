import { Action } from 'components/HeaderActions/types'
import { useQueryCrossCheckDetails } from 'queries/idave/crossChecks'
import { matchPath } from 'react-router'
import { Url } from 'routing'

export const useShouldShowInfo = (pageKey?: string) => {
  /**
   * Room for improvement here
   * Rework it if behavior became more complex than just:
   *  - show info for pages in config
   *  - show info for xCheck page only if it's role assignment x-check
   */
  const isIdaveCrossCheck = pageKey === Url.CrossCheckIdaveDetails
  const match = pageKey
    ? matchPath({ path: pageKey }, window.location.pathname)
    : undefined
  const crossCheckId = isIdaveCrossCheck ? match?.params?.crossCheckId || '' : ''
  const { data: crossCheck } = useQueryCrossCheckDetails({
    params: crossCheckId,
    options: {
      enabled: !!crossCheckId,
    },
  })

  return isIdaveCrossCheck ? crossCheck?.executableName === 'RoleAssign' : !!pageKey
}

/**
 * Adds actions to every page in array
 * Just convenient way to add actions to every mentioned tab/page
 */
export const addActionsToPages = (pages: string[], actions: Action[]) =>
  pages.reduce<Record<string, Action[]>>((acc, page) => ({ ...acc, [page]: actions }), {})
