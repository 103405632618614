import { Box, Button, Popup } from '@revolut/ui-kit'
import { UserList } from 'components/UserList'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useState } from 'react'

export const UsersSelectPopup = (props: {
  onAdd: (values: string[]) => void
  addedUsers: string[]
}) => {
  const { onAdd, addedUsers } = props
  const { scrollRef } = useEditPopup()
  const [userIds, setUserIds] = useState<string[]>([])

  const onChange = useCallback((ids: string[]) => setUserIds(ids), [setUserIds])

  const onSubmitClick = useCallback(() => {
    onAdd(userIds)
  }, [userIds, onAdd])

  return (
    <>
      <Box mt="-s-24">
        <UserList
          onChange={onChange}
          variant="checkbox"
          scrollRef={scrollRef}
          excludeInactive
          usePeopleOpsIds
          exclude={addedUsers}
        />
      </Box>
      <Popup.Actions>
        <Button
          variant="primary"
          elevated
          onClick={onSubmitClick}
          disabled={!userIds?.length}
        >
          Add employee
        </Button>
      </Popup.Actions>
    </>
  )
}
