import { notNullable } from 'utils/common'
import { stringifyBoolean } from 'utils/boolean/stringifyBoolean'
import { getDetailRow } from './utils'
import { DetailParams } from './types'
import { DetailRow } from '../../types'

export const getClientInfo = (params: DetailParams): DetailRow[] => {
  const { entity, crossCheck } = params
  if (entity.type !== 'Client') {
    return []
  }

  const requestedSource = crossCheck.executableParams.Source

  const sourceOfClient =
    requestedSource?.type === 'string' ? requestedSource.value : undefined
  const sourceOfClientParams = {
    crossCheck,
    title: 'Source',
    type: 'text' as const,
    current: entity.value?.source,
    requested: sourceOfClient,
  }

  const requestedScope = params.crossCheck.executableParams.Scope

  const requestedSAAuth =
    crossCheck.executableParams['Enable Service Account Authentication']?.value
  const currentSAAuth = entity.value?.enableSAAuthentication

  return [
    getDetailRow({
      crossCheck,
      title: 'Reference',
      type: 'TowerComponent',
      current: entity.value?.externalRef?.reference,
      requested: crossCheck.executableParams['External Reference']?.value.reference,
    }),

    sourceOfClientParams ? getDetailRow(sourceOfClientParams) : undefined,

    crossCheck.executableName !== 'PermissionMatrixSynchronizer'
      ? getDetailRow({
          crossCheck,
          title: 'Scope',
          type: 'text',
          current: entity.value?.scope,
          requested: requestedScope?.type === 'string' ? requestedScope.value : undefined,
        })
      : null,

    getDetailRow({
      crossCheck,
      title: 'Destination (client)',
      type: 'Client',
      current: crossCheck.executableParams.Destination?.value.id,
    }),

    getDetailRow({
      crossCheck,
      title: 'Destination scope',
      type: 'text',
      current: crossCheck.executableParams['Destination (Scope Name)']?.value,
    }),

    getDetailRow({
      crossCheck,
      title: 'Description',
      type: 'text',
      current: entity.value?.description,
      requested: crossCheck.executableParams.Description?.value,
    }),

    getDetailRow({
      crossCheck,
      title: 'SA auth enabled',
      type: 'boolean',
      current: notNullable(currentSAAuth) ? stringifyBoolean(currentSAAuth) : undefined,
      requested: notNullable(requestedSAAuth)
        ? stringifyBoolean(requestedSAAuth)
        : undefined,
    }),
  ].filter(notNullable)
}
