import { Page, TabBar } from '@revolut/ui-kit'
import { TabBarLink } from 'components/TabBarLink'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useHeaderActions } from 'components/HeaderActions'
import { ClientList } from './ClientList'
import { AccountList } from './Accounts'

type ClientTab = 'clients' | 'accounts'

type Props = {
  tab: ClientTab
}
export const Clients = ({ tab }: Props) => {
  const HeaderActions = useHeaderActions()
  return (
    <>
      <Page.Header actions={HeaderActions && <HeaderActions />}>Applications</Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck everyPermission={[IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST]}>
            <TabBarLink to={Url.Clients}>Applications</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck
            everyPermission={[IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_VIEW_LIST]}
          >
            <TabBarLink to={Url.Accounts}>Accounts</TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>
        {tab === 'accounts' && <AccountList />}
        {tab === 'clients' && <ClientList />}
      </Page.Main>
    </>
  )
}
