import { Client } from 'api/idave/clients'
import { EntitiesTable } from 'components/EntitiesTable'
import { useQueryPermissions } from 'queries/idave/permissions'
import { useCallback } from 'react'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { usePermissionPreview } from 'components/previews/PermissionPreview'
import { mergeQueryStatuses } from 'utils/query'
import { getPermissionRows } from './utils'
import { COLUMNS, PermissionRow } from './columns'

export const ClientPermissions = (props: { client: Client }) => {
  const { data: permissions = [], status, fetchStatus } = useQueryPermissions()
  const { openPermissionSide } = usePermissionPreview()
  const clientPermissions = getPermissionRows(permissions, props.client.id)
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: clientPermissions,
  })

  const onRowClickHandler = useCallback(
    (permission: PermissionRow) => openPermissionSide(permission.id),
    [openPermissionSide],
  )
  const state = getLoadingState(
    mergeQueryStatuses({ qs: status, fs: fetchStatus }),
    clientPermissions.length,
  )

  return state === 'no-results' ? (
    <EmptyStatusWidget
      title="There isn't any permission provided by this Application"
      imageCode="3D055"
    />
  ) : (
    <EntitiesTable
      totalCount={clientPermissions.length}
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      data={searched}
      loadingState={state}
      columns={COLUMNS}
      onRowClick={onRowClickHandler}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
    />
  )
}
