import { useForm, Controller } from 'react-hook-form'
import { useToasts } from 'hooks/useToasts'
import { Button, Popup, VStack } from '@revolut/ui-kit'
import { Input } from 'components/Inputs'
import idaveApi from 'api/idave'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserUpdatePayload } from 'api/idave/user'
import { QueryKey } from 'helpers/configQuery'
import { useQueryUser } from 'queries/idave/users'
import { useEditPopup } from 'hooks/useEditPopup'
import { OrgSelect } from 'components/Selects/OrgSelect'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useCallback } from 'react'
import { usernameRegExpCheck } from 'view/Users/utils'

type Props = {
  id: string
}

export const useUserEdit = ({ id }: Props) => {
  const queryClient = useQueryClient()

  const { showSuccessToast } = useToasts()
  const { setPopup, closePopup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: mutateUserEdit } = useMutation({
    mutationFn: (payload: UserUpdatePayload) => idaveApi.user.updateUser(payload),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('User info updated')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.User, id],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Users],
        refetchType: 'all',
      })
    },
    onError: (error) => {
      showErrorPopup({
        title: 'User info updating failed',
        error,
      })
      hideLoadingPopup()
    },
    onMutate() {
      showLoadingPopup({ title: 'Updating...' })
    },
  })

  const onSubmit = useCallback(
    (data: UserUpdatePayload) => {
      mutateUserEdit(data)
    },
    [mutateUserEdit],
  )

  return useCallback(
    () =>
      setPopup({
        title: 'Edit user',
        content: <EditForm onSubmit={onSubmit} id={id} />,
      }),
    [setPopup, onSubmit, id],
  )
}

const EditForm = (props: {
  onSubmit: (payload: UserUpdatePayload) => void
  id: string
}) => {
  const { onSubmit, id } = props

  const { data: user } = useQueryUser({ id })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserUpdatePayload>({
    mode: 'all',
    defaultValues: {
      id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      username: user?.username,
      organisationId: user?.organisationId,
    },
  })

  return (
    <>
      <VStack gap="s-12">
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <Input maxLength={40} {...field} ref={undefined} label="First name" />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <Input maxLength={100} {...field} ref={undefined} label="Last name" />
          )}
        />

        <Controller
          control={control}
          name="email"
          rules={{
            required: 'Email is required',
          }}
          render={({ field }) => (
            <Input
              maxLength={255}
              {...field}
              ref={undefined}
              label="Email"
              aria-invalid={!!errors.email?.message}
              errorMessage={errors.email?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="username"
          rules={{
            required: 'Username is required',
            pattern: {
              value: usernameRegExpCheck,
              message: "Username shouldn't contain an email",
            },
          }}
          render={({ field }) => (
            <Input
              maxLength={255}
              {...field}
              ref={undefined}
              label="Username"
              aria-invalid={!!errors.username?.message}
              errorMessage={errors.username?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="organisationId"
          render={({ field }) => (
            <OrgSelect
              onChange={(org) => {
                field.onChange(org?.id || null)
              }}
              itemId={field.value}
              clearable
              label="Organisation (Optional)"
            />
          )}
        />
      </VStack>
      <Popup.Actions>
        <Button elevated onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </Popup.Actions>
    </>
  )
}
