import { Box, Subheader, Table } from '@revolut/ui-kit'
import { getLoadingState, openLink } from 'components/EntitiesTable/utils'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQueryResourceMap, useQueryResources } from 'queries/sam/resources'
import { useCallback, useMemo } from 'react'
import { QueryStatus } from '@tanstack/react-query'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { SamResource } from 'api/sam/resources'
import { notNullableMap } from 'utils/array'
import { COLUMNS } from './columns'

type SamResourceDetailsProps = {
  ids: string[]
}

export const SamResourcesSide = ({ ids }: SamResourceDetailsProps) => {
  const { data: resources, status, refetch, fetchStatus } = useQueryResources()
  const { data: resourceMap } = useQueryResourceMap()

  const sortedIds = [...ids].sort((idA, idB) => {
    const aName = resourceMap?.get(idA)?.resourceName
    const bName = resourceMap?.get(idB)?.resourceName
    const aType = resourceMap?.get(idA)?.resourceType
    const bType = resourceMap?.get(idB)?.resourceType
    if (aName && bName && aType && bType) {
      return aType.localeCompare(bType) || aName.localeCompare(bName)
    }
    return 0
  })

  const mappedResources = useMemo(
    () => notNullableMap(sortedIds, (id) => resourceMap?.get(id)),
    [sortedIds, resourceMap],
  )

  if (!ids?.length) {
    return null
  }

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={resources}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => <RequestErrorWidget action={refetch} />}
      renderLoading={() => <Inner status={status} resources={mappedResources} />}
      renderSuccess={() => <Inner status={status} resources={mappedResources} />}
    />
  )
}

const Inner = (props: { status: QueryStatus; resources: SamResource[] }) => {
  const { status, resources } = props
  const onRowClick = useCallback(
    (row: SamResource) =>
      openLink(generatePath(Url.SamResource, { resourceId: row.resourceId }), true),
    [],
  )
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Resources</Subheader.Title>
      </Subheader>
      <Table
        columns={COLUMNS}
        data={resources}
        loadingState={getLoadingState(status, resources.length)}
        onRowClick={onRowClick}
      />
    </Box>
  )
}
