import { differenceInCalendarDays } from 'date-fns'
import { isProduction } from 'utils/common'
import { DAY } from 'utils/constants/time'
import { pluralForm } from 'utils/string'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { AssignTimer, DurationType } from './types'

export const getDaysHint = (dueTo?: number) => {
  const diffInDays = dueTo ? differenceInCalendarDays(dueTo, Date.now()) : undefined

  return diffInDays
    ? pluralForm(diffInDays, [
        `Terminates in ${diffInDays} day`,
        `Terminates in ${diffInDays} days`,
      ])
    : undefined
}

export const DEFAULT_OFFSET = 90 * DAY
export const getDefaultDueTo = (defaultValue = DEFAULT_OFFSET) =>
  defaultValue + Date.now()

export const getDefaultTimer = (
  defaultOffset = DEFAULT_OFFSET,
  onlyTemporary?: boolean,
): AssignTimer =>
  isProduction() || onlyTemporary
    ? {
        type: 'temporary',
        value: getDefaultDueTo(defaultOffset),
      }
    : {
        type: 'permanent',
        value: undefined,
      }

export const getDurationOptions = (
  onlyTemporary?: boolean,
): SelectOptionItemType<DurationType>[] => {
  return [
    {
      key: 'temporary',
      value: 'temporary',
      label: 'Temporary',
    },
    {
      key: 'permanent',
      value: 'permanent',
      label: 'Permanent',
      disabled: onlyTemporary,
    },
  ]
}
