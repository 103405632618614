import { Page, TabBar, TextSkeleton } from '@revolut/ui-kit'
import { Url } from 'routing'
import { useQueryGroup } from 'queries/idave/groups'
import { generatePath } from 'react-router'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'
import { EntitySkeleton } from 'components/EntitySkeleton'

import { useNavigateToList } from 'hooks/useBackToResults'
import { GroupTab } from './types'
import { Users } from './components/Users'
import { Details } from './components/Details'

export const Group = ({ tab, groupId }: { tab: GroupTab; groupId: string }) => {
  const onBackClick = useNavigateToList({
    listUrl: Url.SystemGroups,
    entityUrl: generatePath(Url.SystemGroup, { groupId }),
  })

  const { data: group, status: groupS, fetchStatus: groupFS } = useQueryGroup(groupId)

  return (
    <>
      <Page.Header onBack={onBackClick}>
        {group?.name || <TextSkeleton variant="h1" size={16} />}
      </Page.Header>

      <Page.Tabs>
        <TabBar variant="navigation">
          <TabBarLink to={generatePath(Url.SystemGroupDetails, { groupId })}>
            Details
          </TabBarLink>

          <TabBarLink to={generatePath(Url.SystemGroupUsers, { groupId })}>
            Users
          </TabBarLink>
        </TabBar>
      </Page.Tabs>
      <Page.Main>
        <QuerySwitch
          required={[{ qs: groupS, fs: groupFS }]}
          data={group}
          idleText="Looks like you don't have sufficient permissions to view a group's details"
          renderLoading={() => (
            <EntitySkeleton
              variant={tab === 'details' ? 'details' : 'table'}
              hasActions={false}
            />
          )}
          renderSuccess={(data) => (
            <>
              {tab === 'details' && <Details group={data.data} />}
              {tab === 'users' && <Users group={data.data} />}
            </>
          )}
        />
      </Page.Main>
    </>
  )
}
