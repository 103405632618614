import { VStack } from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import { CrossCheckDetail, CrossCheckGroup } from 'api/types/crossCheck'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { RevolutersEmployee, RevolutersTeam } from 'api/sam/revoluters'
import { getReviewers } from './utils'
import { CrossCheckReviewGroup } from './components/CrossCheckReviewGroup'

type Props = {
  userMap: Map<string, UserListItem>
  employeeMap: Map<string, RevolutersEmployee>
  teamMap: Map<string, RevolutersTeam>
  crossCheck: CrossCheckDetail
  group: CrossCheckGroup
}
export const CrossCheckReviewersSide = (props: Props) => {
  const { userMap, crossCheck, group, employeeMap, teamMap } = props
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()

  const { rejected, approved, pending } = getReviewers({
    userMap,
    crossCheck,
    group,
    employeeMap,
    teamMap,
    avatars,
  })

  return (
    <VStack space="s-24">
      <CrossCheckReviewGroup reviewers={rejected} status="rejected" />
      <CrossCheckReviewGroup reviewers={approved} status="approved" />
      <CrossCheckReviewGroup reviewers={pending} status="pending" />
    </VStack>
  )
}
