import { Location, matchPath } from 'react-router'

const locationToUrl = ({ pathname, search, hash }: Location) =>
  `${pathname}${search}${hash}`

type GetLastSearchUrlParams = {
  locations: Location[]
  listUrl: string
  entityUrl: string
  supportedLists?: string[]
}

export const getLastSearchUrl = ({
  locations,
  listUrl,
  entityUrl,
  supportedLists = [],
}: GetLastSearchUrlParams) => {
  const locationStack = [...locations]
  const supportedSearchPaths = [...supportedLists, listUrl]
  let finished = false
  const url = listUrl

  while (locationStack.length && !finished) {
    const checkUrl = locationStack.pop()
    if (!checkUrl || !checkUrl.pathname.startsWith(entityUrl)) {
      finished = true

      if (checkUrl) {
        const isPathMatched = !!supportedSearchPaths.find((item) =>
          matchPath(item, checkUrl.pathname),
        )

        if (isPathMatched) {
          return locationToUrl(checkUrl)
        }
      }
    }
  }

  return url
}
