import { Action } from 'components/HeaderActions/types'
import { matchPath } from 'react-router'
import { useShouldShowInfo } from './utils'
import { ACTION_CONFIG } from './config'

export const useHeaderActionsValues = (): Action[] => {
  const key = Object.keys(ACTION_CONFIG).find((path) => {
    return matchPath({ path }, window.location.pathname)
  })

  const showInfo = useShouldShowInfo(key)

  return key && showInfo ? ACTION_CONFIG[key] : []
}
