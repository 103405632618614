import { useHistoryLocations } from 'hooks/useHistoryLocations'
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { getLastSearchUrl } from './utils'

export const useNavigateToList = ({
  listUrl,
  entityUrl,
  supportedLists,
}: {
  listUrl: string
  entityUrl: string
  supportedLists?: string[]
}) => {
  const { locations } = useHistoryLocations()
  const navigate = useNavigate()
  const url = getLastSearchUrl({
    locations,
    listUrl,
    entityUrl,
    supportedLists,
  })

  return useCallback(() => {
    return navigate(url)
  }, [url, navigate])
}
