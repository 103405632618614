import { ActionButtonVariant, IconName } from '@revolut/ui-kit'
import { State, XCheckDetails } from 'api/types/xChecks'
import { notNullable } from 'utils/common'
import {
  Data,
  XCheckActionSubmitHandler,
  XCheckActionType,
} from 'view/XChecks/XCheck/lib/types'
import { isProdDomain } from 'view/XChecks/XCheck/lib/utils'

type GetActionsParams = {
  actionsSecurity: Record<XCheckActionType, boolean>
  xCheck: Data<XCheckDetails>
  xCheckId: string
  currentUserId: Data<string>
  onActionClick?: (actionType: XCheckActionType) => void
  onActionSubmit?: XCheckActionSubmitHandler
}

type PopupSettings = {
  title: string
  primaryActionLabel: string
  textAreaLabel: string
  isTextRequired: boolean
}

type ActionSettings = {
  label: string
  variant?: ActionButtonVariant
  useIcon?: IconName
}

export type XCheckActionSettings = {
  onClick?: (actionType: XCheckActionType) => void
  onSubmit?: XCheckActionSubmitHandler
  actionType: XCheckActionType
  xCheckId: string
  popupSettings?: PopupSettings
  actionSettings: ActionSettings
}

export const getActions = (params: GetActionsParams) => {
  return [
    getJustifyAction(params),
    getDeclineAction(params),
    getApproveAction(params),
    getRejectAction(params),
    getRetryAction(params),
  ].filter(notNullable)
}
const getJustifyAction = ({
  onActionClick,
  onActionSubmit,
  xCheck,
  xCheckId,
  currentUserId,
  actionsSecurity,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const userId = currentUserId.status === 'success' ? currentUserId.data : undefined
  const xCheckValue = xCheck.status === 'success' ? xCheck.data : undefined

  if (
    !userId ||
    !xCheckValue ||
    xCheckValue.requester.id !== userId ||
    xCheckValue.state !== State.Requested ||
    !actionsSecurity.justify
  ) {
    return undefined
  }

  return {
    onClick: onActionClick,
    onSubmit: onActionSubmit,
    xCheckId,
    actionType: 'justify',
    popupSettings: {
      title: 'Justify request',
      primaryActionLabel: 'Justify',
      textAreaLabel: 'Justification',
      isTextRequired: true,
    },
    actionSettings: {
      label: 'Justify',
      useIcon: 'Statement',
      variant: 'accent',
    },
  }
}

const getRetryAction = ({
  onActionClick,
  onActionSubmit,
  xCheckId,
  actionsSecurity,
  xCheck,
}: GetActionsParams): XCheckActionSettings | undefined => {
  if (
    !actionsSecurity.retry ||
    xCheck.status !== 'success' ||
    xCheck.data.state !== State.Approved
  ) {
    return undefined
  }

  return {
    onClick: onActionClick,
    onSubmit: onActionSubmit,
    actionType: 'retry',
    xCheckId,
    actionSettings: {
      label: 'Retry',
      useIcon: 'Retry',
    },
  }
}

const DECLINE_STATES: State[] = [
  State.Requested,
  State.AwaitingReview,
  State.RequestedInfo,
  State.Approved,
]

const getDeclineAction = ({
  onActionClick,
  onActionSubmit,
  xCheck,
  xCheckId,
  currentUserId,
  actionsSecurity,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const userId = currentUserId.status === 'success' ? currentUserId.data : undefined
  const xCheckValue = xCheck.status === 'success' ? xCheck.data : undefined

  if (
    !userId ||
    !xCheckValue ||
    xCheckValue.requester.id !== userId ||
    !DECLINE_STATES.includes(xCheckValue.state) ||
    !actionsSecurity.decline
  ) {
    return undefined
  }

  return {
    onClick: onActionClick,
    onSubmit: onActionSubmit,
    xCheckId,
    actionType: 'decline',
    popupSettings: {
      title: 'Decline request',
      primaryActionLabel: 'Decline',
      textAreaLabel: 'Justification',
      isTextRequired: true,
    },
    actionSettings: {
      label: 'Decline',
      variant: 'negative',
      useIcon: 'Reverted',
    },
  }
}

const getApproveAction = ({
  onActionClick,
  onActionSubmit,
  xCheck,
  xCheckId,
  actionsSecurity,
  currentUserId,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const xCheckValue = xCheck.status === 'success' ? xCheck.data : undefined
  const userId = currentUserId.status === 'success' ? currentUserId.data : undefined

  if (
    !xCheckValue ||
    !actionsSecurity.approve ||
    xCheckValue.state !== State.AwaitingReview ||
    !userId
  ) {
    return undefined
  }

  const isRequester = xCheckValue.requester.id === userId

  if (isRequester && isProdDomain()) {
    return undefined
  }

  return {
    onClick: onActionClick,
    onSubmit: onActionSubmit,
    xCheckId,
    actionType: 'approve',
    popupSettings: {
      title: 'Approve request',
      primaryActionLabel: 'Approve',
      textAreaLabel: 'Justification (optional)',
      isTextRequired: false,
    },
    actionSettings: {
      label: 'Approve',
      variant: 'accent',
      useIcon: 'Check',
    },
  }
}

const getRejectAction = ({
  onActionClick,
  onActionSubmit,
  xCheck,
  xCheckId,
  actionsSecurity,
  currentUserId,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const xCheckValue = xCheck.status === 'success' ? xCheck.data : undefined
  const userId = currentUserId.status === 'success' ? currentUserId.data : undefined

  if (
    !xCheckValue ||
    !actionsSecurity.reject ||
    xCheckValue.state !== State.AwaitingReview
  ) {
    return undefined
  }

  const isRequester = xCheckValue.requester.id === userId

  if (isRequester && isProdDomain()) {
    return undefined
  }

  return {
    onClick: onActionClick,
    onSubmit: onActionSubmit,
    xCheckId,
    actionType: 'reject',
    popupSettings: {
      title: 'Reject request',
      primaryActionLabel: 'Reject',
      textAreaLabel: 'Justification (optional)',
      isTextRequired: false,
    },
    actionSettings: {
      label: 'Reject',
      variant: 'negative',
      useIcon: 'Cross',
    },
  }
}
