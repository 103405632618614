import { Box, Page, PageMainSize, PageProps } from '@revolut/ui-kit'
import { captureException } from '@sentry/browser'
import { ErrorStatus } from 'components/ErrorStatus'
import { useEffect } from 'react'
import { useRouteError } from 'react-router'

export const ErrorElement = () => {
  const error = useRouteError()

  useEffect(() => {
    captureException(error || new Error('Unknown error'))
  }, [error])

  return (
    <Box pt="s-64" pl="s-32" pr="s-32">
      <ErrorStatus />
    </Box>
  )
}

type Props = {
  errorDescription?: string
} & PageProps

export const ErrorPage = (props: Props) => {
  return (
    <Page {...props}>
      <ErrorPageContent errorDescription={props.errorDescription} />
    </Page>
  )
}
export const ErrorPageContent = (props: { errorDescription?: string }) => {
  const error = useRouteError()

  useEffect(() => {
    captureException(error || new Error('Unknown error'))
  }, [error])
  const message = error instanceof Error ? error.message : 'Unknown error'
  return (
    <>
      <Page.Header subtitle="An error occurred" description={message}>
        Ooops!
      </Page.Header>
      <Page.Main size={PageMainSize.WIDE}>
        <ErrorStatus errorDescription={props.errorDescription} />
      </Page.Main>
    </>
  )
}
