import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { TextProps } from '@revolut/ui-kit'
import { UserLinkView, UserLinkViewProps } from './UserLinkView'

export const UserLinkBase = (props: {
  user: UserListItem
  displayId?: string
  avatars?: Map<string, RevolutersAvatar>
  target?: UserLinkViewProps['target']
  isReversed?: boolean
  textProps?: TextProps
}) => {
  const { user, avatars, target, displayId, isReversed, textProps } = props

  const name = getName(user)
  const avatarUrl = getAvatarUrl({ user, avatars })

  return (
    <UserLinkView
      href={generatePath(Url.User, { userId: user.id })}
      id={user.id}
      name={name}
      avatarUrl={avatarUrl}
      state={user.state}
      target={target}
      displayId={displayId}
      isReversed={isReversed}
      textProps={textProps}
    />
  )
}
