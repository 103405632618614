import { useCallback, useState } from 'react'
import { filterActiveIds } from 'components/EntitiesTable/utils'

export type SelectedHash = Record<string, boolean>

export const useSelectedFilter = (enabled?: boolean) => {
  const [selectedHash, setSelectedHash] = useState<SelectedHash>({})

  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(() => {
    setShowSelected((v) => !v)
  }, [setShowSelected])

  const selected = filterActiveIds(selectedHash)
  const selectedCount = selected.length

  const resetSelected = useCallback(() => {
    setSelectedHash({})
    setShowSelected(false)
  }, [setSelectedHash, setShowSelected])

  return {
    selectEnabled: enabled,

    selectedHash,
    setSelectedHash: enabled ? setSelectedHash : undefined,
    selected,
    selectedCount,

    showSelected,
    setShowSelected,
    switchShowSelected,

    resetSelected,
  }
}
