import { noop } from 'lodash'
import { useCallback, useState } from 'react'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { getLabelWithMaxLength } from './utils'

type InputEvent = { currentTarget: { value: string } }
type Params = {
  label?: string
  maxLength?: number
  baseOnChange?: (event?: InputEvent) => void
  defaultValue?: string
}
export const useLabelWithMaxLength = ({
  label,
  maxLength,
  baseOnChange = noop,
  defaultValue,
}: Params) => {
  const [maxLengthLabel, setMaxLengthLabel] = useState(
    getLabelWithMaxLength(label, maxLength, defaultValue),
  )

  const resultLabel = useDebouncedValue(maxLengthLabel, 300)
  const onChange = useCallback(
    (event: InputEvent) => {
      setMaxLengthLabel(
        getLabelWithMaxLength(label, maxLength, event.currentTarget.value),
      )
      return baseOnChange(event)
    },
    [label, maxLength, baseOnChange, setMaxLengthLabel],
  )

  return {
    maxLengthLabel: resultLabel,
    onChange,
  }
}
