import { InputProps, Input as UIKitInput } from '@revolut/ui-kit'
import { useLabelWithMaxLength } from 'hooks/useLabelWithMaxLength'
import { noop } from 'lodash'
import { forwardRef } from 'react'

/**
 * Same as kit output, but:
 * - shows max length remains symbols
 */
export const Input = forwardRef(
  (props: InputProps, ref?: React.Ref<HTMLInputElement> | undefined) => {
    const { label, maxLength, onChange, ...restProps } = props

    const { maxLengthLabel, onChange: onChangeWithLabelUpdate } = useLabelWithMaxLength({
      label,
      maxLength,
      baseOnChange: onChange || noop,
    })

    return (
      <UIKitInput
        maxLength={maxLength}
        label={maxLengthLabel}
        type="text"
        onChange={onChangeWithLabelUpdate}
        ref={ref}
        {...restProps}
      />
    )
  },
)
