import { abbreviate, Avatar, Page, TextSkeleton } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useHeaderActions } from 'components/HeaderActions'
import { UserTab } from '../User'
import { UserActions } from './components/UserActions'
import { UserHeaderSubtitle } from './components/UserHeaderSubtitle'

export const UserHeader = (props: {
  user?: User
  userId: string
  tab: UserTab
  onBackClick: () => void
}) => {
  const { user, userId, onBackClick } = props
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()
  const avatarUrl = getAvatarUrl({ user, type: 'imageMedium', avatars })
  const employeeDescriptions = useGetEmployeeRevoDescription()
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header
        onBack={onBackClick}
        description={
          <UserHeaderSubtitle user={user} employeeDescriptions={employeeDescriptions} />
        }
        actions={HeaderActions && <HeaderActions />}
        avatar={
          <Avatar
            label={user ? abbreviate(getName(user)) : undefined}
            uuid={userId}
            image={avatarUrl}
            size={56}
          />
        }
        meta={<UserActions userId={userId} />}
      >
        {user ? getName(user) : <TextSkeleton size={36} />}
      </Page.Header>
    </>
  )
}
