import { DetailsCellSkeleton, Group, Subheader } from '@revolut/ui-kit'
import { times } from 'lodash'

type Props = {
  cellCount?: number
  title?: string
}
export const DetailsGroupSkeleton = ({ cellCount = 3, title }: Props) => (
  <Group>
    {title && (
      <Subheader>
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
    )}
    {times(cellCount, (idx) => (
      <DetailsCellSkeleton key={idx}>
        <DetailsCellSkeleton.Title />
        <DetailsCellSkeleton.Content />
      </DetailsCellSkeleton>
    ))}
  </Group>
)
