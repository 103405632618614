import { TextProps, TextSkeleton } from '@revolut/ui-kit'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { UserLinkBase } from './common/UserLinkBase'
import { InternalLink } from './InternalLink'

type UserLinkProps = {
  id: string
  isReversed?: boolean
  textProps?: TextProps
}

export const UserLink = ({ id, isReversed, textProps }: UserLinkProps) => {
  const { data: users = new Map(), status, fetchStatus } = useQueryUserIdMap()
  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedEmployeeIdAvatars()

  return (
    <QuerySwitch
      data={users.get(id)}
      required={[{ qs: status, fs: fetchStatus }]}
      optional={[{ qs: avatarsStatus, fs: avatarsFS }]}
      renderLoading={() => <TextSkeleton size={14} />}
      renderError={() => <FallbackLink id={id} />}
      renderIdle={() => <FallbackLink id={id} />}
      renderSuccess={({ data }) => (
        <UserLinkBase
          user={data}
          avatars={avatars}
          isReversed={isReversed}
          textProps={textProps}
        />
      )}
    />
  )
}

type FallBackProps = { id: string; children?: React.ReactNode }
const FallbackLink = ({ id, children }: FallBackProps) => (
  <InternalLink href={generatePath(Url.User, { userId: id })}>
    {children || id}
  </InternalLink>
)
