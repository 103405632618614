import { SamPolicy, SamPolicySubjectType } from 'api/sam/policies'
import { SamPolicySubjectField } from 'view/Sam/components/SamEditPolicyDetails/types'

const MIN_POLICY_DESCRIPTION_LENGTH = 50
export const getPolicyDescriptionRules = () => ({
  validate(value: string | null | undefined) {
    if (!value) {
      return "Policy description can't be empty"
    }

    if (value.length <= MIN_POLICY_DESCRIPTION_LENGTH) {
      return `Policy description should be longer than ${MIN_POLICY_DESCRIPTION_LENGTH} characters`
    }

    return undefined
  },
})

export const getPolicyNameRules = (
  mappedNames: Map<string, SamPolicy>,
  currentEditingId?: string,
) => ({
  validate(value: string | null | undefined) {
    if (!value) {
      return "Policy name can't be empty"
    }

    return getNameMatchingRule(mappedNames, value, currentEditingId)
  },
})

export const getNameMatchingRule = (
  mappedNames: Map<string, SamPolicy>,
  value: string,
  currentEditingId?: string,
) => {
  const sameNamePolicy = mappedNames.get(value)

  if (sameNamePolicy && sameNamePolicy.policyId !== currentEditingId) {
    return `Policy with this name already exists (${sameNamePolicy.policyId})`
  }

  return undefined
}

export const getFieldRules = (
  field: SamPolicySubjectField,
  subjectType?: SamPolicySubjectType,
) => {
  switch (subjectType) {
    case SamPolicySubjectType.Employee:
      return getEmployeeRules(field)
    default:
      return undefined
  }
}
const getEmployeeRules = (filed: SamPolicySubjectField) => {
  switch (filed) {
    case 'employeeIds':
      return {
        validate: (value: string[] | null | undefined) => {
          if (!value?.length) {
            return "Employee id can't be empty"
          }

          return undefined
        },
      }
    default:
      return undefined
  }
}
