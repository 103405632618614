import { Table, TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { UserListItem } from 'api/idave/user'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { getName } from 'utils/string/getName'
import { StateCell, stateCellAccessor } from 'components/StateCell'
import { UserTableCell } from 'components/UserTableCell'
import { UserInfo } from './types'

export const getColumns = (isDataLoading: boolean): TableColumn<UserInfo>[] => [
  {
    Header: 'Name',
    accessor: (user) => getName(user),
    id: 'name',
    Cell: (params: { row: { original: UserListItem } }) => {
      return <UserTableCell userId={params.row.original.id} />
    },
  },
  {
    Header: 'Department',
    accessor: 'department',
    Cell: (params: { row: { original: UserInfo } }) => (
      <Table.Cell>
        {isDataLoading ? <TextSkeleton size={12} /> : params.row.original.department}
      </Table.Cell>
    ),

    filter: 'includesValue' as const,
    Filter: SelectItemFilter,
  },
  {
    Header: 'Team',
    accessor: 'team',
    Cell: (params: { row: { original: UserInfo } }) => (
      <Table.Cell>
        {isDataLoading ? <TextSkeleton size={12} /> : params.row.original.team}
      </Table.Cell>
    ),
    filter: 'includesValue' as const,
    Filter: SelectItemFilter,
  },
  {
    Header: 'Status',
    accessor: stateCellAccessor,
    id: 'state',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: StateCell,
  },
]
