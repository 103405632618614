import {
  MultiSelectInput,
  MultiSelectInputProps,
  SelectOptionItemType,
} from '@revolut/ui-kit'
import { EmployeeType } from 'api/sam/policies'
import { EMPLOYEE_TYPE_OPTIONS } from './constants'

type EmployeeTypesSelectProps = MultiSelectInputProps<
  string,
  SelectOptionItemType<EmployeeType>
>
type Props = Omit<EmployeeTypesSelectProps, 'options'>

export const EmployeeTypesSelect = (props: Props) => (
  <MultiSelectInput options={EMPLOYEE_TYPE_OPTIONS} {...props} />
)
