import {
  MultiSelectInput,
  MultiSelectInputProps,
  SelectOptionItemType,
} from '@revolut/ui-kit'
import { SENIORITY_OPTIONS } from './constants'

type StringSelect = MultiSelectInputProps<string, SelectOptionItemType<string>>

type Props = Omit<StringSelect, 'options'>

export const SeniorityIdsSelect = (props: Props) => (
  <MultiSelectInput {...props} options={SENIORITY_OPTIONS} valueAsTags />
)
