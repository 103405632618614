export const Url = {
  AuditLogs: '/audit-logs',

  Clients: '/clients',
  Accounts: '/clients/accounts',

  Account: '/clients/accounts/:accountId',
  AccountDetails: '/clients/accounts/:accountId/details',
  AccountPermissions: '/clients/accounts/:accountId/permissions',
  AccountAdd: '/clients/accounts/add',
  AccountEdit: '/clients/accounts/:accountId/edit',
  AccountAuditLogs: '/clients/accounts/:accountId/audit-logs',

  Client: '/clients/:clientId',
  ClientDetails: '/clients/:clientId/details',
  ClientPermissions: '/clients/:clientId/permissions',
  ClientAuditLogs: '/clients/:clientId/audit-logs',
  ClientEdit: '/clients/:clientId/edit',
  ClientCreate: '/clients/new',

  Roles: '/roles',
  Role: '/roles/:roleId',
  RoleDetails: '/roles/:roleId/details',
  RolePermissions: '/roles/:roleId/permissions',
  RolePolicies: '/roles/:roleId/policies',
  RoleUsers: '/roles/:roleId/users',
  RoleAuditLogs: '/roles/:roleId/audit-logs',
  AddRole: '/roles/add',
  AddRoleDuplicate: '/roles/add/:roleId',
  EditRole: '/roles/:roleId/edit',

  Home: '/',

  Organisations: '/organisations',
  Organisation: '/organisations/:organisationId',
  OrganisationDetails: '/organisations/:organisationId/details',
  OrganisationAuditLog: '/organisations/:organisationId/audit-log',
  OrganisationEdit: '/organisations/:organisationId/edit',
  OrganisationCreate: '/organisations/add',

  ToSignIn: '/signin',

  User: '/users/:userId',
  UserProfile: '/users/:userId/profile',
  UserRoles: '/users/:userId/roles',
  UserAccessLogs: '/users/:userId/access-logs',
  UserAuditLogs: '/users/:userId/audit-logs',
  UserDataLogs: '/users/:userId/data-logs',
  UserPolicies: '/users/:userId/policies',
  UserPermissions: '/users/:userId/permissions',
  UserDataAccess: '/users/:userId/data-access',
  UserToxicAlerts: '/users/:userId/toxic-alerts',
  Users: '/users',

  Permissions: '/permissions',
  Permission: '/permissions/:permissionId',
  PermissionEdit: '/permissions/:permissionId/edit',
  PermissionDetails: '/permissions/:permissionId/details',
  PermissionRoles: '/permissions/:permissionId/roles',
  PermissionUsers: '/permissions/:permissionId/users',
  PermissionAuditLogs: '/permissions/:permissionId/audit-logs',

  ToxicPair: '/permissions/toxic/:toxicPairId',
  ToxicPairDetails: '/permissions/toxic/:toxicPairId/details',
  ToxicPairAlerts: '/permissions/toxic/:toxicPairId/alerts',
  ToxicPairAdd: '/permissions/toxic/add',

  ToxicPairs: '/permissions/toxic',
  ToxicAlerts: '/permissions/toxic-alerts',

  CrossChecks: '/cross-checks',
  CrossChecksToReview: '/cross-checks/review',
  CrossChecksRequested: '/cross-checks/requested',
  CrossChecksAll: '/cross-checks/all',
  CrossCheck: '/cross-checks/:source/:crossCheckId',
  CrossCheckIdaveDetails: '/cross-checks/idave/:crossCheckId',
  CrossCheckSamDetails: '/cross-checks/sam/:crossCheckId',
  CrossCheckDartDetails: '/cross-checks/dart/:crossCheckId',

  XChecks: '/x-checks',
  XCheck: '/x-checks/:xCheckId',

  Sam: '/sam',
  SamPolicies: '/sam/policies',
  SamPoliciesCreate: '/sam/policies/new',
  SamPoliciesCreateCopy: '/sam/policies/copy/:policyId',
  SamPoliciesEdit: '/sam/policies/edit/:policyId',
  SamPolicy: '/sam/policy/:policyId',
  SamPolicyTrainings: '/sam/policy/:policyId/trainings',
  SamPolicyResources: '/sam/policy/:policyId/resources',
  SamPolicyLogs: '/sam/policy/:policyId/logs',
  SamPolicyEmployees: '/sam/policy/:policyId/employees',
  SamResources: '/sam/resources',
  SamResource: '/sam/resources/:resourceId',
  SamResourceDetails: '/sam/resources/:resourceId/details',
  SamResourcePolicies: '/sam/resources/:resourceId/policies',
  SamTasks: '/sam/tasks',
  SamPoliciesAddTrainings: '/sam/policies/addTrainings',
  SamPoliciesAddResources: '/sam/policies/addResources',

  Dart: '/limitations',
  DartCustomerCompanies: '/limitations/customerCompanies',
  DartCustomerCompany: '/limitations/customerCompanies/:companyId',
  DartCustomerCompanyAllowedCompanies:
    '/limitations/customerCompanies/:companyId/allowedCompanies',
  DartCustomerCompanyAllowedEmployees:
    '/limitations/customerCompanies/:companyId/allowedEmployees',
  DartCustomerCompanyApprovers: '/limitations/customerCompanies/:companyId/approvers',

  DartEmployeeCompanies: '/limitations/employeeCompanies',
  DartEmployeeCompany: '/limitations/employeeCompanies/:companyId',
  DartEmployeeCompanyAllowedCompanies:
    '/limitations/employeeCompanies/:companyId/allowedCompanies',
  DartEmployeeCompanyAuditLog: '/limitations/employeeCompanies/:companyId/audit-log',

  DartUserAccesses: '/limitations/userAccesses',
  DartUserRestrictions: '/limitations/userRestrictions',
  DartAuditLogs: '/limitations/audit-logs',

  System: '/system',
  SystemGroups: '/system/groups',
  SystemGroupAdd: '/system/groups/add',
  SystemGroup: '/system/groups/:groupId',
  SystemGroupEdit: '/system/groups/:groupId/edit',
  SystemGroupDetails: '/system/groups/:groupId/details',
  SystemGroupUsers: '/system/groups/:groupId/users',

  Logout: '/logout',
} as const
