import { Page, TabBar } from '@revolut/ui-kit'
import { DART_PERMISSIONS, IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'
import { useQueryUser } from 'queries/idave/users'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { User as UserType } from 'api/idave/user'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useQueryToxicAlerts } from 'queries/idave/permissions'
import { NoAccessPage } from 'components/NoAccessWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import {
  getUserAccessLogs,
  getUserAuditLogs,
  getUserPolicies,
  getUserProfile,
  getUserRoles,
} from '../utils'
import { UserPolicies } from './UserPolicies'
import { UserAccessLogs } from './UserAccessLogs'
import { UserRoles } from './UserRoles'
import { UserAuditLogs } from './UserAuditLogs'
import { UserProfile } from './UserProfile'
import { UserHeader } from './UserHeader'
import { UserPermissions } from './UserPermissions'
import { UserSkeleton } from './UserSkeleton'
import { UserDataAccess } from './UserDataAccess'
import { UserDataLogs } from './UserDataLogs'
import { UserToxicAlerts } from './UserToxicAlerts'

export type UserTab =
  | 'profile'
  | 'roles'
  | 'permissions'
  | 'policies'
  | 'accessLog'
  | 'auditLog'
  | 'dataAccess'
  | 'dataLogs'
  | 'toxicAlerts'

export type UserProps = {
  userId: string
  tab: UserTab
}

export const User = (props: UserProps) => {
  const { userId, tab } = props
  // Assume that every user has permission to view user details
  const { data: user, status, refetch, fetchStatus } = useQueryUser({ id: userId })
  const {
    data: alerts,
    status: alertsQS,
    fetchStatus: alertsFS,
  } = useQueryToxicAlerts({ userId })
  const hasToxicAlert = Boolean(alerts?.length)
  const onBackClick = useNavigateToList({
    listUrl: Url.Users,
    entityUrl: generatePath(Url.User, { userId }),
  })

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      optional={[{ qs: alertsQS, fs: alertsFS }]}
      data={user}
      renderLoading={() => (
        <Outer
          userId={userId}
          tab={tab}
          hasToxicAlert={hasToxicAlert}
          onBackClick={onBackClick}
        >
          <UserSkeleton tab={tab} userId={userId} />
        </Outer>
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={userId}
          title="Something went wrong"
          description="Requested user info fetch failed"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => <NoAccessPage pageTitle={userId} onBackClick={onBackClick} />}
      renderSuccess={({ data }) => (
        <Outer
          userId={userId}
          user={data}
          tab={tab}
          hasToxicAlert={hasToxicAlert}
          onBackClick={onBackClick}
        >
          <Inner user={data} tab={tab} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  userId: string
  children: React.ReactNode
  user?: UserType
  tab: UserTab
  hasToxicAlert: boolean
  onBackClick: () => void
}) => {
  const { children, userId, user, tab, hasToxicAlert, onBackClick } = props

  return (
    <>
      <UserHeader user={user} userId={userId} tab={tab} onBackClick={onBackClick} />
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_VIEW_DETAILS}>
            <TabBarLink to={getUserProfile(userId)}>Profile</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_VIEW_DETAILS}>
            <TabBarLink to={getUserRoles(userId)}>Roles</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_VIEW_LIST}>
            <TabBarLink to={getUserPolicies(userId)}>Policies</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.UserPermissions, { userId })}>
              Permissions
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            somePermissions={[
              DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS,
              DART_PERMISSIONS.EMPLOYEES_VIEW_DETAILS_LIMITED,
            ]}
          >
            <TabBarLink to={generatePath(Url.UserDataAccess, { userId })}>
              Data access
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.USERS_VIEW_LOGS_ACCESS_LIST}>
            <TabBarLink to={getUserAccessLogs(userId)}>Access Logs</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={getUserAuditLogs(userId)}>Audit Logs</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.UserDataLogs, { userId })}>
              Data Logs
            </TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck
            permission={IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST}
          >
            <TabBarLink
              to={generatePath(Url.UserToxicAlerts, { userId })}
              hasDot={hasToxicAlert}
            >
              Toxic permission alerts
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = (props: { user: UserType; tab: UserProps['tab'] }) => {
  const { user, tab } = props

  switch (tab) {
    case 'roles':
      return <UserRoles user={user} />
    case 'policies':
      return <UserPolicies userId={user.id} />
    case 'permissions':
      return <UserPermissions userId={user.id} />
    case 'accessLog':
      return <UserAccessLogs userId={user.id} />
    case 'auditLog':
      return <UserAuditLogs userId={user.id} />
    case 'dataAccess':
      return <UserDataAccess userId={user.id} />
    case 'dataLogs':
      return <UserDataLogs userId={user.id} />
    case 'toxicAlerts':
      return <UserToxicAlerts userId={user.id} />
    default:
    case 'profile':
      return <UserProfile userId={user.id} />
  }
}
