import { createContext, useEffect, useState } from 'react'
import { Location, useLocation } from 'react-router'

export const HistoryLocationsContext = createContext<{
  locations: Location[]
}>({ locations: [] })

export const HistoryLocationsProvider = (props: { children: React.ReactElement }) => {
  const [locations, setLocations] = useState<Location[]>([])
  const location = useLocation()

  useEffect(() => {
    const lastLocation = locations[locations.length - 1]
    if (!lastLocation) {
      setLocations([location])
    } else if (
      lastLocation.hash !== location.hash ||
      lastLocation.pathname !== location.pathname ||
      lastLocation.search !== location.search
    ) {
      setLocations([...locations, location])
    }
  }, [location, locations, setLocations])

  return (
    <HistoryLocationsContext.Provider value={{ locations }}>
      {props.children}
    </HistoryLocationsContext.Provider>
  )
}
