import { Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { UserStates } from 'api/idave/user'
import { RevolutersEmployeeStatus } from 'api/sam/revoluters'
import { useMemo } from 'react'
import { capitalizeFirst } from 'utils/string'

type Placement = 'auto' | 'top-start'

export const useUserStateTooltip = (
  state: UserStates | RevolutersEmployeeStatus | undefined,
  placement: Placement = 'auto',
) => {
  const tooltip = useTooltip()

  const showState =
    // idave
    state === 'TERMINATED' ||
    state === 'DELETED' ||
    // peopleOps
    state === 'inactive' ||
    state === 'terminated'

  const result = useMemo(
    () => ({
      anchorProps: tooltip.getAnchorProps<HTMLDivElement>(),
      avatarProps: { disabled: showState },
      titleProps: {
        color: showState ? Token.color.greyTone50 : undefined,
        textDecoration: showState ? 'line-through' : undefined,
      },
      tooltip: showState ? (
        <Tooltip {...tooltip.getTargetProps({ placement })}>
          Status: {capitalizeFirst(state)}
        </Tooltip>
      ) : null,
    }),
    [placement, showState, state, tooltip],
  )

  return result
}
