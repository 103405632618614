import { UIRoute } from './types'
import { Url } from './url'
import { baseRoute } from './utils'

type RedirectConfig = {
  url: string
  params?: string[]
}

const deprecatedUrlsMap: Record<string, RedirectConfig> = {
  '/sam/policies/side/:policyId': { url: Url.SamPolicy, params: ['policyId'] },
  '/user/:userId': { url: Url.User, params: ['userId'] },
  '/user/:userId/profile': { url: Url.UserProfile, params: ['userId'] },
  '/user/:userId/roles': { url: Url.UserRoles, params: ['userId'] },
  '/user/:userId/policies': { url: Url.UserPolicies, params: ['userId'] },
  '/user/:userId/auditLog': { url: Url.UserAuditLogs, params: ['userId'] },
  '/role/:roleId': { url: Url.Role, params: ['roleId'] },
  '/add-role': { url: Url.AddRole },
  '/edit-role/:roleId': { url: Url.EditRole, params: ['roleId'] },
  '/permission/:permissionId': { url: Url.Permission, params: ['permissionId'] },
  '/limitations/company-relations': { url: Url.DartEmployeeCompanies },
  '/limitations/company-relations/:companyId': {
    url: Url.DartEmployeeCompany,
    params: ['companyId'],
  },
  '/limitations/direct-relations': { url: Url.DartUserAccesses },
  '/permissions/orphaned': { url: Url.Permissions },
}

export const getLegacyRedirects = (): Record<string, UIRoute> => {
  return Object.keys(deprecatedUrlsMap).reduce(
    (acc, path) => ({
      ...acc,
      [path]: {
        type: 'redirect',
        params: deprecatedUrlsMap[path].params || [],
        newUrl: deprecatedUrlsMap[path].url,
      },
    }),
    {},
  )
}

export const failoverRoutes = (): Record<string, UIRoute> => {
  return {
    [baseRoute()]: {
      type: 'redirect',
      params: [],
      newUrl: Url.Home,
    },
  }
}
