import { TextArea as UIKitTextArea, TextAreaProps } from '@revolut/ui-kit'
import { useLabelWithMaxLength } from 'hooks/useLabelWithMaxLength'
import { noop } from 'lodash'
import { forwardRef } from 'react'

export const TextArea = forwardRef(
  (props: TextAreaProps, ref?: React.Ref<HTMLTextAreaElement> | undefined) => {
    const { label, maxLength, onChange, ...restProps } = props

    const { maxLengthLabel, onChange: onChangeWithLabelUpdate } = useLabelWithMaxLength({
      label,
      maxLength,
      baseOnChange: onChange || noop,
    })

    return (
      <UIKitTextArea
        maxLength={maxLength}
        label={maxLengthLabel}
        onChange={onChangeWithLabelUpdate}
        ref={ref}
        {...restProps}
      />
    )
  },
)
