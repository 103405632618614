import { useCallback, useMemo, useState } from 'react'
import { filterActiveIds, getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { EntitiesTable } from 'components/EntitiesTable'
import { useQueryPermissionIdMap } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { mergeQueryStatuses } from 'utils/query'
import { usePermissionPreview } from 'components/previews/PermissionPreview'
import { PresenceMap } from 'utils/array/toPresenceMap'
import { Row } from './types'
import { COLUMNS } from '../../columns'
import { getRows } from './utils'
import { Actions } from './components/Actions'
import { EmptyList } from './components/EmptyList'

export type PermissionsListProps = {
  permissionIds?: string[]
  onAddClick: () => void
  onDelete: (ids: string[]) => void
  showActions?: boolean
  selected: PresenceMap
  setSelected: (value: PresenceMap) => void
}

export const PermissionsList = ({
  permissionIds = [],
  onAddClick,
  onDelete,
  showActions,
  selected,
  setSelected,
}: PermissionsListProps) => {
  const {
    data: permissionMap = new Map(),
    status: permissionsQS,
    fetchStatus: permissionsFS,
  } = useQueryPermissionIdMap()
  const {
    data: clientMap = new Map(),
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const queryResult = mergeQueryStatuses(
    { qs: permissionsQS, fs: permissionsFS },
    { qs: clientsQS, fs: clientsFS },
  )

  const rows = useMemo(
    () =>
      getRows({
        permissionIds,
        permissionMap,
        clientMap,
      }),
    [permissionIds, permissionMap, clientMap],
  )

  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities: rows,
  })

  const loadingState = getLoadingState(queryResult, rows.length)

  const { openPermissionSide } = usePermissionPreview()
  const onRowClick = useCallback(
    (row: Row) => openPermissionSide(row.id),
    [openPermissionSide],
  )

  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((showSelectedValue) => !showSelectedValue),
    [setShowSelected],
  )
  const selectedCount = filterActiveIds(selected).length

  const onDeleteClick = useCallback(() => {
    const selectedIds = Object.keys(selected)
    onDelete(selectedIds)
  }, [onDelete, selected])

  const renderActions = useCallback(
    () =>
      showActions && (
        <Actions
          selectedCount={selectedCount}
          onRemoveClick={onDeleteClick}
          onAddClick={onAddClick}
        />
      ),
    [showActions, onDeleteClick, selectedCount, onAddClick],
  )

  return !rows.length ? (
    <EmptyList actionAllowed={showActions} onClick={onAddClick} />
  ) : (
    <EntitiesTable
      totalCount={rows.length}
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      data={searched}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      searchValue={searchValue}
      searchAutoFocus
      columns={COLUMNS}
      onRowClick={onRowClick}
      selectedHash={selected}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={showActions ? setSelected : undefined}
      renderActions={renderActions}
    />
  )
}
