import { Page } from 'components/HeaderActions/types'
import { InfoBlockName } from './infoContent'

export const CONTENT_PAGES: Record<Page, InfoBlockName[]> = {
  // create
  createClient: ['app', 'accessRequest'],
  createRole: ['role', 'rolePermissions', 'roleOwnership', 'roleName'],
  createPolicy: [
    'policy',
    'policyDescription',
    'policyTypes',
    'policySeniority',
    'policyApprovals',
  ],
  // update
  updateClient: ['app', 'accessRequest'],
  updateRole: ['role', 'rolePermissions', 'roleOwnership', 'roleName'],
  updatePolicy: ['policy', 'policyTypes', 'policyApprovals'],
  updatePermission: [
    'permissionDescription',
    'permissionDescriptionAction',
    'permissionDescriptionData',
    'permissionDescriptionScope',
    'permissionDescriptionOversight',
    'permissionDescriptionDomain',
  ],

  // read
  clientPage: ['client', 'clientDetails', 'clientPermissions'],
  rootPage: [
    'users',
    'apps',
    'roles',
    'permissions',
    'orgs',
    'requests',
    'sam',
    'dart',
    'logs',
  ],
  userPage: ['userProfile', 'userRoles', 'userPolicies', 'userPermissions'],
  userRoles: [
    'userProfile',
    'userRoles',
    'roleAssignment',
    'userPolicies',
    'userPermissions',
  ],
  permissionPage: [
    'permission',
    'permissionName',
    'permissionClient',
    'permissionClassification',
  ],
  // other
  xCheckAssignRole: ['role', 'roleAssignment'],
}
