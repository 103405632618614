import { useCallback, useMemo, useState } from 'react'
import { randomKey } from 'utils/common/randomKey'
import { PresenceMap, toPresenceMap } from 'utils/array/toPresenceMap'
import { PermissionsList } from './components/PermissionList/PermissionsList'
import { AddPermissions } from './components/AddPermissions'

type PermissionWidgetProps = {
  onDelete: (ids: string[]) => void
  onAdd: (ids: string[]) => void
  permissionIds: string[]
  showActions?: boolean
}

const useResetKey = () => {
  const [resetKey, setResetKey] = useState(randomKey())
  const reset = useCallback(() => {
    setResetKey(randomKey())
  }, [setResetKey])

  return { reset, resetKey }
}

export const PermissionWidget = ({
  onAdd,
  onDelete,
  permissionIds,
  showActions,
}: PermissionWidgetProps) => {
  const { reset, resetKey } = useResetKey()
  /**
   * Resets table after item deletions to recalculate selected items and hashes
   */
  const onDeleteReset = useCallback(
    (ids: string[]) => {
      onDelete(ids)
      reset()
    },
    [onDelete, reset],
  )

  const [showAdd, setShowAdd] = useState(false)
  const onShowAddClick = useCallback(() => setShowAdd(true), [setShowAdd])
  const onCloseAddClick = useCallback(() => setShowAdd(false), [setShowAdd])
  const addedPermissions = useMemo(
    () => toPresenceMap(permissionIds || []),
    [permissionIds],
  )
  const [selected, setSelected] = useState<PresenceMap>({})

  return (
    <>
      {showAdd && (
        <AddPermissions
          onClose={onCloseAddClick}
          onAdd={onAdd}
          addedPermissions={addedPermissions}
        />
      )}
      <PermissionsList
        permissionIds={permissionIds}
        showActions={showActions}
        key={resetKey}
        onDelete={onDeleteReset}
        onAddClick={onShowAddClick}
        selected={selected}
        setSelected={setSelected}
      />
    </>
  )
}
