import { MoreBar } from '@revolut/ui-kit'
import idaveApi from 'api/idave'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useCallback } from 'react'

export const RoleUsersActions = ({
  userIds,
  roleId,
}: {
  userIds: string[]
  roleId: string
}) => {
  const queryClient = useQueryClient()

  const { closePopup, setPopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: unassignRoles } = useMutation({
    mutationFn: () => {
      const body = userIds.map((userId) => ({ roleId, userId }))
      return idaveApi.user.unassignRoles(body)
    },
    onMutate: () => showLoadingPopup({ title: 'Unassign in progress...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closePopup()
      showSuccessToast('Role unassigned')
      queryClient.invalidateQueries([QueryKey.Users, { roleId }, roleId])
    },
    onError: (error: unknown) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Unassign failed',
        error,
      })
    },
  })

  const onUnassignClick = useCallback(
    () =>
      setPopup({
        title: 'Role unassign',
        message: `Do you want to unassign the role from the users?`,
        confirmButtonText: 'Unassign',
        confirmButtonVariant: 'negative',
        onConfirmClick: unassignRoles,
      }),
    [setPopup, unassignRoles],
  )

  return (
    <>
      {!!userIds.length && (
        <MoreBar.Action variant="negative" onClick={onUnassignClick} useIcon="Delete">
          Unassign
        </MoreBar.Action>
      )}
    </>
  )
}
