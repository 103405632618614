import { Avatar, TabBar, TextSkeleton, Page, HStack } from '@revolut/ui-kit'
import { EmployeeCompany as TEmployeeCompany } from 'api/dart/employeeCompanies'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'
import { useQueryEmployeeCompanies } from 'queries/dart/companies'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { DART_PERMISSIONS } from 'security'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'
import { ExternalTextLink } from 'components/Links/ExternalTextLink'
import { useMemo } from 'react'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { NoAccessPage } from 'components/NoAccessWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import { getEmployeeCompanyLink } from './utils'
import { EmployeeCompanySkeleton } from './components/EmployeeCompanySkeleton'
import { CustomerCompanies } from './components/CustomerCompanies'
import { EmployeeCompanyAuditLog } from './components/EmployeeCompanyAuditLog'

export type EmployeeCompanyTab = 'allowedCustomerCompanies' | 'auditLog'

type Props = {
  companyId: string
  tab: EmployeeCompanyTab
}
export const EmployeeCompany = ({ companyId, tab }: Props) => {
  const {
    data: employeeCompanies = [],
    status: empStatus,
    fetchStatus: empFS,
    refetch,
  } = useQueryEmployeeCompanies()

  const employeeCompany = useMemo(
    () => employeeCompanies.find((i) => i.id === companyId),
    [employeeCompanies, companyId],
  )

  const onBackClick = useNavigateToList({
    listUrl: Url.DartEmployeeCompanies,
    entityUrl: generatePath(Url.DartEmployeeCompany, { companyId }),
  })

  return (
    <QuerySwitch
      required={[{ qs: empStatus, fs: empFS }]}
      data={employeeCompany}
      renderLoading={() => (
        <Outer tab={tab} companyId={companyId} onBackClick={onBackClick}>
          <EmployeeCompanySkeleton />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer
          tab={tab}
          companyId={companyId}
          employeeCompany={data}
          onBackClick={onBackClick}
        >
          <Inner tab={tab} employeeCompany={data} />
        </Outer>
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={companyId}
          title="Something went wrong"
          description="Employee companies fetch failed or company not found"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => <NoAccessPage pageTitle={companyId} onBackClick={onBackClick} />}
    />
  )
}

type OuterProps = {
  tab: EmployeeCompanyTab
  companyId: string
  children: React.ReactNode
  onBackClick: () => void
  employeeCompany?: TEmployeeCompany
}
const Outer = ({ companyId, employeeCompany, children, onBackClick }: OuterProps) => {
  return (
    <>
      <Page.Header
        subtitle="Employee company"
        description={
          employeeCompany && (
            <ExternalTextLink
              href={getEmployeeCompanyLink(
                employeeCompany.externalId,
                employeeCompany.type,
              )}
              text="Legal entities"
            />
          )
        }
        onBack={onBackClick}
      >
        <HStack align="center" space="s-4">
          {employeeCompany?.region && (
            <Avatar
              size={20}
              uuid={companyId}
              image={getRegionFlagImageUrl(employeeCompany.region)}
            />
          )}
          {employeeCompany?.name || <TextSkeleton variant="h1" size={16} />}
        </HStack>
      </Page.Header>

      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck
            everyPermission={[
              DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST,
              DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST,
            ]}
          >
            <TabBarLink
              to={generatePath(Url.DartEmployeeCompanyAllowedCompanies, { companyId })}
            >
              Allowed Customer Companies
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={DART_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.DartEmployeeCompanyAuditLog, { companyId })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>

      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = ({
  tab,
  employeeCompany,
}: {
  employeeCompany: TEmployeeCompany
  tab: EmployeeCompanyTab
}) => {
  switch (tab) {
    case 'auditLog':
      return <EmployeeCompanyAuditLog companyId={employeeCompany.id} />
    case 'allowedCustomerCompanies':
    default:
      return <CustomerCompanies employeeCompany={employeeCompany} />
  }
}
