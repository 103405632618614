import { QueryFetchStatus, isAllSettled } from './isAllSettled'

/**
 * Returns merged status of queries
 * - if one of query has error status - returns error
 * - if one of query has idle status - returns idle
 * - if one of query has loading/idle status - returns loading
 * - either returns success
 */
export type QueryResult = 'error' | 'success' | 'loading' | 'forbidden'
export const mergeQueryStatuses = (...statuses: QueryFetchStatus[]): QueryResult => {
  if (statuses.some((status) => status.qs === 'error')) {
    return 'error'
  }

  if (statuses.some(({ qs, fs }) => qs === 'loading' && fs === 'idle')) {
    return 'forbidden'
  }

  if (!isAllSettled(...statuses)) {
    return 'loading'
  }

  return 'success'
}
