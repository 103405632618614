import { uniq } from 'lodash'
import { useCallback, useState } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { ToxicPair } from 'api/idave/toxic'
import { RoleCreatePermissionsList } from './components/RoleCreatePermissionsList'
import { RoleCreateAddPermissions } from './components/RoleCreateAddPermission'

export const RoleCreatePermissions = ({
  permissionIds,
  roleName,
  toxicPairs,
  parent,
  setPermissionIds,
}: {
  parent?: string
  toxicPairs: ToxicPair[]
  roleName: string
  permissionIds: string[]
  setPermissionIds: (permissions: string[]) => void
}) => {
  const [isAdding, setAdding] = useState(false)
  const openAdding = useCallback(() => setAdding(true), [setAdding])
  const closeAdding = useCallback(() => setAdding(false), [setAdding])
  const deletePermissions = useCallback(
    (permissionsToDelete: string[]) => {
      const presence = toPresenceMap(permissionsToDelete)
      setPermissionIds(permissionIds.filter((i) => !presence[i]))
    },
    [permissionIds, setPermissionIds],
  )

  const addPermissions = useCallback(
    (newPermissions: string[]) => {
      setPermissionIds(uniq(permissionIds.concat(newPermissions)))
      closeAdding()
    },
    [permissionIds, setPermissionIds, closeAdding],
  )

  return (
    <>
      {isAdding && (
        <RoleCreateAddPermissions
          closeAdding={closeAdding}
          permissionIds={permissionIds}
          addPermissions={addPermissions}
          roleName={roleName}
          parent={parent}
        />
      )}
      <RoleCreatePermissionsList
        key={String(permissionIds.length)}
        toxicPairs={toxicPairs}
        deletePermissions={deletePermissions}
        openAdding={openAdding}
        permissionIds={permissionIds}
        parent={parent}
      />
    </>
  )
}
