import { Box, Subheader } from '@revolut/ui-kit'
import { SAMPolicyUpdateLog } from 'api/sam/auditLogs'
import { DetailsGroup } from 'components/DetailsGroup'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { SamPolicyLogTrainingsTable } from '../components/SamPolicyLogTrainingsTable'

export const SamPolicyUpdateSideLog = (props: { log: SAMPolicyUpdateLog }) => {
  const { log } = props

  const { mandatory_trainings, mandatory_trainings_added, mandatory_trainings_deleted } =
    log.data

  return (
    <>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Policy</Subheader.Title>
        </Subheader>
        <DetailsGroup
          rows={[
            ['Policy name', 'name'],
            ['Description', log.data.business_reason || EMPTY_VALUE_FALLBACK],
          ]}
        />
      </Box>
      <SamPolicyLogTrainingsTable
        title="Mandatory trainings added"
        trainingIds={mandatory_trainings_added}
      />
      <SamPolicyLogTrainingsTable
        title="Mandatory trainings deleted"
        trainingIds={mandatory_trainings_deleted}
      />
      <SamPolicyLogTrainingsTable
        title="Mandatory trainings summary"
        trainingIds={mandatory_trainings}
      />
    </>
  )
}
