import { useQuery } from '@tanstack/react-query'
import samApi from 'api/sam'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Optioned } from '../types'
import { isEnabled, makeMappedKeyDataHook } from '../utils'

export const useQuerySamUser = ({ params: id, options }: Optioned<string>) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamUserTrainings, id],
    queryFn: () => samApi.policies.getUserDetails(id),
    select: (response) => response.data,
    retryOnMount: false,
    enabled: isEnabled(
      hasPermission(SAM_PERMISSIONS.USER_POLICIES_VIEW_DETAILS),
      options,
    ),
  })
}

export const useQueryPolicyUsers = (id: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamPolicyEmployees, id],
    queryFn: () => samApi.policies.employees(id),
    select: (response) => response?.data.employeeIds,
    enabled: hasPermission(SAM_PERMISSIONS.POLICIES_VIEW_EMPLOYEES_LIST),
  })
}

export const useQueryEmployees = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.SamEmployees],
    queryFn: samApi.revoluters.getEmployees,
    select: (response) => response?.data.employees,
    enabled: hasPermission(SAM_PERMISSIONS.REVOLUTERS_VIEW_EMPLOYEES_LIST),
  })
}

export const useQueryEmployeeMap = makeMappedKeyDataHook(useQueryEmployees, 'iDaveUserId')
export const useQueryEmployeePeopleOpsMap = makeMappedKeyDataHook(
  useQueryEmployees,
  'revolutersId',
)

export const useQueryAvatars = () =>
  useQuery({
    queryKey: [QueryKey.RevolutersAvatars],
    queryFn: samApi.revoluters.getAvatars,
  })

export const useQueryMappedEmployeeIdAvatars = makeMappedKeyDataHook(
  useQueryAvatars,
  'revolutersId',
)
