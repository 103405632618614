import { useQuery } from '@tanstack/react-query'
import { Page } from 'hooks/useTokenize/types'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUsers } from 'queries/idave/users'
import { findEntityById } from 'utils/array'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { useErrorToast } from 'hooks/useErrorToast'
import { getEntityNameRequest } from './utils'

export const useImage = (page: Page) => {
  const { data: users = [] } = useQueryUsers()
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()

  return page.type === 'user'
    ? getAvatarUrl({
        user: findEntityById(users, page.entityId),
        avatars,
      })
    : undefined
}

export const useEntityName = (page: Page) => {
  const type = page.type
  const entityId = 'entityId' in page ? page.entityId : undefined
  const handler = useErrorToast()

  return useQuery({
    // eslint-disable-next-line  @tanstack/query/exhaustive-deps
    queryKey: [type, entityId],
    queryFn: () => getEntityNameRequest(page),
    onError: handler,
  })
}
