import { AxiosResponse } from 'axios'
import { UseQueryResult } from '@tanstack/react-query'
import { identity, isEmpty } from 'lodash'
import { mapify } from 'utils/array'
import { notNullable } from 'utils/common'
import { OuterOptions } from './types'

export const isEnabled = (allowed: boolean, options?: OuterOptions) => {
  const optionEnabled = typeof options?.enabled === 'boolean' ? options.enabled : true

  return optionEnabled && allowed
}

export const getLogsNextPageParam =
  (limitsFrom?: number) => (lastPage: AxiosResponse) => {
    const from = lastPage.headers['x-scanned-from']
    const to = lastPage.headers['x-scanned-to']
    const isEndOfScan = lastPage.headers['x-end-of-scan'] === 'true'

    if (isEndOfScan || from === to) {
      return undefined
    }

    if (limitsFrom && limitsFrom >= Number(from)) {
      return undefined
    }

    return {
      to: Number(from),
    }
  }

type DataMap<Data, Entity> = {
  data?: Data
  value?: Map<string, Entity>
}

export const makeMappedDataHook = <Data, Entity, HookParams = void>(
  useDataHook: (params: HookParams) => UseQueryResult<Data>,
  getEntities: (response: Data) => Entity[],
  matcher: (item: Entity) => string | undefined,
) => {
  let cacheStore: DataMap<Data, Entity> = {}

  return (params: HookParams) => {
    const { data, ...query } = useDataHook(params)
    if (cacheStore.data !== data) {
      const entities = data ? getEntities(data) : []
      cacheStore = {
        data,
        value: mapify(entities, matcher),
      }
    }
    return { data: cacheStore.value, rawData: cacheStore.data, ...query }
  }
}

export const makeMappedKeyDataHook = <Entity, HookParams = void>(
  useDataHook: (hookParams: HookParams) => UseQueryResult<Entity[]>,
  key: keyof Entity,
) =>
  makeMappedDataHook<Entity[], Entity, HookParams>(useDataHook, identity, (i) =>
    String(i[key]),
  )

type BaseValue = null | undefined | string | boolean | number
type ArrayValue = BaseValue[]
type ParamsValue = BaseValue | ArrayValue
type Params = {
  [key: string]: ParamsValue
}

const notEmptyValue = (value: ParamsValue) => {
  if (typeof value === 'string') {
    return !isEmpty(value)
  }

  if (typeof value === 'number') {
    return !Number.isNaN(value)
  }

  if (Array.isArray(value)) {
    const notNullableValues: BaseValue[] = value.filter(notEmptyValue)
    return !isEmpty(notNullableValues)
  }

  return notNullable(value)
}

/**
 * returns normalized params string value, like:
 * - key=value&key2=['value2', 'value3']
 *
 * usable for defining stable query keys
 * */

export const getParamsKey = (params: Params) => {
  const keys = Object.keys(params)
    .filter((key) => notEmptyValue(params[key]))
    .sort()

  return keys.reduce((acc, key, idx) => {
    const infix = idx ? '&' : ''
    const paramsValue = params[key]

    const value = Array.isArray(paramsValue)
      ? paramsValue.filter(notEmptyValue).sort()
      : paramsValue

    return `${acc}${infix}${key}=${JSON.stringify(value)}`
  }, '')
}
