import { TableColumn } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { Group } from 'api/idave/groups'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { GroupCell, getGroupCellText } from 'components/GroupCell'
import { StateCell, stateCellAccessor } from 'components/StateCell'

export const getColumns = (groupMap: Map<string, Group>): TableColumn<Client>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    width: 200,
  },
  {
    Header: 'Scope',
    accessor: 'scope',
    width: 200,
  },
  {
    Header: 'Owner group',
    accessor: ({ ownerGroupId }: Client) => getGroupCellText({ ownerGroupId, groupMap }),
    Cell: (params: { row: { original: Client } }) => (
      <GroupCell groupMap={groupMap} ownerGroupId={params.row.original.ownerGroupId} />
    ),
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 200,
  },
  {
    Header: 'Status',
    accessor: stateCellAccessor,
    Cell: (params: { row: { original: Client } }) => (
      <StateCell row={params.row} cellProps={{ align: 'start' }} />
    ),
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 100,
  },
  {
    Header: 'Source',
    accessor: 'source',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 100,
  },
]
