import { Role } from 'api/idave/roles'
import { useCallback, useState } from 'react'
import { Permission } from 'api/idave/permissions'
import { pluralForm } from 'utils/string'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useQueryToxicPairs } from 'queries/idave/permissions'
import { PermissionIdPair } from 'view/Permissions/types'
import { matchToxicPairIdsByPermissionPairs } from 'utils/toxicPairs/matchToxicPairIdsByPermissionPairs'
import { RolePermissionsAdding } from './components/RolePermissionAdding'
import { RolePermissionsWidget } from './components/RolePermissionsWidget'
import { useToxicPermissionSide } from '../ToxicPermissionSide'
import { useEditRolePermissions } from './useEditRolePermissions'

export const RolePermissions = ({ role }: { role: Role }) => {
  const { open: openToxicSide, close: closeToxicSide } = useToxicPermissionSide()
  const { data: toxicPairs = [] } = useQueryToxicPairs()

  const [isPermissionAdding, setIsPermissionAdding] = useState(false)
  const closeAdding = useCallback(
    () => setIsPermissionAdding(false),
    [setIsPermissionAdding],
  )
  const openAdding = useCallback(
    () => setIsPermissionAdding(true),
    [setIsPermissionAdding],
  )

  const { closePopup, setPopup } = useConfirmationPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const onMutate = useCallback(() => {
    showLoadingPopup({ title: 'Updating permissions...' })
    closeToxicSide()
  }, [showLoadingPopup, closeToxicSide])

  const onSuccess = useCallback(() => {
    hideLoadingPopup()
    closeAdding()
    closePopup()
  }, [closeAdding, closePopup, hideLoadingPopup])

  const onError = useCallback(
    (error: unknown) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Permissions updating failed',
        error,
      })
    },
    [closePopup, hideLoadingPopup, showErrorPopup],
  )

  const onErrorToxicPair = useCallback(
    (toxicPermissionPairs: PermissionIdPair[]) => {
      openToxicSide(matchToxicPairIdsByPermissionPairs(toxicPermissionPairs, toxicPairs))
      showErrorPopup({
        title: 'Toxic pair was detected.',
        message:
          "Please, review the permissions you're trying to add and remove ones that include toxic pairs",
      })
    },
    [openToxicSide, showErrorPopup, toxicPairs],
  )

  const { mutate: setPermissions, isLoading } = useEditRolePermissions({
    role,
    onMutate,
    onSuccess,
    onError,
    onErrorToxicPair,
  })

  const addPermission = useCallback(
    (permissions: Permission[]) => {
      const newPermissions = [...role.permissions, ...permissions.map((i) => i.id)]
      setPermissions({ permissions: newPermissions, type: 'add' })
    },
    [role, setPermissions],
  )

  const deletePermissions = useCallback(
    (permissions: string[]) => {
      const newPermissions = role.permissions.filter(
        (permission) => !permissions.includes(permission),
      )
      setPopup({
        title: 'Permission deletion confirmation',
        confirmButtonText: 'Delete',
        message: pluralForm(permissions.length, [
          'Are you sure you want to delete selected permission?',
          'Are you sure you want to delete selected permissions?',
        ]),
        onConfirmClick: () =>
          setPermissions({ permissions: newPermissions, type: 'delete' }),
      })
    },
    [role, setPermissions, setPopup],
  )

  return (
    <>
      {isPermissionAdding && (
        <RolePermissionsAdding
          role={role}
          closeOverlay={closeAdding}
          addPermission={addPermission}
          isUploading={isLoading}
        />
      )}
      <RolePermissionsWidget
        openToxicSide={openToxicSide}
        role={role}
        openAdding={openAdding}
        deletePermissions={deletePermissions}
        isLoading={isLoading}
      />
    </>
  )
}
