import { AccessModifierPayload } from 'api/dart/types'
import { EmployeeType, SamPolicySubjectType } from 'api/sam/policies'
import { ClientReference } from 'api/idave/clients'
import { ServiceName } from 'services/permissions'

export type CrossCheckSystem = ServiceName
export type CrossCheckStatus =
  | 'WAITING_FOR_APPROVAL'
  | 'WAITING_FOR_SUPPORT'
  | 'MORE_INFO_REQUIRED'
  /** @TODO cleanup after sam migration */
  | 'WAITING_APPROVAL'
  | 'WAITING_SUPPORT'

export type ChangeStatusCommand = 'request_info' | 'provide_info'

export enum CrossCheckState {
  Requested = 'REQUESTED',
  AwaitingReview = 'AWAITING_REVIEW',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Executed = 'EXECUTED',
  Declined = 'DECLINED',
  // @todo remove after Idave migration
  ExecutionSucceed = 'EXECUTION_SUCCEED',
}

export type CrossCheckEntityType =
  | IDaveCrossCheckEntityType
  | SamCrossCheckEntityType
  | DartCrossCheckEntityType

export type IDaveCrossCheckEntityType = 'Role' | 'User' | 'Client' | 'ServiceAccount'
export type SamCrossCheckEntityType = 'AccessPolicy' | 'Recertification'
export type DartCrossCheckEntityType = 'EmployeeCompany' | 'CustomerCompany'

export type CrossCheckCommentPayload = {
  comment: string
}

export type CrossCheckDecision = 'APPROVE' | 'REJECT'
export type CrossCheckReviewPayload = {
  decision: CrossCheckDecision
  reason?: string
}

export type CrossJustifyPayload = {
  justification: string
}

export type CrossCheckDeclinePayload = {
  reason?: string
}

export type CrossCheckCreatedEvent = {
  id: string
}

export type CrossCheckGroup = {
  groupId: string
  name: string
  reviewers: string[]
}

export type CrossCheckAddReviewerPayload = {
  reviewers: string[]
  reason?: string
}

export type CrossCheckRule = AnyUserRule | RequiredGroupReview
export type AnyUserRule = {
  type: 'ANY_USER_REVIEW'
  description: string
  approvals: number
}

export type RequiredGroupReview = {
  type: 'REQUIRED_GROUP_REVIEW'
  description: string
  approvals: number
  groupIds: string[]
}

export type CrossCheckEvent =
  | ApproveEvent
  | RejectEvent
  | JustifyEvent
  | AddReviewerEvent
  | CommentEvent
  | DeclineEvent

export type ApproveEvent = {
  type: 'APPROVE'
  id: string
  author: string
  createdDate: number
  text?: string
}

export type RejectEvent = {
  type: 'REJECT'
  id: string
  author: string
  createdDate: number
  text?: string
}

export type JustifyEvent = {
  type: 'JUSTIFY'
  id: string
  author: string
  createdDate: number
  text: string
}

export type CommentEvent = {
  type: 'COMMENT'
  id: string
  author: string
  createdDate: number
  text: string
}

export type DeclineEvent = {
  type: 'DECLINE'
  id: string
  author: string
  createdDate: number
  text?: string
}

export type AddReviewerEvent = {
  type: 'ADD_REVIEWERS'
  id: string
  author: string
  createdDate: number
  text?: string
  reviewers: string[]
  groupId: string
}

export type CrossCheck = {
  id: string
  createdDate: number
  updatedDate: number
  state: CrossCheckState
  requester: string
  entityType: CrossCheckEntityType
  entityId?: string
  executableName: CrossCheckExecutableName
  status?: CrossCheckStatus
}

export type CrossCheckDetail = CrossCheck & {
  reviewerGroups: CrossCheckGroup[]
  rules: CrossCheckRule[]
  events: CrossCheckEvent[]
  executableParams: CrossCheckExecutableParams
}

export type CrossCheckExecutableName =
  | CrossCheckSamExecutableName
  | CrossCheckIdaveExecutableName
  | CrossCheckDartExecutableName

type CrossCheckSamExecutableName =
  | 'CreatePolicy'
  | 'UpdatePolicy'
  | 'DeletePolicy'
  | 'ApproveRecertification'

type CrossCheckIdaveExecutableName =
  | 'RoleCreate'
  | 'RoleUpdate'
  | 'RoleAssign'
  | 'ClientCreate'
  | 'ClientUpdate'
  | 'ClientDelete'
  | 'ServiceAccountCreate'
  | 'ServiceAccountDelete'
  | 'ServiceAccountUpdate'
  | 'ServiceAccountActivate'
  | 'ServiceAccountTerminate'
  | 'PermissionMatrixSynchronizer'
  | 'PermissionDelete'

export type CrossCheckDartExecutableName =
  | 'DartEmployeeAddAccessModifiers'
  | 'DartEmployeeDeleteAccessModifiers'
  | 'DartEmployeeCompanyAddAccessModifiers'
  | 'DartEmployeeCompanyDeleteAccessModifiers'
  | 'RecertificationApprove'

export type CrossCheckExecutableParams = IDaveExecutableParams &
  SamExecutableParams &
  DartExecutableParams

export type CrossCheckExecutableStringParam = {
  type: 'string'
  value: string
}

export type CrossCheckExecutableNumberParam = {
  type: 'number'
  value: number
}

export type CrossCheckExecutableBooleanParam = {
  type: 'boolean'
  value: boolean
}

export type CrossCheckExecutableTimestampParam = {
  type: 'timestamp'
  value: number
}

export type CrossCheckExecutableEntityParam = {
  type: 'entity'
  value: {
    id: string
    entity: string
  }
}

export type CrossCheckExecutableArrayParam = {
  type: 'array'
  value: CrossCheckExecutableParam[]
}

export type CrossCheckExecutableParam =
  | CrossCheckExecutableStringParam
  | CrossCheckExecutableNumberParam
  | CrossCheckExecutableBooleanParam
  | CrossCheckExecutableTimestampParam
  | CrossCheckExecutableEntityParam
  | CrossCheckExecutableArrayParam

type CrossCheckExecutionResult = 'SUCCESS' | 'FAILURE'

export type CrossCheckExecution = {
  id: string
  result: CrossCheckExecutionResult
  startedDate: number
  completedDate: number
  error?: string
}

export type Entity = 'Role' | 'Client' | 'Group' | 'Permission' | 'User'

export type StringParam = {
  type: 'string'
  value: string
}

export type NumberParam = {
  type: 'number'
  value: number
}

export type BooleanParam = {
  type: 'boolean'
  value: boolean
}

export type EntityParamValue<EntityType extends Entity> = {
  id: string
  entity: EntityType
}
export type EntityParam<EntityType extends Entity> = {
  type: 'entity'
  value: EntityParamValue<EntityType>
}

type AuthenticationParam = {
  type: 'authentication'
  value: {
    gcp?: string
  }
}

export type ObjectParam<T> = {
  type: 'object'
  value: T
}

export type AssignedRole = {
  type: 'entity'
  value: {
    id: string
    entity: 'Role'
    expiryDate?: number
  }
}

export type ArrayParams<T> = {
  type: 'array'
  value: Array<T>
}

export type ExternalRef = {
  type: 'externalref'
  value: ClientReference
}

export const REMOVED_MARK = '<Removed>' as const
export type RemovedParent = { type: 'string'; value: typeof REMOVED_MARK }

type IDaveExecutableParams = {
  Name?: StringParam
  Description?: StringParam
  'Owner Group'?: EntityParam<'Group'>
  Parent?: EntityParam<'Role'> | RemovedParent
  'New Approvers'?: ArrayParams<EntityParam<'User'>>
  'Removed Approvers'?: ArrayParams<EntityParam<'User'>>

  Permissions?: ArrayParams<EntityParam<'Permission'>>
  'New Permissions'?: ArrayParams<EntityParam<'Permission'>>
  'Removed Permissions'?: ArrayParams<EntityParam<'Permission'>>

  'New Redirection Uri'?: ArrayParams<StringParam>
  'Removed Redirection Uri'?: ArrayParams<StringParam>
  'Redirection Uri'?: ArrayParams<StringParam>

  Roles?: ArrayParams<AssignedRole>
  User?: EntityParam<'User'>

  Authentication?: AuthenticationParam
  Client?: EntityParam<'Client'>

  Scope?: ArrayParams<EntityParam<'Client'>> | StringParam
  'New Scope'?: ArrayParams<EntityParam<'Client'>>
  'Removed Scope'?: ArrayParams<EntityParam<'Client'>>

  'External Reference'?: ExternalRef

  Source?: StringParam | EntityParam<'Client'>
  Destination?: EntityParam<'Client'>

  'Source (Scope Name)'?: StringParam
  'Destination (Scope Name)'?: StringParam

  'Enable Service Account Authentication'?: BooleanParam
}

type DiffedIds = {
  added?: string[]
  removed?: string[]
} | null

type SamExecutableParams = {
  business_reason?: string
  mandatory_trainings?: string[]
  name?: string
  resources?: string[]
  resources_diff?: DiffedIds
  mandatory_trainings_diff?: DiffedIds
  subject_type?: SamPolicySubjectType
  subject_params?: {
    subject_type?: SamPolicySubjectType
    subject_employee_types?: EmployeeType[]
    subject_seniority_ids?: string[]
    subject_specialisation_ids?: string[]
    subject_team_ids?: string[]
    subject_employee_ids?: string[]
    subject_department_ids?: string[]
  }
}

export type DartAccessModifierExecutableParam =
  | { employeeId: string; accessModifier: AccessModifierPayload }
  | { employeeCompanyId: string; accessModifier: AccessModifierPayload }

type DartExecutableParams = {
  'Access Modifiers'?: ArrayParams<ObjectParam<DartAccessModifierExecutableParam>>
}
