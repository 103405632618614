import { SelectInput, SelectInputProps, SelectOptionItemType } from '@revolut/ui-kit'
import { SENIORITY_OPTIONS } from './constants'

type StringSelect = SelectInputProps<string, SelectOptionItemType<string>>

type Props = Omit<StringSelect, 'options'>

export const SeniorityIdSelect = (props: Props) => (
  <SelectInput {...props} options={SENIORITY_OPTIONS} />
)
