import { SamPolicySubjectType } from 'api/sam/policies'
import { SamPolicySubjectField } from '../types'

export const isMainField = (
  field: SamPolicySubjectField,
  subjectType?: SamPolicySubjectType,
) => {
  return (
    (field === 'departmentIds' && subjectType === SamPolicySubjectType.Department) ||
    (field === 'teamIds' && subjectType === SamPolicySubjectType.Team) ||
    (field === 'employeeTypes' && subjectType === SamPolicySubjectType.EmployeeType) ||
    (field === 'specialisationIds' &&
      subjectType === SamPolicySubjectType.Specialisation) ||
    (field === 'employeeIds' && subjectType === SamPolicySubjectType.Employee)
  )
}

export * from './name'
export * from './rules'
export * from './fields'
