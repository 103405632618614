import { useQuery } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { makeMappedKeyDataHook } from 'queries/utils'
import { IDAVE_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'

export const useQueryOrgs = () => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Organisations],
    queryFn: idaveApi.organisations.getOrgs,
    enabled: hasPermission(IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST),
    select: (response) => response.data,
  })
}

export const useQueryOrgIdMap = makeMappedKeyDataHook(useQueryOrgs, 'id')

export const useQueryOrg = (orgId: string) => {
  const { hasPermission } = usePermissions()
  return useQuery({
    queryKey: [QueryKey.Organisation, orgId],
    queryFn: () => idaveApi.organisations.getOrg(orgId),
    enabled: hasPermission(IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_DETAILS),
    select: (response) => response.data,
  })
}
