import { Table, TableColumn } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { Client } from 'api/idave/clients'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { InternalLink } from 'components/Links/InternalLink'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { RolePermission } from '../../types'

export const getColumns = (
  roleMap: Map<string, Role>,
  clientMap: Map<string, Client>,
  currentRole: string,
): TableColumn<RolePermission>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    width: 200,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },

  {
    Header: 'Application',
    accessor: (value) =>
      value.clientId
        ? clientMap.get(value.clientId)?.name || value.clientId
        : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: RolePermission } }) => {
      const { clientId } = params.row.original
      return (
        <Table.Cell>
          {clientId ? (
            <InternalLink href={generatePath(Url.Client, { clientId })}>
              {clientMap.get(clientId)?.name || clientId}
            </InternalLink>
          ) : (
            EMPTY_VALUE_FALLBACK
          )}
        </Table.Cell>
      )
    },
    width: 100,
  },
  {
    Header: 'Inherited from',
    accessor: (value) =>
      value.ownerRoleId !== currentRole
        ? roleMap.get(value.ownerRoleId)?.name || value.ownerRoleId
        : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: RolePermission } }) => {
      const { ownerRoleId } = params.row.original
      return (
        <Table.Cell>
          {ownerRoleId !== currentRole ? (
            <InternalLink href={generatePath(Url.RoleDetails, { roleId: ownerRoleId })}>
              {roleMap.get(ownerRoleId)?.name || ownerRoleId}
            </InternalLink>
          ) : (
            EMPTY_VALUE_FALLBACK
          )}
        </Table.Cell>
      )
    },
    width: 200,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
]
