import { Page, TabBar, TextSkeleton } from '@revolut/ui-kit'
import { OrganisationData } from 'api/idave/organisations'
import { QuerySwitch } from 'components/QuerySwitch'
import { TabBarLink } from 'components/TabBarLink'
import { useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { OrganisationTab } from 'view/Organisations/type'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { useQueryOrg } from 'queries/idave/orgs'
import { NoAccessPage } from 'components/NoAccessWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import { OrganisationAuditLog } from './components/OrganisationAuditLog'
import { OrganisationDetails } from './components/OrganisationDetails'
import { OrganisationSkeleton } from './components/OrganisationSkeleton'

export const Organisation = (props: { organisationId: string; tab: OrganisationTab }) => {
  const { organisationId, tab } = props
  const { data: organisation, status, fetchStatus, refetch } = useQueryOrg(organisationId)

  const organisationsRequestStatus = useMemo(
    () => [{ qs: status, fs: fetchStatus }],
    [status, fetchStatus],
  )

  const onBackClick = useNavigateToList({
    listUrl: Url.Organisations,
    entityUrl: generatePath(Url.Organisation, { organisationId }),
  })

  return (
    <QuerySwitch
      required={organisationsRequestStatus}
      data={organisation}
      renderLoading={() => (
        <Outer organisationId={organisationId} toOrgs={onBackClick}>
          <OrganisationSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data: org }) => (
        <Outer organisationId={organisationId} organisation={org} toOrgs={onBackClick}>
          <Inner tab={tab} organisation={org} />
        </Outer>
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={organisationId}
          title="Something went wrong"
          description="Organisations fetch failed or organisation not found"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => (
        <NoAccessPage pageTitle={organisationId} onBackClick={onBackClick} />
      )}
    />
  )
}

const Outer = (props: {
  organisationId: string
  children: React.ReactNode
  organisation?: OrganisationData
  toOrgs: () => void
}) => {
  const { organisationId, children, organisation, toOrgs } = props

  return (
    <>
      <Page.Header onBack={toOrgs}>
        {organisation?.name || <TextSkeleton size={16} />}
      </Page.Header>

      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.OrganisationDetails, { organisationId })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST,
              IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST,
            ]}
          >
            <TabBarLink to={generatePath(Url.OrganisationAuditLog, { organisationId })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>{children}</Page.Main>
    </>
  )
}

const Inner = ({
  tab,
  organisation,
}: {
  organisation: OrganisationData
  tab: OrganisationTab
}) => {
  switch (tab) {
    case 'auditLog':
      return <OrganisationAuditLog organisationId={organisation.id} />
    default:
    case 'details':
      return <OrganisationDetails organisation={organisation} />
  }
}
