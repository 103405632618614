import axios, { AxiosPromise } from 'axios'

export type Credentials = {
  username: string
  password: string
}

export type CredentialsResponse = {
  message: string
  code: number
}

export type SsoUser = {
  user: {
    firstName: string
    lastName: string
    email: string
  }
}

export const AuthErrorCode = {
  OTP_REQUIRED: 2020,
  INVALID_CREDENTIALS: 2030,
}

const auth = {
  submitCredentials: (
    credentials: Credentials,
    params: string,
  ): AxiosPromise<CredentialsResponse> =>
    axios.post(`/api/auth/credentials?${params}`, credentials),

  submitOtp: (code: string): AxiosPromise<{ redirectUri: string }> =>
    axios.post(`/api/auth/2fa/verify?code=${code}`),

  submitGoogleToken: (idToken: string) =>
    axios.post('/api/auth/google-token', { id_token: idToken }),

  getSsoUser: () => axios.get<SsoUser>('/api/auth/session'),

  postSsoAuth: () => axios.post<void>('/api/auth/session'),

  logout: () => axios.post<void>('/api/oauth2/logout'),

  logoutSso: () => axios.post<void>('/api/auth/logout'),
}

export default auth
