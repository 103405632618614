import { EntityType } from './common'

export type XCheckReadableParams = {
  sections: ParamsSection[]
}

export type ParamsSection = {
  initial: { value: XCheckParam[] }
  changes: { value: XCheckParam[] }
  result: { value: XCheckParam[] }
}

export enum ParamAction {
  edit = 'EDIT',
  add = 'ADD',
  delete = 'DELETE',
}

type TypeValueFactory<Descriptor, ValueType, AdditionalTypeFields = {}> = {
  type: {
    type: Descriptor
  } & AdditionalTypeFields
  value: ValueType
}

type ParamTypeValue =
  | ArrayTypeValue
  | BoolTypeValue
  | EntityTypeValue
  | EnumTypeValue
  | NumberTypeValue
  | TextTypeValue
  | TimestampTypeValue
  | UUIDTypeValue

type ArrayTypeValue = TypeValueFactory<
  'array',
  any[],
  {
    subtype: ParamTypeValue['type']
  }
>
type BoolTypeValue = TypeValueFactory<'bool', boolean>
type EntityTypeValue = TypeValueFactory<
  'entity',
  { id: string; name: string; attributes?: Record<string, string> },
  { entityType: EntityType }
>
type EnumTypeValue = TypeValueFactory<'enum', string, { possibleValues: string[] }>
type TextTypeValue = TypeValueFactory<'text', string>
type TimestampTypeValue = TypeValueFactory<'timestamp', number>
type UUIDTypeValue = TypeValueFactory<'uuid', string>
type NumberTypeValue = TypeValueFactory<'number', number>

export type XCheckParam = {
  name: string
  actions: ParamAction[]
} & ParamTypeValue
