import idaveApi from 'api/idave'
import samApi from 'api/sam'
import dartApi from 'api/dart'
import { AxiosPromise } from 'axios'
import { Page } from 'hooks/useTokenize/types'
import { get } from 'lodash'
import { findEntityById } from 'utils/array'
import { getRequest } from 'view/CrossChecks/utils'
import { getName as getUserName } from 'utils/string/getName'
import { IconName } from '@revolut/ui-kit'

export const getIcon = (type: Page['type']): IconName | undefined => {
  switch (type) {
    case 'client':
      return 'Apps'
    case 'organisation':
      return 'Bank'
    case 'permission':
      return 'Controls'
    case 'policy':
      return 'Key'
    case 'role':
      return '40/SubtractGroupVault'
    case 'idaveCrossCheck':
    case 'samCrossCheck':
    case 'dartCrossCheck':
      return 'CheckSuccess'

    case 'customerCompany':
    case 'dartLogs':
    case 'employeeAccesses':
    case 'employeeCompany':
    case 'employeeRestrictions':
      return 'LockClosed'
    case 'idaveLogs':
      return 'ArrowRepeat'
    case 'user':
      return 'Profile'
    default:
      return undefined
  }
}

export const findName = <T extends Record<string, unknown>>(
  request: () => AxiosPromise<T[]>,
  id: string,
  fallbackName: string,
  nameKey = 'name',
) =>
  request()
    .then(({ data }) => {
      const entity = findEntityById(data, id)
      return get(entity, nameKey, fallbackName) as string
    })
    .catch(() => {
      return fallbackName
    })

export const getName = <T extends Record<string, unknown>>(
  request: () => AxiosPromise<T>,
  fallbackName: string,
  nameKey = 'name',
) =>
  request()
    .then(({ data }) => get(data, nameKey, fallbackName) as string)
    .catch(() => fallbackName)

const getCrossCheckMethod = (type: Page['type']) => {
  switch (type) {
    case 'samCrossCheck':
      return samApi.crossChecks.getCrossCheckDetails
    case 'dartCrossCheck':
      return dartApi.crossChecks.getCrossCheckDetails
    case 'idaveCrossCheck':
    default:
      return idaveApi.crossChecks.getCrossCheckDetails
  }
}

export const getEntityNameRequest = async (page: Page): Promise<string | null> => {
  try {
    switch (page.type) {
      case 'user':
        try {
          const userInfo = (await idaveApi.user.getUser(page.entityId)).data
          return userInfo ? getUserName(userInfo) : `User (${page.entityId})`
        } catch (_e) {
          return `Unknown user (${page.entityId})`
        }

      case 'client':
        return findName(
          idaveApi.clients.getClients,
          page.entityId,
          `Unknown app (${page.entityId})`,
        )
      case 'organisation':
        return findName(
          idaveApi.organisations.getOrgs,
          page.entityId,
          `Unknown org (${page.entityId})`,
        )
      case 'permission':
        return getName(
          () => idaveApi.permissions.getPermission(page.entityId),
          `Unknown permission (${page.entityId})`,
        )
      case 'role':
        return getName(
          () => idaveApi.roles.getRoleMVP(page.entityId),
          `Unknown role (${page.entityId})`,
        )
      case 'policy':
        return getName(
          () => samApi.policies.getPolicy(page.entityId),
          `Unknown policy (${page.entityId})`,
          'policyName',
        )
      case 'employeeCompany':
        return findName(
          dartApi.employeeCompany.getEmployeeCompanies,
          page.entityId,
          `Unknown employee company (${page.entityId})`,
        )
      case 'customerCompany':
        return getName(
          () => dartApi.customerCompany.getCustomerCompany({ id: page.entityId }),
          `Unknown customer company (${page.entityId})`,
        )

      case 'employeeAccesses':
        return 'User accesses'
      case 'employeeRestrictions':
        return 'User restrictions'
      case 'dartLogs':
        return 'Data access logs'
      case 'idaveLogs':
        return 'Audit logs'
      case 'dartCrossCheck':
      case 'idaveCrossCheck':
      case 'samCrossCheck':
        const method = getCrossCheckMethod(page.type)

        try {
          const crossCheckResponse = await method(page.entityId)
          const crossCheck = crossCheckResponse?.data
          if (!crossCheck) {
            return `Crosscheck (${page.entityId})`
          }
          const crossCheckName = getRequest(crossCheck.executableName)

          return crossCheckName || `Crosscheck (${page.entityId})`
        } catch (_e) {
          return `Unknown crosscheck (${page.entityId})`
        }
      default:
        return Promise.reject()
    }
  } catch (_e) {
    return Promise.resolve(null)
  }
}
