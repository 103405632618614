import { useFormContext, useController } from 'react-hook-form'
import { InputProps } from '@revolut/ui-kit'
import { Fielded } from 'utils/types/fielded'
import { Input } from 'components/Inputs'
import { getFieldErrorState } from '../utils'

type Props = Fielded<InputProps>

export const FieldInput = (props: Props) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control, rules })

  return <Input {...field} {...rest} {...getFieldErrorState(fieldState)} />
}
