export const getLabelWithMaxLength = (
  label?: string,
  maxLength?: number,
  value?: string,
) => {
  if (!label) {
    return undefined
  }

  if (!maxLength || !value || maxLength / 2 >= value.length) {
    return label
  }

  return `${label} (Max: ${value.length}/${maxLength})`
}
