import { PEOPLE_URL } from 'constants/urls'
import { TextSkeleton } from '@revolut/ui-kit'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUserPeopleOpsIdMap } from 'queries/idave/users'
import { QuerySwitch } from 'components/QuerySwitch'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { UserLinkView } from '../common/UserLinkView'
import { ExternalTextLink } from '../ExternalTextLink'

type UserLinkProps = {
  id: string | null | undefined
}

export const getRevoulutersEmployeeLink = (id: string) =>
  `${PEOPLE_URL}/employees/preview/${id}`

export const RevolutersEmployeeLink = ({ id }: UserLinkProps) => {
  const { data: users = new Map(), status, fetchStatus } = useQueryUserPeopleOpsIdMap()
  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedEmployeeIdAvatars()

  if (!id) {
    return <>{EMPTY_VALUE_FALLBACK}</>
  }

  return (
    <QuerySwitch
      data={users.get(id)}
      required={[{ qs: status, fs: fetchStatus }]}
      optional={[{ qs: avatarsStatus, fs: avatarsFS }]}
      renderLoading={() => <TextSkeleton size={14} />}
      renderError={() => <FallbackLink id={id} />}
      renderIdle={() => <FallbackLink id={id} />}
      renderSuccess={({ data }) => (
        <UserLinkView
          href={getRevoulutersEmployeeLink(id)}
          id={data.id}
          name={getName(data)}
          avatarUrl={getAvatarUrl({ user: data, avatars })}
          state={data.state}
          target="_blank"
          displayId={id}
          isExternal
        />
      )}
    />
  )
}

type FallBackProps = { id: string }
const FallbackLink = ({ id }: FallBackProps) => (
  <ExternalTextLink href={getRevoulutersEmployeeLink(id)} text={id} />
)
