import { SelectInput, VStack } from '@revolut/ui-kit'
import { DateInput } from '@revolut/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { DAY } from 'utils/constants/time'
import { AssignTimer, DurationType } from './types'
import { getDefaultDueTo, getDefaultTimer, getDurationOptions } from './utils'

type AssignTimerProps = {
  onChange: (assignTimer: AssignTimer) => void
  defaultValue?: AssignTimer
  defaultOffset?: number
  label?: string
  onlyTemporary?: boolean
}

export const AssignmentTimer = ({
  onChange,
  defaultValue,
  defaultOffset,
  label = 'Access duration',
  onlyTemporary,
}: AssignTimerProps) => {
  const [timer, setTimer] = useState<AssignTimer | undefined>(defaultValue)

  useEffect(() => {
    if (!defaultValue) {
      const newTimer = getDefaultTimer(defaultOffset, onlyTemporary)

      setTimer(newTimer)
      onChange(newTimer)
    }
  }, [defaultValue, defaultOffset, setTimer, onChange, onlyTemporary])

  const onDurationTypeChange = useCallback(
    (newType: DurationType | null) => {
      if (newType) {
        const newTimer = {
          type: newType,
          value: newType === 'permanent' ? undefined : getDefaultDueTo(defaultOffset),
        } as AssignTimer
        setTimer(newTimer)
        onChange(newTimer)
      }
    },
    [setTimer, onChange, defaultOffset],
  )

  const onDueToChange = useCallback(
    (date: null | Date) => {
      if (date && timer?.type === 'temporary') {
        const value = date.getTime()
        const newTimer = {
          type: 'temporary' as const,
          value,
        }
        setTimer(newTimer)
        onChange(newTimer)
      }
    },
    [timer, setTimer, onChange],
  )

  return (
    <VStack space="s-16">
      {!onlyTemporary && (
        <SelectInput
          label={label}
          searchable={false}
          clearable={false}
          options={getDurationOptions(onlyTemporary)}
          value={timer?.type}
          onChange={onDurationTypeChange}
        />
      )}
      {timer?.type === 'temporary' && (
        <DateInput
          clearable={false}
          disabledDays={{
            before: new Date(Date.now() + DAY),
            after: onlyTemporary ? new Date(Date.now() + DAY * 90) : undefined,
          }}
          value={new Date(timer.value)}
          onChange={onDueToChange}
          label="Select Date"
        />
      )}
    </VStack>
  )
}
