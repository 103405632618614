import { SamPolicy, SamPolicySubject, SamPolicySubjectType } from 'api/sam/policies'
import { assertNonReachable } from 'utils/error'
import {
  PolicyDetails,
  DEFAULT_POLICY_DETAILS,
} from 'view/Sam/components/SamEditPolicyDetails'
import { FormState, FormTab, PolicyResources, PolicyTrainings } from './types'
import { getPolicyDescriptionRules } from '../components/SamEditPolicyDetails/utils'

const mapPolicySubjectPolicyDetailsSubject = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Specialisation:
      return {
        subjectType: subject.subjectType,
        specialisationIds: subject.specialisationIds || [],
        seniorityIds: subject?.seniorityIds || [],
        employeeTypes: subject.employeeTypes || [],
      }
    case SamPolicySubjectType.Team:
      return {
        subjectType: subject.subjectType,
        teamIds: subject.teamIds || [],
        employeeTypes: subject.employeeTypes || [],
        specialisationIds: subject.specialisationIds || [],
        seniorityIds: subject.seniorityIds || [],
      }
    case SamPolicySubjectType.Department:
      return {
        subjectType: subject.subjectType || [],
        departmentIds: subject.departmentIds || [],
        employeeTypes: subject.employeeTypes || [],
        specialisationIds: subject.specialisationIds || [],
        seniorityIds: subject.seniorityIds || [],
      }
    case SamPolicySubjectType.EmployeeType:
      return {
        subjectType: subject.subjectType || [],
        employeeTypes: subject.employeeTypes || [],
      }

    case SamPolicySubjectType.Employee:
      return {
        subjectType: subject.subjectType,
        employeeIds: subject.employeeIds || [],
      }

    default:
      return assertNonReachable(subject)
  }
}

export const mapPolicyDetailsSubjectPolicySubject = (
  subject: PolicyDetails['subject'],
) => {
  if (!subject.subjectType) {
    return undefined
  }

  switch (subject.subjectType) {
    case SamPolicySubjectType.Specialisation:
      return {
        subjectType: subject.subjectType,
        specialisationIds: subject.specialisationIds,
        employeeTypes: subject.employeeTypes,
        seniorityIds: subject.seniorityIds,
      }
    case SamPolicySubjectType.Team:
      return {
        subjectType: subject.subjectType,
        teamIds: subject.teamIds,
        employeeTypes: subject.employeeTypes,
        specialisationIds: subject.specialisationIds,
        seniorityIds: subject.seniorityIds,
      }
    case SamPolicySubjectType.Department:
      return {
        subjectType: subject.subjectType,
        departmentIds: subject.departmentIds,
        employeeTypes: subject.employeeTypes,
        specialisationIds: subject.specialisationIds,

        seniorityIds: subject.seniorityIds,
      }
    case SamPolicySubjectType.EmployeeType:
      return {
        subjectType: subject.subjectType,
        employeeTypes: subject.employeeTypes,
      }
    case SamPolicySubjectType.Employee:
      return {
        subjectType: subject.subjectType,
        employeeIds: subject.employeeIds,
      }

    default:
      return assertNonReachable(subject)
  }
}

export const mapPolicyDetails = (
  policy?: SamPolicy,
  storedDetails?: PolicyDetails,
): PolicyDetails => {
  if (!policy) {
    return storedDetails || DEFAULT_POLICY_DETAILS
  }

  return {
    policyName: '',
    businessReason: '',
    subject: mapPolicySubjectPolicyDetailsSubject(policy.subject),
  }
}

export const mapPolicyResources = (
  policy?: SamPolicy,
  storedResources?: PolicyResources,
): PolicyResources => policy?.resourceIds || storedResources || []

export const mapPolicyTrainings = (
  policy?: SamPolicy,
  storedTrainings?: PolicyTrainings,
): PolicyTrainings => policy?.mandatoryTrainings || storedTrainings || []

const isDetailsFilled = (policyDetails: PolicyDetails) => {
  return (
    !!policyDetails.policyName &&
    isFilledSubject(policyDetails) &&
    !getPolicyDescriptionRules().validate(policyDetails.businessReason)
  )
}

const isFilledSubject = (policyDetails: PolicyDetails) => {
  if (!policyDetails.subject.subjectType) {
    return false
  }

  switch (policyDetails.subject.subjectType) {
    case SamPolicySubjectType.Employee:
      return !!policyDetails.subject.employeeIds.length
    case SamPolicySubjectType.EmployeeType:
      return !!policyDetails.subject.employeeTypes.length
    case SamPolicySubjectType.Specialisation:
      return !!policyDetails.subject.specialisationIds.length
    case SamPolicySubjectType.Team:
      return !!policyDetails.subject.teamIds.length
    case SamPolicySubjectType.Department:
      return !!policyDetails.subject.departmentIds.length
    default:
      return false
  }
}

const isResourcesFilled = (policyResources: PolicyResources) => !!policyResources.length

export const checkTabFullness = (tab: FormTab, state: FormState) => {
  const { policyDetails, resources } = state

  switch (tab) {
    case 'details':
      return isDetailsFilled(policyDetails)
    case 'resources':
      return isResourcesFilled(resources)
    case 'trainings':
    default:
      return true
  }
}
