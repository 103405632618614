import { TableColumn } from '@revolut/ui-kit'
import { Group } from 'api/idave/groups'
import { Role } from 'api/idave/roles'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { GroupCell, getGroupCellText } from 'components/GroupCell'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { GroupOwnersCell, useGroupOwnersCellAccessor } from 'components/GroupOwnersCell'
import { useMemo } from 'react'

export const useColumns = (
  roleMaps: Map<string, Role>,
  groupMap: Map<string, Group>,
): TableColumn<Role>[] => {
  const getGroupOwnerAccessor = useGroupOwnersCellAccessor()

  const columns = useMemo(
    (): TableColumn<Role>[] => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 300,
      },
      {
        Header: 'Description',
        accessor: (role) => role.description?.trim() || EMPTY_VALUE_FALLBACK,
        width: 400,
      },
      {
        Header: 'Parent',
        accessor: (role) =>
          role.parent
            ? roleMaps.get(role.parent)?.name || role.parent
            : EMPTY_VALUE_FALLBACK,
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 300,
      },
      {
        Header: 'Owner',
        accessor: ({ ownerGroupId }) => getGroupOwnerAccessor(ownerGroupId),
        Cell: (params: { row: { original: Role } }) => (
          <GroupOwnersCell groupId={params.row.original.ownerGroupId} />
        ),
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 300,
      },
      {
        Header: 'Owner group',
        accessor: ({ ownerGroupId }) => getGroupCellText({ ownerGroupId, groupMap }),
        Cell: (params: { row: { original: Role } }) => (
          <GroupCell
            groupMap={groupMap}
            ownerGroupId={params.row.original.ownerGroupId}
          />
        ),
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 500,
      },
    ],
    [getGroupOwnerAccessor, groupMap, roleMaps],
  )

  return columns
}
