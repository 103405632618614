import { SelectInput, SelectInputProps, SelectOptionItemType } from '@revolut/ui-kit'
import { EmployeeType } from 'api/sam/policies'
import { EMPLOYEE_TYPE_OPTIONS } from './constants'

type EmployeeTypeSelectProps = SelectInputProps<
  string,
  SelectOptionItemType<EmployeeType>
>
type Props = Omit<EmployeeTypeSelectProps, 'options'>

export const EmployeeTypeSelect = (props: Props) => (
  <SelectInput options={EMPLOYEE_TYPE_OPTIONS} {...props} />
)
