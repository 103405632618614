import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { RegionTableCell } from 'components/RegionTableCell'
import { CustomerCompanyMapItem } from './CustomerCompanies'

export const COLUMNS: TableColumn<CustomerCompanyMapItem>[] = [
  {
    Header: 'Company name',
    accessor: 'name',
    width: 500,
  },
  {
    Header: 'Region',
    accessor: 'region',
    Cell: RegionTableCell,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    maxWidth: 150,
  },
  {
    Header: 'Service type',
    accessor: 'serviceType',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    maxWidth: 150,
  },
]
