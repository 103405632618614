import { notNullable } from 'utils/common'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getSubjectType } from './getSubjectType'
import { getSeniorities } from './getSeniorities'
import { getEmployeeTypes } from './getEmployeeTypes'
import { getSpecialisations } from './getSpecialisations'
import { getTeams } from './getTeams'
import { getEmployees } from './getEmployees'
import { getDepartments } from './getDepartments'

export const getPolicyInfo = (params: DetailParams): DetailRow[] => {
  if (params.entity.type !== 'AccessPolicy') {
    return []
  }

  // subject is immutable, so every subject helper returns only actual value
  return [
    getSubjectType(params),
    getSeniorities(params),
    getEmployeeTypes(params),
    getSpecialisations(params),
    getTeams(params),
    getEmployees(params),
    getDepartments(params),
  ].filter(notNullable)
}
