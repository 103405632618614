import { useForm, Controller, useFormState } from 'react-hook-form'
import { Button, Popup, VStack } from '@revolut/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { CreateUserPayload, UserListItem } from 'api/idave/user'
import { UserSelect } from 'components/Selects/UserSelect'
import { OrgSelect } from 'components/Selects/OrgSelect'
import { OrganisationData } from 'api/idave/organisations'
import { useSetValue } from 'hooks/useSetValue'
import { Input } from 'components/Inputs'
import { usernameRegExpCheck } from '../utils'

const initialUserValue: CreateUserPayload = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  organisation: undefined,
  clone: undefined,
}

const isFilled = ({ firstName, lastName, email, username }: CreateUserPayload) =>
  !!firstName && !!lastName && !!email && !!username

export const NewUserForm = (props: { submit: (user: CreateUserPayload) => void }) => {
  const { submit } = props

  const [user, setUser] = useState<CreateUserPayload>(initialUserValue)
  const [isSubmitable, setIsSubmittable] = useState(false)

  const {
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm<CreateUserPayload>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: initialUserValue,
  })

  const handleCloneChange = useSetValue(
    setValue,
    'clone',
    (value?: UserListItem | null) => value?.id,
  )

  const handleOrgChange = useSetValue(
    setValue,
    'organisation',
    (value?: OrganisationData | null) => value?.id,
  )

  const { isValid } = useFormState({ control })

  useEffect(() => {
    const subscription = watch((state) => {
      setUser(state as CreateUserPayload)
    })
    return subscription.unsubscribe
  }, [setUser, watch])

  useEffect(
    () => setIsSubmittable(isValid && isFilled(user)),
    [isValid, user, setIsSubmittable],
  )

  const create = useCallback(() => {
    submit(user)
  }, [user, submit])

  return (
    <>
      <VStack space="s-12">
        <Controller
          name="firstName"
          control={control}
          rules={{ required: 'Required' }}
          render={({ field: { ref: _ref, ...rest } }) => (
            <Input
              maxLength={40}
              {...rest}
              label="First Name"
              invalid={!!errors.firstName?.message}
              errorMessage={errors.firstName?.message}
              autoFocus
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: 'Required' }}
          render={({ field: { ref: _ref, ...rest } }) => (
            <Input
              maxLength={100}
              {...rest}
              label="Last Name"
              invalid={!!errors.lastName?.message}
              errorMessage={errors.lastName?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: 'Required' }}
          render={({ field: { ref: _ref, ...rest } }) => (
            <Input
              maxLength={255}
              {...rest}
              label="Email"
              invalid={!!errors.email?.message}
              errorMessage={errors.email?.message}
            />
          )}
        />
        <Controller
          name="username"
          control={control}
          rules={{
            required: 'Required',
            pattern: {
              value: usernameRegExpCheck,
              message: "Username shouldn't contain an email",
            },
          }}
          render={({ field: { ref: _ref, ...rest } }) => (
            <Input
              maxLength={255}
              {...rest}
              label="User Name"
              invalid={!!errors.username?.message}
              errorMessage={errors.username?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="organisation"
          render={({ field }) => (
            <OrgSelect
              label="Organisation (Optional)"
              onChange={handleOrgChange}
              itemId={field.value}
              clearable
            />
          )}
        />

        <Controller
          control={control}
          name="clone"
          render={({ field }) => {
            return (
              <UserSelect
                itemId={field.value}
                label="Clone user (Optional)"
                clearable
                onChange={handleCloneChange}
              />
            )
          }}
        />
      </VStack>
      <Popup.Actions>
        <Button elevated onClick={create} disabled={!isSubmitable}>
          Create
        </Button>
      </Popup.Actions>
    </>
  )
}
