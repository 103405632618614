import { TableColumn, Text, Table, HStack, Flex, Token } from '@revolut/ui-kit'
import { SAMLog } from 'api/sam/auditLogs'
import { EntitiesTable } from 'components/EntitiesTable'
import { useCallback, useMemo, useState } from 'react'
import { useSideBox } from 'view/SideBox/SideBox'
import { Key } from '@revolut/icons'
import { compareDesc } from 'date-fns'
import { QueryStatus } from '@tanstack/react-query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import {
  CalendarFilter,
  DateFilterValue,
  filterItemsByDate,
} from 'components/CalendarFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { SideAvatar } from 'view/SideBox/SideAvatar'
import { formatDateTimePrecise } from 'utils/date'
import { SamPolicyLogSide } from './SamPolicyLogSide'
import { stringifyLogType, sortLogsByDate } from './utils'

const columns: TableColumn<SAMLog>[] = [
  {
    Header: 'Event',
    accessor: (log) => stringifyLogType(log.action),
    id: 'action',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: ({ value }: { value: string }) => (
      <Table.Cell>
        <HStack space="s-24">
          <Flex
            width="24px"
            height="24px"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            bg={Token.color.actionBackground}
            flex="24px 0 0"
          >
            <Key size={16} color={Token.color.blue} />
          </Flex>
          <Text display="inline-block">{value}</Text>
        </HStack>
      </Table.Cell>
    ),
  },
  {
    Header: 'Date',
    id: 'created_at',
    accessor: (log) => formatDateTimePrecise(new Date(log.created_at)),
    sortType: (rowA, rowB) =>
      compareDesc(new Date(rowA.original.created_at), new Date(rowB.original.created_at)),
  },
]

export const SamPolicyLogsTable = ({
  items,
  loadingStatus,
  policyId,
}: {
  policyId: string
  loadingStatus: QueryStatus
  items: SAMLog[]
}) => {
  const { data: policy } = useQuerySamPolicy(policyId)

  const policyName = policy?.policyName || policyId
  const sorted = useMemo(() => sortLogsByDate(items), [items])
  const [dateFilter, setDateFilter] = useState<DateFilterValue>(null)
  const filtered = useMemo(
    () => filterItemsByDate(sorted, ({ created_at }) => new Date(created_at), dateFilter),
    [sorted, dateFilter],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: filtered,
  })
  const { openSide } = useSideBox()
  const onLogClick = useCallback(
    (row: SAMLog) => {
      openSide({
        title: stringifyLogType(row.action),
        subtitle: policyName,
        description: formatDateTimePrecise(new Date(row.created_at)),
        avatar: <SideAvatar iconName="Key" />,
        body: <SamPolicyLogSide log={row} />,
      })
    },
    [openSide, policyName],
  )

  return (
    <EntitiesTable
      totalCount={items.length}
      entitiesTypeLabel="Logs"
      pluralForms={['log', 'logs']}
      data={searched}
      columns={columns}
      loadingState={getLoadingState(loadingStatus, sorted.length)}
      onRowClick={onLogClick}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      renderFiltersLeft={() => (
        <CalendarFilter value={dateFilter} onChange={setDateFilter} exceptFuture />
      )}
    />
  )
}
