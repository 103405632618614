import { EmployeeType, SamPolicySubjectType } from 'api/sam/policies'
import {
  RevolutersDepartment,
  RevolutersSpecialisation,
  RevolutersTeam,
} from 'api/sam/revoluters'
import { SENIORITY } from 'constants/seniority'
import { first, uniq } from 'lodash'
import { notNullableMap } from 'utils/array'
import { capitalizeFirst } from 'utils/string'
import { isTruthy } from 'utils/common/isTruthy'
import {
  PolicyDetailsSubject,
  SamPolicySubjectField,
} from 'view/Sam/components/SamEditPolicyDetails/types'

const NAME_PART_SEPARATOR = ' - '
const MULTIPLE_FIELD_NAME_SEPARATOR = '/'
const ALL_VALUES_LABEL = 'All'

type DataMaps = {
  teamMap: Map<string, RevolutersTeam>
  specMap: Map<string, RevolutersSpecialisation>
  departmentMap: Map<string, RevolutersDepartment>
}
type Part = {
  type: SamPolicySubjectField
  value: string[]
}

const stringifyMultipleBuffer = (types: Part['type'][]) => {
  if (!types.length) {
    return ''
  }

  return `Multiple(${notNullableMap(types, (type) => {
    switch (type) {
      case 'departmentIds':
        return 'Departments'
      case 'employeeTypes':
        return 'Employee types'
      case 'seniorityIds':
        return 'Seniorities'
      case 'specialisationIds':
        return 'Specialisations'
      case 'teamIds':
        return 'Teams'
      default:
        return undefined
    }
  }).join(MULTIPLE_FIELD_NAME_SEPARATOR)})`
}

const stringifyPart = (partType: Part['type'], partValue: string, dataMaps: DataMaps) => {
  switch (partType) {
    case 'employeeTypes':
      return capitalizeFirst(partValue)
    case 'departmentIds':
      return dataMaps.departmentMap.get(partValue)?.name || partValue
    case 'specialisationIds':
      return dataMaps.specMap.get(partValue)?.name || partValue
    case 'teamIds':
      return dataMaps.teamMap.get(partValue)?.name || partValue
    case 'seniorityIds':
      return SENIORITY[partValue] || partValue
    case 'employeeIds':
    default:
      return undefined
  }
}

/**
 * returns policy name suggestion like
 * EmployeeType - Main - Spec - Seniority
 *
 */
export const getNameSuggest = (params: {
  subject: PolicyDetailsSubject
  teamMap?: Map<string, RevolutersTeam>
  specMap?: Map<string, RevolutersSpecialisation>
  departmentMap?: Map<string, RevolutersDepartment>
}) => {
  const { subject, teamMap, specMap, departmentMap } = params
  // Lack of data to suggest
  if (!teamMap || !specMap || !departmentMap) {
    return undefined
  }

  // Lack of configuration to suggest
  if (subject.subjectType === SamPolicySubjectType.Employee || !subject.subjectType) {
    return undefined
  }

  // small hack to process internal+external or [] employee type as single 'All' value
  const uniqEmployeeTypes = uniq(subject.employeeTypes || [])
  const isAll =
    uniqEmployeeTypes.length === Object.keys(EmployeeType).length ||
    uniqEmployeeTypes.length === 0
  const combinedEmployeeTypes = isAll ? [ALL_VALUES_LABEL] : subject.employeeTypes

  const employeeTypePart: Part[] = [
    { type: 'employeeTypes', value: combinedEmployeeTypes },
  ]
  const dataMaps = { teamMap, specMap, departmentMap }

  switch (subject.subjectType) {
    case SamPolicySubjectType.EmployeeType: {
      return concatPolicyNameSuggestion({
        main: employeeTypePart,
        dataMaps,
      })
    }

    case SamPolicySubjectType.Specialisation: {
      return concatPolicyNameSuggestion({
        prefix: employeeTypePart,
        main: [{ type: 'specialisationIds', value: subject.specialisationIds }],
        suffix: [{ type: 'seniorityIds', value: subject.seniorityIds }],
        dataMaps,
      })
    }

    case SamPolicySubjectType.Team: {
      return concatPolicyNameSuggestion({
        prefix: employeeTypePart,
        main: [{ type: 'teamIds', value: subject.teamIds }],
        suffix: [
          { type: 'specialisationIds', value: subject.specialisationIds },
          { type: 'seniorityIds', value: subject.seniorityIds },
        ],
        dataMaps,
      })
    }

    case SamPolicySubjectType.Department:
      return concatPolicyNameSuggestion({
        prefix: employeeTypePart,
        main: [{ type: 'departmentIds', value: subject.departmentIds }],
        suffix: [
          { type: 'specialisationIds', value: subject.specialisationIds },
          { type: 'seniorityIds', value: subject.seniorityIds },
        ],
        dataMaps,
      })

    default:
      return undefined
  }
}

type ConcatPolicyNameSuggestionParams = {
  main?: Part[]
  prefix?: Part[]
  suffix?: Part[]
  dataMaps: DataMaps
}
const concatPolicyNameSuggestion = ({
  main,
  prefix,
  suffix,
  dataMaps,
}: ConcatPolicyNameSuggestionParams) => {
  // returns nothing if main part hasn't value
  if (!first(main)?.value?.length) {
    return undefined
  }

  const prefixValue = stringifyParts({ parts: prefix, dataMaps })
  const mainValue = stringifyParts({ parts: main, dataMaps })
  const suffixValue = stringifyParts({ parts: suffix, dataMaps })

  return notNullableMap([prefixValue, mainValue, suffixValue], (value) => {
    if (!value) {
      return undefined
    }

    return value
  }).join(NAME_PART_SEPARATOR)
}

export const stringifyParts = (params: { parts?: Part[]; dataMaps: DataMaps }) => {
  const { parts, dataMaps } = params

  if (!parts?.length) {
    return undefined
  }

  const reducedParts = parts.reduce<{
    value: Array<string | undefined>
    multiplePartsBuffer: Array<SamPolicySubjectField>
  }>(
    (acc, currentPart, idx) => {
      // field with multiple values - extend buffer or prune buffer to value (if last chank)
      if (currentPart.value.length > 1) {
        const multipleParts = [...acc.multiplePartsBuffer, currentPart.type]
        const isLastChank = idx + 1 === parts.length

        if (isLastChank) {
          return {
            value: [...acc.value, stringifyMultipleBuffer(multipleParts)],
            multiplePartsBuffer: [],
          }
        }
        return {
          value: acc.value,
          multiplePartsBuffer: multipleParts,
        }
      }

      // field without a value - just prune multiple parts buffer
      if (!currentPart.value?.length) {
        return {
          value: [
            ...acc.value,
            stringifyMultipleBuffer(acc.multiplePartsBuffer),
            ALL_VALUES_LABEL,
          ],
          multiplePartsBuffer: [],
        }
      }

      // single value field - prune buffer and add value
      return {
        value: [
          ...acc.value,
          stringifyMultipleBuffer(acc.multiplePartsBuffer),
          stringifyPart(currentPart.type, currentPart.value[0], dataMaps),
        ],
        multiplePartsBuffer: [],
      }
    },
    {
      value: [],
      multiplePartsBuffer: [],
    },
  )

  return reducedParts.value.filter(isTruthy).join(NAME_PART_SEPARATOR)
}
