import { Avatar, Flex, Text, TextProps, abbreviate } from '@revolut/ui-kit'
import { UserStates } from 'api/idave/user'
import { useUserStateTooltip } from 'hooks/useUserStateTooltip'
import { HTMLAttributeAnchorTarget } from 'react'
import { InternalLink } from '../InternalLink'
import { ExternalLink } from '../ExternalLink'

export type UserLinkViewProps = {
  id: string
  name: string
  avatarUrl?: string
  state?: UserStates
  target?: HTMLAttributeAnchorTarget
  displayId?: string
  isExternal?: boolean
  href: string
  isReversed?: boolean
  textProps?: TextProps
}

export const UserLinkView = ({
  id,
  name,
  state,
  avatarUrl,
  target,
  displayId,
  isExternal,
  href,
  isReversed,
  textProps,
}: UserLinkViewProps) => {
  const { anchorProps, avatarProps, titleProps, tooltip } = useUserStateTooltip(state)
  const LinkComponent = isExternal ? ExternalLink : InternalLink

  return (
    <LinkComponent href={href} target={target}>
      <Flex
        {...anchorProps}
        gap="s-8"
        alignItems="center"
        flexWrap="nowrap"
        flexDirection={isReversed ? 'row-reverse' : undefined}
      >
        <Avatar size={24} image={avatarUrl} uuid={id} {...avatarProps}>
          {abbreviate(name)}
        </Avatar>
        <Text whiteSpace="nowrap" {...titleProps} {...textProps}>
          {name}
          {displayId ? `(${displayId})` : null}
        </Text>
      </Flex>
      {tooltip}
    </LinkComponent>
  )
}
