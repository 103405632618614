import { StatusWidget } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { get3DImageSrcSetProps } from 'utils/url'

const DEFAULT_STATUS =
  "Please, clean cookies and local storage and try to reload the page. If it doesn't help, please, contact the iDave team."

export const ErrorStatus = (props: { errorDescription?: string }) => {
  const { errorDescription = DEFAULT_STATUS } = props
  const handler = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D018', 'v2')} />
      <StatusWidget.Title>Uh-oh! Something went wrong</StatusWidget.Title>
      <StatusWidget.Description maxWidth="335px">
        {errorDescription}
      </StatusWidget.Description>
      <StatusWidget.Action useIcon="16/ArrowRecurring" onClick={handler}>
        Reload
      </StatusWidget.Action>
    </StatusWidget>
  )
}
