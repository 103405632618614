import { QuerySwitch } from 'components/QuerySwitch'
import { MentionToken, Token } from 'hooks/useTokenize/types'
import { getMentionsTokens } from 'hooks/useTokenize/utils'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUserEmailMap } from 'queries/idave/users'
import { Mentions } from './Mentions'
import { MentionsSkeletons } from './MentionSkeletons'

export const CrossCheckEventMentions = (props: { tokens: Token[] }) => {
  const mentionTokens = getMentionsTokens(props.tokens)

  if (!mentionTokens.length) {
    return null
  }

  return <Inner mentions={mentionTokens} />
}

const Inner = (props: { mentions: MentionToken[] }) => {
  const { mentions } = props
  const {
    data: emailUserMap,
    fetchStatus: usersFS,
    status: usersQS,
  } = useQueryUserEmailMap()
  const {
    data: avatars,
    fetchStatus: avatarsFS,
    status: avatarsQS,
  } = useQueryMappedEmployeeIdAvatars()

  return (
    <QuerySwitch
      data={emailUserMap}
      optional={[{ qs: avatarsQS, fs: avatarsFS }]}
      required={[{ qs: usersQS, fs: usersFS }]}
      renderLoading={() => <MentionsSkeletons />}
      renderIdle={() => <Mentions mentions={mentions} avatars={avatars} />}
      renderError={() => <Mentions mentions={mentions} avatars={avatars} />}
      renderSuccess={({ data }) => (
        <Mentions mentions={mentions} emailUserMap={data} avatars={avatars} />
      )}
    />
  )
}
