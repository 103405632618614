import { VStack } from '@revolut/ui-kit'
import { FieldGroupSelect } from 'components/fields/FieldGroupSelect'
import { FieldInput } from 'components/fields/FieldInput'
import { FieldTextArea } from 'components/fields/FieldTextArea'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { RULE_REQUIRED } from 'utils/form/rules'
import { FieldCheckbox } from 'components/fields/FieldCheckbox'
import { generateScope } from './utils'
import { GROUP_TYPES, SCOPE_RULE } from './constants'
import { CommonClientEditFormValues } from './types'

export const CommonClientEdit = ({
  isLoading,
  isCreate,
  isOuterSource,
}: {
  isLoading: boolean
  isOuterSource: boolean
  isCreate?: boolean
}) => {
  const { setValue, getValues } = useFormContext<CommonClientEditFormValues>()

  const onNameBlur = useCallback(() => {
    const { name, scope } = getValues()
    if (name && !scope) {
      setValue('scope', generateScope(name))
    }
  }, [getValues, setValue])

  return (
    <VStack space="s-24">
      <FieldInput
        maxLength={255}
        name="name"
        label="Name"
        disabled={isLoading || isOuterSource}
        autoFocus
        onBlur={isCreate ? onNameBlur : undefined}
        rules={RULE_REQUIRED}
      />
      {isCreate && (
        <FieldInput
          maxLength={40}
          name="scope"
          label="Scope"
          disabled={isLoading || isOuterSource}
          rules={SCOPE_RULE}
        />
      )}
      <FieldGroupSelect
        name="ownerGroupId"
        label="Owner group"
        disabled={isLoading || isOuterSource}
        useGroupTypes={GROUP_TYPES}
        clearable
        rules={RULE_REQUIRED}
      />
      <FieldTextArea
        maxLength={300}
        name="description"
        label="Description (Optional)"
        disabled={isLoading || isOuterSource}
      />
      {!isCreate && (
        <FieldCheckbox name="enableSAAuthentication" label="Enable SA auth" />
      )}
    </VStack>
  )
}
