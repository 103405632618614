import { TableColumn, Table } from '@revolut/ui-kit'
import { SamResource } from 'api/sam/resources'

import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'

export const COLUMNS: TableColumn<SamResource>[] = [
  {
    Header: 'Name',
    accessor: 'resourceName',
    Cell: (params: { row: { original: SamResource } }) => (
      <Table.Cell>{params.row.original.resourceName}</Table.Cell>
    ),
  },
  {
    Header: 'Type',
    accessor: (row) => getResourceTypeLabel(row.resourceType),
  },
]
