import { Table, TableColumn, Text, TextSkeleton } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { capitalizeFirst } from 'utils/string'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { Row } from 'view/Dart/CustomerCompany/components/AllowedUsers/AllowedUserList/types'
import {
  CrossCheckValueTab,
  DiffItemStatus,
} from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/types'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { accessDurationAccessor, AccessDurationCell } from 'components/AccessDurationCell'
import { isComparable } from '../../../../../ChangeSwitcher/utils'

export type EmployeeRow = Row & { status?: DiffItemStatus }

export const useColumns = ({
  status,
  crossCheck,
  isTeamsLoaded,
}: {
  status: CrossCheckValueTab
  crossCheck: CrossCheckDetail
  isTeamsLoaded: boolean
}): TableColumn<EmployeeRow>[] => {
  const getUserName = useUserTableCellAccessor()

  return useMemo(() => {
    const baseColumns: TableColumn<EmployeeRow>[] = [
      {
        Header: 'Name',
        accessor: ({ id }) => getUserName(id),
        Cell: (cell: { row: { original: EmployeeRow } }) => (
          <UserTableCell userId={cell.row.original.id} />
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Team',
        accessor: 'team',
        Cell: (cell: { row: { original: EmployeeRow } }) => (
          <Table.Cell>
            {isTeamsLoaded ? (
              <Text>{cell.row.original.team}</Text>
            ) : (
              <TextSkeleton size={12} />
            )}
          </Table.Cell>
        ),
        filter: isTeamsLoaded ? 'includesValue' : undefined,
        Filter: isTeamsLoaded ? SelectItemFilter : undefined,
      },
      {
        Header: 'Access duration',
        accessor: accessDurationAccessor,
        Cell: AccessDurationCell,
      },
    ]

    return status === 'requested' && !isFinished(crossCheck) && isComparable(crossCheck)
      ? [...baseColumns, ...STATE_COLUMNS]
      : baseColumns
  }, [getUserName, status, crossCheck, isTeamsLoaded])
}

const STATE_COLUMNS: TableColumn<EmployeeRow>[] = [
  {
    Header: 'Change',
    accessor: ({ status }: EmployeeRow) =>
      status ? capitalizeFirst(status) : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    maxWidth: 50,
  },
]
