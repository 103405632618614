import { getDepartmentIds } from 'view/Sam/utils'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getNormalizedValues } from '../utils'
import { getPolicySubjectChangeType } from './utils'

export const getDepartments = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  const requested =
    crossCheck.executableName === 'CreatePolicy'
      ? crossCheck.executableParams.subject_params?.subject_department_ids
      : undefined

  const current =
    entity.type === 'AccessPolicy' && entity.value
      ? getDepartmentIds(entity.value.subject)
      : undefined

  const value = requested || current

  return {
    title: 'Departments',
    value: {
      ...getNormalizedValues(value, value),
      type: 'Departments',
    },
    changeType: getPolicySubjectChangeType(crossCheck),
    isContext: true,
  }
}
