import { Page, TextSkeleton, VStack } from '@revolut/ui-kit'
import { CrossCheckDetail, CrossCheckSystem } from 'api/types/crossCheck'
import { QuerySwitch } from 'components/QuerySwitch'
import { useMemo, useRef } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { StatusText } from 'components/StatusText'
import { useHeaderActions } from 'components/HeaderActions'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { NoAccessPage } from 'components/NoAccessWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import { useCrosscheckQuery } from './useCrosscheckQuery'
import { CrossCheckActions } from './components/CrossCheckActions'
import { getRequest, getRequestStateLevel, getRequestStateText } from '../utils'
import { CrossCheckDetails } from './components/CrosscheckDetails'
import { CrossCheckApprovers } from './components/CrossCheckApprovers'
import { CrossCheckEvents } from './components/CrossCheckEvents'
import { CrossCheckRules } from './components/CrossCheckRules'
import { CrossCheckJustification } from './components/CrossCheckJustification'
import { CrossCheckComment } from './components/CrossCheckComment'

type Props = {
  crossCheckId: string
  source: CrossCheckSystem
}

const getCrossCheckBaseUrl = (source: CrossCheckSystem) => {
  switch (source) {
    case 'dart':
      return Url.CrossCheckDartDetails
    case 'sam':
      return Url.CrossCheckSamDetails
    default:
    case 'idave':
      return Url.CrossCheckIdaveDetails
  }
}

export const CrossCheck = (props: Props) => {
  const { source, crossCheckId } = props
  const { query } = useCrosscheckQuery({ source, crossCheckId })
  const { data, status, fetchStatus, refetch } = query
  const requiredStatuses = useMemo(
    () => [{ qs: status, fs: fetchStatus }],
    [status, fetchStatus],
  )

  const onBackClick = useNavigateToList({
    listUrl: Url.CrossChecks,
    supportedLists: [
      Url.CrossChecksAll,
      Url.CrossChecksRequested,
      Url.CrossChecksToReview,
    ],
    entityUrl: generatePath(getCrossCheckBaseUrl(source), { crossCheckId }),
  })

  return (
    <QuerySwitch
      required={requiredStatuses}
      data={data}
      renderLoading={() => <Inner source={source} toCrosschecks={onBackClick} />}
      renderSuccess={() => (
        <Inner source={source} crossCheck={data} toCrosschecks={onBackClick} />
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={crossCheckId}
          title="Something went wrong"
          description="Requested crosscheck info fetch failed"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => (
        <NoAccessPage pageTitle={crossCheckId} onBackClick={onBackClick} />
      )}
    />
  )
}

const Inner = (props: {
  crossCheck?: CrossCheckDetail
  source: CrossCheckSystem
  toCrosschecks: () => void
}) => {
  const { crossCheck, source, toCrosschecks } = props

  const commentRef = useRef<HTMLTextAreaElement>(null)
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header
        onBack={toCrosschecks}
        subtitle={
          crossCheck ? (
            <StatusText level={getRequestStateLevel(crossCheck.state)}>
              {getRequestStateText(crossCheck)}
            </StatusText>
          ) : (
            <TextSkeleton size={6} />
          )
        }
        actions={HeaderActions && <HeaderActions />}
      >
        {crossCheck ? getRequest(crossCheck.executableName) : <TextSkeleton size={12} />}
      </Page.Header>
      <Page.Main>
        <VStack space="s-24" maxWidth={MAXIMAL_DETAILS_WIDTH}>
          <CrossCheckActions
            crossCheck={crossCheck}
            source={source}
            commentRef={commentRef}
          />
          <CrossCheckJustification crossCheck={crossCheck} source={source} />
          <CrossCheckDetails crossCheck={crossCheck} source={source} />
          <CrossCheckRules crossCheck={crossCheck} />
          <CrossCheckApprovers crossCheck={crossCheck} source={source} />
          <CrossCheckComment
            commentRef={commentRef}
            source={source}
            crossCheck={crossCheck}
          />
          <CrossCheckEvents crossCheck={crossCheck} source={source} />
        </VStack>
      </Page.Main>
    </>
  )
}
