import { TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { StateCell, stateCellAccessor } from 'components/StateCell'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { User } from './types'

export const useColumns = (params: { isDataSettled: boolean }): TableColumn<User>[] => {
  const getUserName = useUserTableCellAccessor()
  const { isDataSettled } = params
  return useMemo(() => {
    const loadingFilter = isDataSettled
      ? {
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        }
      : undefined

    const loadingCell = !isDataSettled
      ? {
          Cell: () => <TextSkeleton size={16} />,
        }
      : undefined

    return [
      {
        Header: 'Name',
        id: 'name',
        accessor: ({ id }) => getUserName(id),
        Cell: (cell: { row: { original: User } }) => (
          <UserTableCell userId={cell.row.original.id} />
        ),
        width: 200,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 300,
      },
      {
        Header: 'Department',
        accessor: 'department',
        width: 200,
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Team',
        accessor: 'team',
        width: 300,
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Specialisation',
        accessor: 'spec',
        width: 300,
        ...loadingCell,
        ...loadingFilter,
      },
      {
        Header: 'Status',
        accessor: stateCellAccessor,
        id: 'state',
        filter: 'includesValue',
        Filter: SelectItemFilter,
        width: 140,
        Cell: StateCell,
      },
    ]
  }, [getUserName, isDataSettled])
}
