import { TableColumn } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { capitalizeFirst } from 'utils/string'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { CrossCheckDetail } from 'api/types/crossCheck'
import {
  CrossCheckValueTab,
  DiffItemStatus,
} from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/types'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { RegionTableCell } from 'components/RegionTableCell'
import { accessDurationAccessor, AccessDurationCell } from 'components/AccessDurationCell'
import { isComparable } from '../../../../../ChangeSwitcher/utils'

export type CustomerCompanyRow = {
  id: string
  name: string
  region?: string
  serviceType?: string
  status?: DiffItemStatus
  expirationDate?: number
}

export const getColumns = (
  status: CrossCheckValueTab,
  crossCheck: CrossCheckDetail,
): TableColumn<CustomerCompanyRow>[] =>
  status === 'requested' && !isFinished(crossCheck) && isComparable(crossCheck)
    ? [...BASE_COLUMNS, ...STATE_COLUMNS]
    : BASE_COLUMNS

const BASE_COLUMNS: TableColumn<CustomerCompanyRow>[] = [
  {
    Header: 'Company name',
    accessor: 'name',
  },
  {
    Header: 'Region',
    accessor: 'region',
    Cell: RegionTableCell,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    maxWidth: 50,
  },
  {
    Header: 'Service type',
    accessor: 'serviceType',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    maxWidth: 50,
  },
  {
    Header: 'Access duration',
    accessor: accessDurationAccessor,
    Cell: AccessDurationCell,
  },
]

const STATE_COLUMNS: TableColumn<CustomerCompanyRow>[] = [
  {
    Header: 'Change',
    accessor: ({ status }: CustomerCompanyRow) =>
      status ? capitalizeFirst(status) : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    maxWidth: 50,
  },
]
