import { TableColumn } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { Permission } from 'api/idave/permissions'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const getColumns = (clientMap: Map<string, Client>): TableColumn<Permission>[] => [
  {
    Header: 'Permission',
    accessor: 'name',
  },
  {
    Header: 'Application',
    accessor: (value) =>
      value.client
        ? clientMap.get(value.client)?.name || value.client
        : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
]
