import { chain, Table, Text, Token } from '@revolut/ui-kit'
import { formatDate } from 'utils/date'

type AccessDuration = { expirationDate?: number }

type AccessDurationCellProps = {
  row: { original: AccessDuration }
}

export const accessDurationAccessor = ({ expirationDate }: AccessDuration) =>
  expirationDate ? 'Temporary' : 'Permanent'

export const AccessDurationCell = (props: AccessDurationCellProps) => {
  const { expirationDate } = props.row.original

  return (
    <Table.Cell>
      <Text color={Token.color.greyTone50}>
        {chain(
          <Text color={Token.color.foreground}>
            {expirationDate ? 'Temporary' : 'Permanent'}
          </Text>,
          expirationDate ? <Text>Expires {formatDate(expirationDate)}</Text> : undefined,
        )}
      </Text>
    </Table.Cell>
  )
}
