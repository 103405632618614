import { MoreBar } from '@revolut/ui-kit'

export const Actions = ({
  selectedCount,
  onAddClick,
  onRemoveClick,
}: {
  selectedCount: number
  onRemoveClick: () => void
  onAddClick: () => void
}) =>
  selectedCount ? (
    <MoreBar.Action variant="negative" onClick={onRemoveClick} useIcon="Delete">
      Remove
    </MoreBar.Action>
  ) : (
    <MoreBar.Action useIcon="Plus" onClick={onAddClick}>
      Add permissions
    </MoreBar.Action>
  )
