import { Page } from '@revolut/ui-kit'
import { useHeaderActions } from 'components/HeaderActions'
import { AuditLogsList } from './AuditLogsList'

export const AuditLogs = () => {
  const HeaderActions = useHeaderActions()
  return (
    <>
      <Page.Header actions={HeaderActions && <HeaderActions />}>Audit Logs</Page.Header>
      <Page.Main>
        <AuditLogsList />
      </Page.Main>
    </>
  )
}
