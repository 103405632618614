import { Token, Table, TableColumn, Text, TextSkeleton, chain } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { formatDate } from 'utils/date'
import { StateCell, stateCellAccessor } from 'components/StateCell'
import { UserTableCell, useUserTableCellAccessor } from 'components/UserTableCell'
import { useMemo } from 'react'
import { Row } from './types'

export const useColumns = (params: { isTeamsLoaded: boolean }) => {
  const { isTeamsLoaded } = params
  const getUserName = useUserTableCellAccessor()

  return useMemo<TableColumn<Row>[]>(
    () => [
      {
        Header: 'Name',
        accessor: (row: Row) => getUserName(row.id),
        Cell: (cell: { row: { original: Row } }) => (
          <UserTableCell userId={cell.row.original.id} />
        ),
        width: 200,
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Team',
        accessor: 'team',
        Cell: (cell: { row: { original: Row } }) => (
          <Table.Cell>
            {isTeamsLoaded ? (
              <Text>{cell.row.original.team}</Text>
            ) : (
              <TextSkeleton size={12} />
            )}
          </Table.Cell>
        ),
        filter: isTeamsLoaded ? 'includesValue' : undefined,
        Filter: isTeamsLoaded ? SelectItemFilter : undefined,
        width: 200,
      },
      {
        Header: 'User status',
        accessor: stateCellAccessor,
        filter: 'includesValue',
        Filter: SelectItemFilter,
        Cell: StateCell,
        maxWidth: 140,
      },
      {
        Header: 'Access duration',
        accessor: ({ expirationDate }) => (expirationDate ? 'Temporary' : 'Permanent'),
        Cell: (cellParams: { row: { original: Row } }) => {
          const { expirationDate } = cellParams.row.original

          return (
            <Table.Cell>
              <Text color={Token.color.greyTone20}>
                {chain(
                  <Text color={Token.color.foreground}>
                    {expirationDate ? 'Temporary' : 'Permanent'}
                  </Text>,

                  expirationDate ? (
                    <Text>{`Expires ${formatDate(expirationDate)}`}</Text>
                  ) : undefined,
                )}
              </Text>
            </Table.Cell>
          )
        },
        width: 200,
      },
    ],
    [isTeamsLoaded, getUserName],
  )
}
