import { abbreviate, Avatar, HStack, Table, TableColumn, Text } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getEmployeeAvatarUrl } from 'utils/url/avatarUrl'
import { useUserStateTooltip } from 'hooks/useUserStateTooltip'
import { Employee } from './types'

export const useColumns = (
  avatars?: Map<string, RevolutersAvatar>,
): TableColumn<Employee>[] => {
  return [
    {
      Header: 'User',
      id: 'user',
      accessor: 'user',
      Cell: (params: { row: { original: Employee } }) => {
        const { user, id, status } = params.row.original
        const avatar = getEmployeeAvatarUrl({
          avatars,
          employee: params.row.original,
        })

        const { anchorProps, avatarProps, titleProps, tooltip } =
          useUserStateTooltip(status)

        return (
          <Table.Cell>
            <HStack space="s-8" {...anchorProps}>
              <Avatar
                size={24}
                uuid={id}
                label={abbreviate(user)}
                image={avatar}
                {...avatarProps}
              />
              <Text display="inline-block" whiteSpace="nowrap" {...titleProps}>
                {user}
              </Text>
            </HStack>
            {tooltip}
          </Table.Cell>
        )
      },
      width: 100,
    },
    {
      Header: 'Email',
      accessor: 'email',
      width: 150,
      filter: 'includesValue' as const,
      Filter: SelectItemFilter,
    },
    {
      Header: 'Department',
      accessor: 'department',
      width: 100,
      filter: 'includesValue' as const,
      Filter: SelectItemFilter,
    },
    {
      Header: 'Team',
      accessor: 'team',
      width: 150,
      filter: 'includesValue' as const,
      Filter: SelectItemFilter,
    },
  ]
}
