import { CrossCheckDetail, REMOVED_MARK } from 'api/types/crossCheck'
import { ChangeType, DetailRow, RowEntityType } from '../../types'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

export const getForecasted = <T>(params: {
  current?: T
  requested?: T
  changeType: ChangeType
}) => {
  const { changeType, current, requested } = params
  if (
    changeType === 'Added' ||
    changeType === 'Edited' ||
    changeType === 'EntityCreation'
  ) {
    return requested
  }

  if (changeType === 'NoChange') {
    return current
  }

  return undefined
}

export const getNormalizedValues = <T>(current?: T[], requested?: T[]) => ({
  current: (current || []).sort(),
  requested: (requested || []).sort(),
  forecasted: (requested || current || []).sort(),
})

export const getChangeType = (params: {
  requested: DetailRow['value']['requested']
  current: DetailRow['value']['current']
  crossCheck: CrossCheckDetail
}): ChangeType => {
  const { requested, crossCheck, current } = params
  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return 'EntityCreation'
  }

  // requested is empty str or specific removed value
  if (
    (!requested && typeof requested !== 'undefined' && current) ||
    requested === REMOVED_MARK
  ) {
    return 'Deleted'
  }

  if (requested === current || !requested) {
    return 'NoChange'
  }

  if (requested && !current) {
    return 'Added'
  }

  return 'Edited'
}

type GetDetailRowParams = {
  requested?: string
  current?: string
  title: string
  crossCheck: CrossCheckDetail
  type: RowEntityType | 'text' | 'boolean'
  isContext?: boolean
}

export const getDetailRow = ({
  current,
  requested,
  title,
  crossCheck,
  type,
  isContext,
}: GetDetailRowParams): DetailRow => {
  const changeType = getChangeType({ requested, current, crossCheck })

  return {
    title,
    value: {
      current,
      requested,
      forecasted: getForecasted({ current, changeType, requested }),
      type,
    },
    changeType,
    isContext,
  }
}
