import { TabBar, Page } from '@revolut/ui-kit'
import { SAM_PERMISSIONS } from 'security'
import { Url } from 'routing'
import { TabBarLink } from 'components/TabBarLink'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useHeaderActions } from 'components/HeaderActions'
import { SamPolicies } from './SamPolicies'
import { SamResources } from './SamResources'
import { SamTasks } from './SamTasks'

type SamProps = {
  tab: 'policies' | 'resources' | 'tasks'
}

export const Sam = ({ tab }: SamProps) => {
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header actions={HeaderActions && <HeaderActions />}>
        Security Access Management
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <TabBarLink to={Url.SamPolicies}>Policies</TabBarLink>
          <PermissionsCheck permission={SAM_PERMISSIONS.RESOURCES_VIEW_LIST}>
            <TabBarLink to={Url.SamResources}>Resources</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck permission={SAM_PERMISSIONS.TASKS_CREATE}>
            <TabBarLink to={Url.SamTasks}>Tasks</TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main>
        {tab === 'policies' && <SamPolicies />}

        {tab === 'resources' && <SamResources />}

        {tab === 'tasks' && <SamTasks />}
      </Page.Main>
    </>
  )
}
