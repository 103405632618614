import { Action, Box, Subheader, textChain } from '@revolut/ui-kit'
import { EntityList } from 'components/EntityList'
import { useCallback, useMemo, useState } from 'react'
import { EmployeeDetails } from 'api/dart/employee'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getItems, hasCompaniesToAdd } from './utils'
import { useAddCompanies, useDeleteCompany } from './hooks'

const DEFAULT_ITEMS_COUNT = 5
const DEFAULT_ITEMS_THRESHOLD = 8

export const UserDataAccessCompanies = ({
  employeeDetails,
  customerCompaniesMap,
}: {
  employeeDetails: EmployeeDetails | undefined
  customerCompaniesMap: Map<string, CustomerCompanyItem>
}) => {
  const [expanded, setExpanded] = useState(false)

  const onToggleExpanded = useCallback(() => setExpanded((v) => !v), [])

  const items = useMemo(
    () => getItems(employeeDetails, customerCompaniesMap),
    [customerCompaniesMap, employeeDetails],
  )

  const employeeId = employeeDetails?.id!

  const { hasSomePermissions } = usePermissions()
  const addEnabled =
    hasSomePermissions(
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_UPDATE_ACCESS_MODIFIER,
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK,
    ) && hasCompaniesToAdd(items, Array.from(customerCompaniesMap.values()))

  const deleteEnabled = hasSomePermissions(
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_DELETE_ACCESS_MODIFIER,
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_DELETE_ACCESS_MODIFIER_CROSS_CHECK,
  )

  const { onAddCompanies } = useAddCompanies(employeeId)
  const { onDeleteCompany } = useDeleteCompany(employeeId)

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{textChain('Customer companies', items.length)}</Subheader.Title>

        <Subheader.Side>
          {items.length > DEFAULT_ITEMS_THRESHOLD && (
            <Action onClick={onToggleExpanded}>
              {expanded ? 'Hide all' : 'Show all'}
            </Action>
          )}
        </Subheader.Side>
      </Subheader>

      <EntityList
        defaultItemsCount={DEFAULT_ITEMS_COUNT}
        defaultItemsThreshold={DEFAULT_ITEMS_THRESHOLD}
        expanded={expanded}
        onExpandClick={onToggleExpanded}
        items={items}
        showAvatars
        onAddClick={onAddCompanies}
        onDeleteClick={onDeleteCompany}
        addEnabled={addEnabled}
        deleteEnabled={deleteEnabled}
        addActionLabel="Add company"
      />
    </Box>
  )
}
