import { UserListItem } from 'api/idave/user'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useFilteredUsers } from 'queries/idave/users'
import { waitForAll } from 'utils/query'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useCallback, useEffect, useMemo } from 'react'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useSelectedFilter } from 'hooks/useSelectedFilter'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { IDAVE_PERMISSIONS } from 'security'
import { getColumns } from './columns'
import { getRows } from './utils'
import { RoleUsersActions } from './RoleUsersActions'

export const RoleUsers = (props: { roleId: string }) => {
  const { hasPermission } = usePermissions()

  const {
    data: users = [],
    status,
    fetchStatus,
  } = useFilteredUsers({ roleId: props.roleId })
  const { getEmployeeDescription, status: descriptionStatus } =
    useGetEmployeeRevoDescription()
  const isDataLoading = descriptionStatus === 'loading'

  const loadingState = getLoadingState(
    waitForAll([{ qs: status, fs: fetchStatus }]),
    users.length,
  )

  const rows = useMemo(
    () => getRows({ users, getEmployeeDescription }),
    [users, getEmployeeDescription],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: rows,
  })
  const getUserLink = useCallback(
    (user: UserListItem) => generatePath(Url.UserProfile, { userId: user.id }),
    [],
  )

  const hasActionsPermissions = hasPermission(IDAVE_PERMISSIONS.USERS_UNASSIGN_ROLES)

  const {
    selectEnabled,
    selectedHash,
    setSelectedHash,
    selected,
    showSelected,
    switchShowSelected,
    resetSelected,
  } = useSelectedFilter(hasActionsPermissions)

  useEffect(() => {
    if (selectEnabled) {
      resetSelected()
    }
  }, [resetSelected, selectEnabled, users])

  const columns = useMemo(() => getColumns(isDataLoading), [isDataLoading])

  return loadingState === 'no-results' ? (
    <EmptyStatusWidget
      title="No users with this role"
      imageCode="3D082"
      imageVersion="v2"
    />
  ) : (
    <EntitiesTable
      totalCount={users.length}
      entitiesTypeLabel="Users"
      pluralForms={['user', 'users']}
      data={searched}
      loadingState={loadingState}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      getRowLink={getUserLink}
      searchAutoFocus
      selectedHash={selectedHash}
      setSelectedHash={setSelectedHash}
      showSelected={showSelected}
      switchShowSelected={switchShowSelected}
      renderActions={
        hasActionsPermissions
          ? () => <RoleUsersActions roleId={props.roleId} userIds={selected} />
          : undefined
      }
    />
  )
}
