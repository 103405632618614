import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { Row } from './components/PermissionList/types'

export const COLUMNS: TableColumn<Row>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 300,
  },
  {
    Header: 'Application',
    id: 'clientName',
    accessor: ({ client, clientName }: Row) => clientName || client,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 200,
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 500,
  },
]
