import { MoreBar, Page } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryOrgs } from 'queries/idave/orgs'
import { IDAVE_PERMISSIONS } from 'security'
import { useCallback, useMemo } from 'react'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { useNavigate, generatePath } from 'react-router'
import { Url } from 'routing'
import { OrganisationData } from 'api/idave/organisations'
import { sortBy } from 'lodash'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useHeaderActions } from 'components/HeaderActions'
import { COLUMNS } from './columns'
import { OrganisationRow } from './type'

export const Organisations = () => {
  const { data: organisations = [], status, fetchStatus } = useQueryOrgs()
  const entities = useMemo(() => sortBy(organisations, 'name'), [organisations])
  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })

  const navigate = useNavigate()

  const state = getLoadingState(
    mergeQueryStatuses({ qs: status, fs: fetchStatus }),
    organisations.length,
  )

  const onRowClick = useCallback(
    (row: OrganisationRow) => {
      navigate(
        generatePath(Url.OrganisationDetails, {
          organisationId: row.id,
        }),
      )
    },
    [navigate],
  )

  const onOrgCreateClick = useCallback(() => {
    navigate(Url.OrganisationCreate)
  }, [navigate])

  const getRowLink = useCallback(
    (org: OrganisationData) => generatePath(Url.Organisation, { organisationId: org.id }),
    [],
  )
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Page.Header actions={HeaderActions && <HeaderActions />}>
        Organisations
      </Page.Header>

      <Page.Main>
        <EntitiesTable
          entitiesTypeLabel="Organisations"
          pluralForms={['organisation', 'organisations']}
          enableNavigation
          totalCount={entities.length}
          loadingState={state}
          data={searched}
          columns={COLUMNS}
          onRowClick={onRowClick}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchAutoFocus
          getRowLink={getRowLink}
          renderActions={() => (
            <PermissionsCheck permission={IDAVE_PERMISSIONS.ORGANISATIONS_CREATE}>
              <MoreBar.Action useIcon="Plus" onClick={onOrgCreateClick}>
                Create new
              </MoreBar.Action>
            </PermissionsCheck>
          )}
        />
      </Page.Main>
    </>
  )
}
