import { Button, Popup, Description, VStack, Subheader } from '@revolut/ui-kit'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useState } from 'react'
import { AssignmentTimer, getDaysHint } from 'components/AssignmentTimer'
import { AssignTimer } from 'components/AssignmentTimer/types'
import { DAY } from 'constants/time'
import { getOnlyTemporary } from './utils'

type UserRolesTimerProps = {
  confirm: (time?: number) => void
}

const DEFAULT_OFFSET = DAY * 30

export const UserRolesAddPopup = ({ confirm }: UserRolesTimerProps) => {
  const [timer, setTimer] = useState<AssignTimer | undefined>()
  const { closePopup } = useEditPopup()

  const onConfirm = useCallback(() => {
    confirm(timer?.value)
  }, [confirm, timer])

  const daysHint = getDaysHint(timer?.value)
  const dateNotSet = timer?.type === 'temporary' && !timer.value

  return (
    <>
      <Subheader variant="nested">
        <Subheader.Title>Role assignment duration</Subheader.Title>
      </Subheader>
      <VStack space="s-6">
        <AssignmentTimer
          onChange={setTimer}
          onlyTemporary={getOnlyTemporary()}
          defaultOffset={DEFAULT_OFFSET}
        />
        {daysHint && <Description pl="s-8">{daysHint}</Description>}
      </VStack>

      <Popup.Actions horizontal>
        <Button elevated variant="secondary" onClick={closePopup}>
          Cancel
        </Button>
        <Button elevated variant="primary" onClick={onConfirm} disabled={dateNotSet}>
          Confirm
        </Button>
      </Popup.Actions>
    </>
  )
}
