import { TableFilters, TableSortingRule } from '@revolut/ui-kit'
import { EntitiesTable } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import {
  useQueryMappedEmployeeIdAvatars,
  useQueryEmployeePeopleOpsMap,
} from 'queries/sam/users'
import { useQueryRecertificationPolicyMap } from 'queries/sam/recerts'
import { useQueryAccountMap } from 'queries/idave/accounts'
import {
  useQueryCustomerCompanyMap,
  useQueryEmployeeCompanyMap,
} from 'queries/dart/companies'
import { useQueryPolicyMap } from 'queries/sam/policies'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { QueryResult, isAllSettled } from 'utils/query'
import { useNavigate, generatePath } from 'react-router'
import { Url } from 'routing'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'
import { CrossCheck } from 'api/types/crossCheck'
import { isTruthy } from 'utils/common/isTruthy'
import { UserListItem } from 'api/idave/user'
import { randomKey } from 'utils/common/randomKey'
import { useNavigateTableState } from 'components/EntitiesTable/hooks/useNavigateTableState'
import { getCrossCheckRows, getEmptyTabParams } from '../../utils'
import { getColumns } from './columns'
import { CrossCheckRow, CrossChecksTab } from '../../types'
import { makeFilters } from './utils'
import { useReviewerFilterNavigation } from './components/ReviewerFilter/useReviewerFilterNavigation'
import { getOverdueActive } from './utils'
import { FILTER_SUPPORT, SORT_DATE_ASC } from './consts'
import { makeOverdueAction } from './components/OverdueAction'

type ListProps = {
  crossChecks: {
    idave: CrossCheck[]
    sam: CrossCheck[]
    dart: CrossCheck[]
  }
  queryResult: QueryResult
  tab: CrossChecksTab
  setUserFilter: (user: UserListItem | undefined) => void
  userFilter?: UserListItem
}

export const List = ({
  crossChecks,
  queryResult,
  tab,
  setUserFilter,
  userFilter,
}: ListProps) => {
  useReviewerFilterNavigation({
    setUserFilter,
    tab,
    userFilter,
  })
  const { idave, sam, dart } = crossChecks
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()
  const {
    data: roleMap = new Map(),
    status: rolesQS,
    fetchStatus: rolesFS,
  } = useQueryRoleIdMap()

  const {
    data: clientMap = new Map(),
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()

  const {
    data: policyMap = new Map(),
    status: policiesQS,
    fetchStatus: policiesFS,
  } = useQueryPolicyMap()

  const {
    data: recertToPolicy = {},
    status: recertToPolicyQS,
    fetchStatus: recertToPolicyFS,
  } = useQueryRecertificationPolicyMap()

  const {
    data: accountMap = new Map(),
    status: accountsQS,
    fetchStatus: accountsFS,
  } = useQueryAccountMap()

  const {
    data: userMap = new Map(),
    status: usersQS,
    fetchStatus: usersFS,
  } = useQueryUserIdMap()
  const navigate = useNavigate()

  const {
    data: employeeMap = new Map(),
    status: employeeQS,
    fetchStatus: employeeFS,
  } = useQueryEmployeePeopleOpsMap()
  const isEmployeeSettled = isAllSettled({ qs: employeeQS, fs: employeeFS })
  const isRecertToPolycySettled = isAllSettled({
    qs: recertToPolicyQS,
    fs: recertToPolicyFS,
  })

  const {
    data: customerCompanyMap = new Map(),
    status: customerCompaniesQS,
    fetchStatus: customerCompaniesFS,
  } = useQueryCustomerCompanyMap()

  const {
    data: employeeCompanyMap = new Map(),
    status: employeeCompaniesQS,
    fetchStatus: employeeCompaniesFS,
  } = useQueryEmployeeCompanyMap()

  const dataMaps = useMemo(
    () => ({
      roleMap,
      clientMap,
      policyMap,
      userMap,
      employeeMap,
      customerCompanyMap,
      employeeCompanyMap,
      recertToPolicy,
      accountMap,
    }),
    [
      roleMap,
      clientMap,
      policyMap,
      userMap,
      employeeMap,
      customerCompanyMap,
      employeeCompanyMap,
      recertToPolicy,
      accountMap,
    ],
  )

  const rows = useMemo(
    () =>
      getCrossCheckRows({
        idave,
        sam,
        dart,
        dataMaps,
        isEmployeeSettled,
        isRecertToPolycySettled,
      }),
    [idave, sam, dart, dataMaps, isEmployeeSettled, isRecertToPolycySettled],
  )
  const onClick = useCallback(
    (crossCheckRow: CrossCheckRow) => {
      const [url] = [
        crossCheckRow.source === 'sam' && Url.CrossCheckSamDetails,
        crossCheckRow.source === 'dart' && Url.CrossCheckDartDetails,
        crossCheckRow.source === 'idave' && Url.CrossCheckIdaveDetails,
      ].filter(isTruthy)

      return navigate(
        generatePath(url, {
          crossCheckId: crossCheckRow.crossCheck.id,
        }),
      )
    },
    [navigate],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities: rows })
  const getRowLink = useCallback(
    (row: CrossCheckRow) =>
      generatePath(Url.CrossCheck, {
        crossCheckId: row.crossCheck.id,
        source: row.source,
      }),
    [],
  )
  const isDataSettled = isAllSettled(
    { qs: rolesQS, fs: rolesFS },
    { qs: clientsQS, fs: clientsFS },
    { qs: policiesQS, fs: policiesFS },
    { qs: usersQS, fs: usersFS },
    { qs: customerCompaniesQS, fs: customerCompaniesFS },
    { qs: employeeCompaniesQS, fs: employeeCompaniesFS },
    { qs: accountsQS, fs: accountsFS },
  )
  const state = !isDataSettled ? 'pending' : getLoadingState(queryResult, rows.length)
  const columns = useMemo(() => getColumns(avatars), [avatars])

  const [filters, updateFilter] = useState<TableFilters<CrossCheckRow>>()
  const [sorts, updateSort] = useState<TableSortingRule<CrossCheckRow>[]>()
  const [key, updateKey] = useState<number>()
  const [isOverdueActive, setOverdueActive] = useState(getOverdueActive(filters, sorts))
  useEffect(() => {
    setOverdueActive(getOverdueActive(filters, sorts))
  }, [filters, sorts])

  const { navigateTableState, resetTableState } = useNavigateTableState()
  const onOverdueClick = useCallback(() => {
    if (isOverdueActive) {
      resetTableState()
    } else {
      navigateTableState({
        filters: FILTER_SUPPORT,
        sorts: SORT_DATE_ASC,
      })
    }
    updateKey(randomKey())
  }, [isOverdueActive, navigateTableState, resetTableState])

  if (queryResult === 'success' && !rows.length && tab !== 'all') {
    return <EmptyStatusWidget {...getEmptyTabParams(tab)} />
  }

  return (
    <EntitiesTable
      key={key}
      enableNavigation
      entitiesTypeLabel="Requests"
      pluralForms={['request', 'requests']}
      totalCount={rows.length}
      loadingState={state}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      getRowLink={getRowLink}
      onRowClick={onClick}
      renderFiltersRight={makeFilters(tab, setUserFilter, userFilter)}
      onFilter={updateFilter}
      onSortBy={updateSort}
      renderFiltersLeft={makeOverdueAction(tab, onOverdueClick, isOverdueActive)}
    />
  )
}
