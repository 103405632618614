import { Token, Table, TableColumn } from '@revolut/ui-kit'
import { CheckSuccess, CrossCircle } from '@revolut/icons'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { RegionTableCell } from 'components/RegionTableCell'
import { EmployeeCompany } from '../../types'

export const COLUMNS: TableColumn<EmployeeCompany>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    width: 700,
  },
  {
    Header: 'Region',
    accessor: 'region',
    Cell: RegionTableCell,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 100,
  },
  {
    Header: 'Access',
    accessor: (value) => (value.accessModifierId ? 'Allowed' : 'Not allowed'),
    Cell: (params: { row: { original: EmployeeCompany } }) => {
      const { accessModifierId } = params.row.original
      return (
        <Table.Cell>
          {accessModifierId ? (
            <CheckSuccess size={24} color={Token.color.success} />
          ) : (
            <CrossCircle size={24} color={Token.color.greyTone20} />
          )}
        </Table.Cell>
      )
    },
    width: 100,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
