import { PageVariant } from '@revolut/ui-kit'
import { captureException } from '@sentry/browser'
import { Component } from 'react'
import { ErrorPage } from 'routing'

type ErrorBoundaryProps = {
  children?: React.ReactNode
}
export class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true }
  }

  componentDidCatch(err: Error) {
    captureException(err)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage variant={PageVariant.FOCUSED} />
    }
    return this.props.children
  }
}
