import { Table, TableCellProps } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { UserStates } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { capitalizeFirst } from 'utils/string'

type State = UserStates | Client['state']

type Props = {
  row: { original: { state?: State } }
  cellProps?: TableCellProps
}

const stateToStatus = (state: State) => {
  switch (state) {
    case 'ACTIVE':
      return 'success'
    case 'DELETED':
    case 'TERMINATED':
    default:
      return 'alert'
  }
}

export const stateCellAccessor = ({ state }: { state?: State }) =>
  state ? capitalizeFirst(state) : EMPTY_VALUE_FALLBACK

export const StateCell = (props: Props) => {
  const { cellProps = { align: 'center' }, row } = props
  const { state } = row.original

  if (!state) {
    return <Table.Cell {...cellProps}>{EMPTY_VALUE_FALLBACK}</Table.Cell>
  }

  return (
    <Table.StatusCell {...cellProps} level={stateToStatus(state)}>
      {capitalizeFirst(state)}
    </Table.StatusCell>
  )
}
