import { Route, Routes } from 'react-router-dom'
import { baseRoute, ErrorPage, Url } from 'routing'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { PageVariant } from '@revolut/ui-kit'
import { SignIn } from './SignIn'
import { UILayout } from './Layout'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

const SIGN_IN_ERROR_DESCRIPTION =
  "Please, clean cookies and local storage and try to open an app and login again. If it doesn't help, please, contact the iDave team."

export const RootRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        path={Url.ToSignIn}
        Component={SignIn}
        errorElement={
          <ErrorPage
            variant={PageVariant.FOCUSED}
            errorDescription={SIGN_IN_ERROR_DESCRIPTION}
          />
        }
      />
      <Route path={baseRoute()} index Component={UILayout} errorElement={<ErrorPage />} />
    </SentryRoutes>
  )
}
