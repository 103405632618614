import { VStack } from '@revolut/ui-kit'
import { Group as TGroup } from 'api/idave/groups'
import { UserListItem } from 'api/idave/user'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useQueryGroup } from 'queries/idave/groups'
import { ParticipantList, ParticipantListSkeleton } from './components/ParticipantList'

export const Body = ({ groupId }: { groupId: string }) => {
  const { data, fetchStatus: groupFs, status: groupQS } = useQueryGroup(groupId)
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()
  const {
    data: userMap = new Map(),
    status: userMapQs,
    fetchStatus: userMapFs,
  } = useQueryUserIdMap()

  return (
    <QuerySwitch
      data={data}
      required={[
        { qs: userMapQs, fs: userMapFs },
        { qs: groupQS, fs: groupFs },
      ]}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view users or groups" />
      )}
      renderLoading={() => <ParticipantListSkeleton />}
      renderSuccess={({ data: group }) => (
        <Inner avatars={avatars} userMap={userMap} group={group} />
      )}
    />
  )
}

type InnerProps = {
  avatars?: Map<string, RevolutersAvatar>
  userMap: Map<string, UserListItem>
  group: TGroup
}

const Inner = ({ avatars, userMap, group }: InnerProps) => {
  const ownerIds = group.ownerIds || []
  const userIds = group.userIds || []

  return (
    <VStack space="s-24">
      <ParticipantList
        title="Owners"
        items={ownerIds}
        avatars={avatars}
        userMap={userMap}
      />
      <ParticipantList
        title="Participants"
        items={userIds}
        avatars={avatars}
        userMap={userMap}
      />
    </VStack>
  )
}
