import { Box, Subheader, textChain } from '@revolut/ui-kit'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { pluralForm } from 'utils/string'
import { EntityList } from 'components/EntityList'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { isAllSettled } from 'utils/query'
import { useCallback, useMemo } from 'react'
import { useEditPopup } from 'hooks/useEditPopup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'api/dart'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getItems } from './utils'
import { AddReviewerPopup } from './AddReviewerPopup'

export const CustomerApprovers = (props: {
  ids: string[]
  customerCompanyId: string
}) => {
  const { ids, customerCompanyId } = props
  const {
    data: userMap = new Map(),
    status: usersS,
    fetchStatus: usersFS,
  } = useQueryUserIdMap()
  const {
    data: avatars,
    status: avatarsS,
    fetchStatus: avatarsFS,
  } = useQueryMappedEmployeeIdAvatars()

  const isLoading = !isAllSettled(
    {
      fs: usersFS,
      qs: usersS,
    },
    {
      qs: avatarsS,
      fs: avatarsFS,
    },
  )

  const items = useMemo(() => getItems(ids, userMap, avatars), [ids, userMap, avatars])
  const { closePopup: closeEditPopup, setPopup: setEditPopup } = useEditPopup()

  const { showSuccessToast } = useToasts()
  const { closePopup, setPopup } = useConfirmationPopup()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const queryClient = useQueryClient()

  const { mutate: addApprover } = useMutation({
    mutationFn: (approverId: string) =>
      api.customerCompany.addCustomerCompanyApprover({
        id: customerCompanyId,
        approverId,
      }),
    onMutate: () => {
      showLoadingPopup({ title: 'Adding approver...' })
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Addition failed', error: err })
    },
    onSuccess: () => {
      hideLoadingPopup()
      closeEditPopup()
      showSuccessToast('Approver added')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CustomerCompany, customerCompanyId],
      })
    },
  })

  const { mutate: deleteApprover } = useMutation({
    mutationFn: (approverId: string) =>
      api.customerCompany.deleteCustomerCompanyApprover({
        id: customerCompanyId,
        approverId,
      }),
    onMutate: () => {
      showLoadingPopup({ title: 'Deleting approver...' })
    },
    onSuccess() {
      showSuccessToast('Approver deleted')
      closePopup()
      hideLoadingPopup()
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CustomerCompany, customerCompanyId],
      })
    },
    onError: (err) => {
      closePopup()
      hideLoadingPopup()
      showErrorPopup({ title: 'Deletion failed', error: err })
    },
  })

  const onDeleteClick = useCallback(
    (approverId: string) => {
      setPopup({
        title: 'Are you sure you want to delete company approver?',
        message: 'This user won’t be able to approve new company users.',
        confirmButtonText: 'Delete',
        onConfirmClick: () => deleteApprover(approverId),
      })
    },
    [setPopup, deleteApprover],
  )

  const onAddClick = useCallback(() => {
    setEditPopup({
      title: 'Add approver',
      content: <AddReviewerPopup onAddUser={addApprover} addLabel="Add approver" />,
    })
  }, [setEditPopup, addApprover])
  const { hasPermission, hasEveryPermission } = usePermissions()

  return (
    <Box>
      <Box pl="s-16">
        <Subheader variant="nested">
          <Subheader.Title>
            {textChain(
              pluralForm(ids, ['Company approver', 'Company approvers']),
              ids.length,
            )}
          </Subheader.Title>
        </Subheader>
      </Box>

      <EntityList
        items={items}
        showAvatars
        isLoading={isLoading}
        onAddClick={onAddClick}
        onDeleteClick={onDeleteClick}
        addEnabled={hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_UPDATE_APPROVER)}
        deleteEnabled={hasEveryPermission(
          DART_PERMISSIONS.CUSTOMER_COMPANIES_DELETE_APPROVER,
        )}
        addActionLabel="Add approver"
      />
    </Box>
  )
}
