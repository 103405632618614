import { Text } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { Role } from 'api/idave/roles'
import { SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useQueryPermissions, useQueryToxicPairs } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { useQueryRoleIdMap } from 'queries/idave/roles'
import { useEffect, useMemo, useState } from 'react'
import { waitForAll } from 'utils/query'
import { makeGetToxicRowState } from 'view/Roles/Role/utils'
import { getInheritedPermissions } from 'view/Roles/Role/RoleCreate/utils'
import { useSideBox } from 'view/SideBox/SideBox'
import { RolePermissionAlertBanner } from '../../../RolePermissionAlertBanner'
import { getColumns } from './columns'
import { getAddingPermissions, getToxicPairs } from './utils'

type RolePermissionsAddingProps = {
  role: Role
  isUploading: boolean
  addPermission: (permissions: Permission[]) => void
  closeOverlay: () => void
}

export const RolePermissionsAdding = (props: RolePermissionsAddingProps) => {
  const { role, isUploading, addPermission, closeOverlay } = props

  const { data: roleMap = new Map() } = useQueryRoleIdMap()

  const {
    data: permissions = [],
    status: permissionStatus,
    fetchStatus: permissionsFS,
  } = useQueryPermissions()

  const {
    data: clientMap = new Map(),
    status: clientStatus,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()
  const columns = useMemo(() => getColumns(clientMap), [clientMap])

  const permissionsToAdd = useMemo(
    () => getAddingPermissions({ role, permissions }),
    [role, permissions],
  )

  const { closeSide } = useSideBox()

  const loadingState = getLoadingState(
    waitForAll(
      [{ qs: permissionStatus, fs: permissionsFS }],
      [
        {
          qs: clientStatus,
          fs: clientsFS,
        },
      ],
    ),
    permissionsToAdd.length,
  )

  const allRolePermissions = useMemo(
    () =>
      getInheritedPermissions({ parent: role.parent, roleMap }).concat(role.permissions),
    [role, roleMap],
  )

  const { data: toxicPairs = [] } = useQueryToxicPairs()
  const [selected, setSelected] = useState<string[]>([])

  const alertBannerPairs = useMemo(
    () =>
      getToxicPairs({
        selected,
        permissions: allRolePermissions,
        toxicPairs,
      }),
    [selected, allRolePermissions, toxicPairs],
  )

  useEffect(() => {
    closeSide()
    return () => closeSide()
  }, [closeSide, selected])

  const getRowState = useMemo(
    () => makeGetToxicRowState(allRolePermissions.concat(selected), toxicPairs),
    [allRolePermissions, selected, toxicPairs],
  )

  return (
    <SelectEntitiesTableOverlay
      entitiesTypeLabel="Permissions"
      pluralForms={['permission', 'permissions']}
      entities={permissionsToAdd}
      columns={columns}
      loadingState={loadingState}
      title="Add permissions"
      SubtitleComponent={() => <Text>{role.name}</Text>}
      BannerComponent={() => (
        <RolePermissionAlertBanner viewCase="updating" toxicPair={alertBannerPairs} />
      )}
      pending={isUploading}
      onSelect={addPermission}
      onClose={closeOverlay}
      getRowState={getRowState}
      onSelectUpdate={setSelected}
      isSubmitDisabled={!!alertBannerPairs.length}
    />
  )
}
