import { Principal, ReviewRuleState } from './common'

export type Event =
  | ApprovedEvent
  | CommentedEvent
  | DeclinedEvent
  | ExecutedEvent
  | ExecutionFailedEvent
  | InfoProvidedEvent
  | InfoRequestedEvent
  | JustifiedEvent
  | RejectedEvent
  | RequestedEvent
  | ReviewedEvent
  | ReviewStageStateChangedEvent
  | ReviewGroupReviewersAddedEvent

enum EventType {
  approved = 'CrossCheckApprovedEvent',
  commented = 'CrossCheckCommentedEvent',
  declined = 'CrossCheckDeclinedEvent',
  executed = 'CrossCheckExecutedEvent',
  executionFailed = 'CrossCheckExecutionFailedEvent',
  infoProvided = 'CrossCheckInfoProvidedEvent',
  infoRequested = 'CrossCheckInfoRequestedEvent',
  justified = 'CrossCheckJustifiedEvent',
  rejected = 'CrossCheckRejectedEvent',
  requested = 'CrossCheckRequestedEvent',
  reviewed = 'CrossCheckReviewedEvent',
  groupReviewersAdded = 'CrossCheckReviewGroupReviewersAddedEvent',
  reviewStageChanged = 'CrossCheckReviewStageStateChangedEvent',
}

type BaseEvent = {
  id: string
  author: Principal
  createdDate: number
  type: EventType
}
type TypedEvent<T extends EventType> = BaseEvent & {
  type: T
}
type TextEvent<T extends EventType> = BaseEvent & {
  type: T
  text?: string
}

/** Crosscheck review is finished and xCheck approved */
export type ApprovedEvent = TypedEvent<EventType.approved>
/** Crosscheck review is declined by requester and xCheck rejected */
export type DeclinedEvent = TypedEvent<EventType.declined>
/** Crosscheck review is finished and xCheck rejected */
export type RejectedEvent = TypedEvent<EventType.rejected>
/** Crosscheck action is executed */
export type ExecutedEvent = TypedEvent<EventType.executed>
/** Additional info requested  */
export type RequestedEvent = TypedEvent<EventType.requested>
/** Changing xCheck stage for multistage rules  */
export type ReviewStageStateChangedEvent = TypedEvent<EventType.reviewStageChanged> & {
  reviewStageId: string
  reviewStageName: string
  oldState: ReviewRuleState
  newState: ReviewRuleState
}

/** A user added to a review group */
export type ReviewGroupReviewersAddedEvent = TextEvent<EventType.groupReviewersAdded> & {
  groupId: string
  reviewers: Principal[]
}
/** A user commented xCheck */
export type CommentedEvent = TextEvent<EventType.commented>
/** A user requested additional info for a xCheck */
export type InfoRequestedEvent = TextEvent<EventType.infoRequested>
/** A user provided additional info for a xCheck */
export type InfoProvidedEvent = TextEvent<EventType.infoProvided>
/** Requester added justification info for a xCheck */
export type JustifiedEvent = TextEvent<EventType.justified>
/** Execution of action failed on side of BE owning xCheck */
export type ExecutionFailedEvent = TextEvent<EventType.executionFailed>
/** A user reviewed a crosscheck */
export type ReviewedEvent = TextEvent<EventType.reviewed> & {
  decision?: 'APPROVE' | 'REJECT'
}
