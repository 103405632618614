import { Principal, ReviewRuleState } from './common'

export enum ReviewTypeDescriptor {
  and = 'AND',
  multiStage = 'MULTISTAGE',
  or = 'OR',
  group = 'GROUP',
  counting = 'COUNTING',
  noRejections = 'NO_REJECTIONS',
  custom = 'CUSTOM',
}

type BaseReviewRule = {
  id: string
  name: string
  state: ReviewRuleState
  type: ReviewTypeDescriptor
}

export type AndReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.and
  reviewRules: ReviewRule[]
}

export type MultistageReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.multiStage
  reviewRules: ReviewRule[]
}

export type OrReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.or
  reviewRules: ReviewRule[]
}

export type GroupReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.group
  config: {
    approvals: number
    reviewers: Principal[]
  }
}

export type CountingReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.counting
  config: {
    approvals: number
  }
}

export type NoRejectionsReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.noRejections
}

export type CustomReviewRule = BaseReviewRule & {
  type: ReviewTypeDescriptor.custom
  config: object
}

export type ReviewRule =
  | MultistageReviewRule
  | AndReviewRule
  | OrReviewRule
  | GroupReviewRule
  | CountingReviewRule
  | NoRejectionsReviewRule
  | CustomReviewRule
