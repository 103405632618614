import { SAMLog } from 'api/sam/auditLogs'
import { compareDesc } from 'date-fns'
import { assertNonReachable } from 'utils/error'

export const stringifyLogType = (logType: SAMLog['action']) => {
  switch (logType) {
    case 'ACCESS_POLICY_CREATED':
      return 'Policy created'
    case 'ACCESS_POLICY_DELETED':
      return 'Policy deleted'
    case 'ACCESS_POLICY_RESOURCES_SET':
      return 'Policy resources updated'
    case 'ACCESS_POLICY_UPDATED':
      return 'Policy updated'
    default:
      return assertNonReachable(logType)
  }
}

export const sortLogsByDate = (logs: SAMLog[]) =>
  [...logs].sort(({ created_at: dateA }, { created_at: dateB }) =>
    compareDesc(new Date(dateA), new Date(dateB)),
  )
