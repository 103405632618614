/* eslint-disable prettier/prettier */
export const DART_PERMISSIONS = {
  AUDIT_LOG_VIEW_LIST: {
    endpoints: [
      'GET /audit-log'
    ],
    service: 'dart',
    value: 'audit_log.view.list'
  },
  CROSS_CHECKS_COMMENT: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/comment'
    ],
    service: 'dart',
    value: 'cross_checks.comment'
  },
  CROSS_CHECKS_DECLINE: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/decline'
    ],
    service: 'dart',
    value: 'cross_checks.decline'
  },
  CROSS_CHECKS_JUSTIFY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/justify'
    ],
    service: 'dart',
    value: 'cross_checks.justify'
  },
  CROSS_CHECKS_RETRY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/retry'
    ],
    service: 'dart',
    value: 'cross_checks.retry'
  },
  CROSS_CHECKS_REVIEW: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/review'
    ],
    service: 'dart',
    value: 'cross_checks.review'
  },
  CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS: {
    endpoints: [
      'POST /cross-checks/groups/:crossCheckGroupId/reviewers'
    ],
    service: 'dart',
    value: 'cross_checks.update.groups.reviewers'
  },
  CROSS_CHECKS_UPDATE_PARAMS: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/params'
    ],
    service: 'dart',
    value: 'cross_checks.update.params'
  },
  CROSS_CHECKS_VIEW_DETAILS: {
    endpoints: [
      'GET /cross-checks/:crossCheckId'
    ],
    service: 'dart',
    value: 'cross_checks.view.details'
  },
  CROSS_CHECKS_VIEW_GROUPS_DETAILS: {
    endpoints: [
      'GET /cross-checks/groups/:crossCheckGroupId'
    ],
    service: 'dart',
    value: 'cross_checks.view.groups.details'
  },
  CROSS_CHECKS_VIEW_LIST: {
    endpoints: [
      'GET /cross-checks'
    ],
    service: 'dart',
    value: 'cross_checks.view.list'
  },
  CROSS_CHECKS_VIEW_PARAMS_LIST: {
    endpoints: [
      'GET /cross-checks/:crossCheckId/params'
    ],
    service: 'dart',
    value: 'cross_checks.view.params.list'
  },
  CUSTOMER_COMPANIES_DELETE_APPROVER: {
    endpoints: [
      'DELETE /customer-companies/:companyId/approver/:employeeId'
    ],
    service: 'dart',
    value: 'customer_companies.delete.approver'
  },
  CUSTOMER_COMPANIES_DELETE_OWNER: {
    endpoints: [
      'DELETE /customer-companies/:companyId/owner/:employeeId'
    ],
    service: 'dart',
    value: 'customer_companies.delete.owner'
  },
  CUSTOMER_COMPANIES_UPDATE_APPROVER: {
    endpoints: [
      'POST /customer-companies/:companyId/approver/:employeeId'
    ],
    service: 'dart',
    value: 'customer_companies.update.approver'
  },
  CUSTOMER_COMPANIES_UPDATE_OWNER: {
    endpoints: [
      'POST /customer-companies/:companyId/owner/:employeeId'
    ],
    service: 'dart',
    value: 'customer_companies.update.owner'
  },
  CUSTOMER_COMPANIES_VIEW_DETAILS: {
    endpoints: [
      'GET /customer-companies/:companyId'
    ],
    service: 'dart',
    value: 'customer_companies.view.details'
  },
  CUSTOMER_COMPANIES_VIEW_LIST: {
    endpoints: [
      'GET /customer-companies'
    ],
    service: 'dart',
    value: 'customer_companies.view.list'
  },
  EMPLOYEES_DELETE_ACCESS_MODIFIER: {
    endpoints: [
      'POST /dart/employees/access-modifiers/delete'
    ],
    service: 'dart',
    value: 'employees.delete.access_modifier'
  },
  EMPLOYEES_DELETE_ACCESS_MODIFIER_CROSS_CHECK: {
    endpoints: [
      'POST /dart/employees/access-modifiers/delete'
    ],
    service: 'dart',
    value: 'employees.delete.access_modifier.cross_check'
  },
  EMPLOYEES_UPDATE_ACCESS_MODIFIER: {
    endpoints: [
      'POST /dart/employees/access-modifiers/add'
    ],
    service: 'dart',
    value: 'employees.update.access_modifier'
  },
  EMPLOYEES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK: {
    endpoints: [
      'POST /dart/employees/access-modifiers/add'
    ],
    service: 'dart',
    value: 'employees.update.access_modifier.cross_check'
  },
  EMPLOYEES_VIEW_ALLOWED_COMPANIES: {
    endpoints: [
      'GET /employees/:employeeId/allowed-companies'
    ],
    service: 'dart',
    value: 'employees.view.allowed.companies'
  },
  EMPLOYEES_VIEW_DETAILS: {
    endpoints: [
      'GET /dart/employees/details/:employeeId'
    ],
    service: 'dart',
    value: 'employees.view.details'
  },
  EMPLOYEES_VIEW_DETAILS_LIMITED: {
    endpoints: [
      'GET /dart/employees/details/:employeeId'
    ],
    service: 'dart',
    value: 'employees.view.details.limited'
  },
  EMPLOYEES_VIEW_DISPOSABLE_ACCESS: {
    endpoints: [
      'GET /dart/employees/details/:employeeId/disposable-access'
    ],
    service: 'dart',
    value: 'employees.view.disposable.access'
  },
  EMPLOYEES_VIEW_LIST: {
    endpoints: [
      'GET /dart/employees'
    ],
    service: 'dart',
    value: 'employees.view.list'
  },
  EMPLOYEE_COMPANIES_DELETE_ACCESS_MODIFIER: {
    endpoints: [
      'POST /dart/employee-companies/access-modifiers/delete'
    ],
    service: 'dart',
    value: 'employee_companies.delete.access_modifier'
  },
  EMPLOYEE_COMPANIES_DELETE_ACCESS_MODIFIER_CROSS_CHECK: {
    endpoints: [
      'POST /dart/employee-companies/access-modifiers/delete'
    ],
    service: 'dart',
    value: 'employee_companies.delete.access_modifier.cross_check'
  },
  EMPLOYEE_COMPANIES_UPDATE_ACCESS_MODIFIER: {
    endpoints: [
      'POST /dart/employee-companies/access-modifiers/add'
    ],
    service: 'dart',
    value: 'employee_companies.update.access_modifier'
  },
  EMPLOYEE_COMPANIES_UPDATE_ACCESS_MODIFIER_CROSS_CHECK: {
    endpoints: [
      'POST /dart/employee-companies/access-modifiers/add'
    ],
    service: 'dart',
    value: 'employee_companies.update.access_modifier.cross_check'
  },
  EMPLOYEE_COMPANIES_VIEW_LIST: {
    endpoints: [
      'GET /dart/employee-companies',
      'GET /employee-companies'
    ],
    service: 'dart',
    value: 'employee_companies.view.list'
  },
  OAUTH2_VIEW_BACKUP_DETAILS: {
    endpoints: [
      'GET /idave/oauth2/backup'
    ],
    service: 'dart',
    value: 'oauth2.view.backup.details'
  },
  PERMISSIONS_VIEW_ACL_LIST: {
    endpoints: [
      'GET /permissions/acl'
    ],
    service: 'dart',
    value: 'permissions.view.acl.list'
  },
  TEST_USERS_CREATE: {
    endpoints: [
      'POST /test-users'
    ],
    service: 'dart',
    value: 'test_users.create'
  },
  TEST_USERS_CREATE_CROSS_CHECK: {
    endpoints: [
      'POST /test-users'
    ],
    service: 'dart',
    value: 'test_users.create.cross_check'
  },
  TEST_USERS_VIEW_LIST: {
    endpoints: [
      'GET /test-users'
    ],
    service: 'dart',
    value: 'test_users.view.list'
  },
  WHOAMI_VIEW_DETAILS: {
    endpoints: [
      'GET /whoami'
    ],
    service: 'dart',
    value: 'whoami.view.details'
  }
} as const