import { EmployeeType } from 'api/sam/policies'
import { capitalizeFirst } from 'utils/string'

export const EMPLOYEE_TYPE_OPTIONS = [
  {
    key: EmployeeType.External,
    label: capitalizeFirst(EmployeeType.External),
    value: EmployeeType.External,
  },
  {
    key: EmployeeType.Internal,
    label: capitalizeFirst(EmployeeType.Internal),
    value: EmployeeType.Internal,
  },
]
