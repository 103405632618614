import {
  IDAVE_PERMISSIONS,
  SAM_PERMISSIONS,
  DART_PERMISSIONS,
  X_CHECKS_PERMISSIONS,
} from 'security'
import { Url } from 'routing'
import { notNullableMap } from 'utils/array'
import { IconName } from '@revolut/ui-kit'
import { ServicePermission } from 'services/permissions'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { NavLink } from 'react-router-dom'
import { omit } from 'lodash'
import { IS_DEVELOPMENT_ENV } from 'utils/constants/misc'

type MenuConfig = MenuItem[]
type MenuItem = PermissionItem | SomePermissionItem
type Item = {
  title: string
  icon: IconName
  id: string
  to: string
  use: typeof NavLink
}
type PermissionItem = Item & {
  permission: ServicePermission
}
type SomePermissionItem = Item & {
  somePermissions: ServicePermission[]
}

const MENU_CONFIG: MenuConfig = [
  {
    title: 'Users',
    icon: 'People',
    id: 'users',
    to: Url.Users,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.USERS_VIEW_LIST,
  },
  {
    title: 'Apps',
    icon: 'Apps',
    id: 'apps',
    to: Url.Clients,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST,
  },
  {
    title: 'Roles',
    icon: '40/SubtractGroupVault',
    id: 'roles',
    to: Url.Roles,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.ROLES_VIEW_LIST,
  },
  {
    title: 'Permissions',
    icon: 'Controls',
    id: 'permissions',
    to: Url.Permissions,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST,
  },
  {
    title: 'Orgs',
    id: 'orgs',
    icon: 'Bank',
    to: Url.Organisations,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.ORGANISATIONS_VIEW_LIST,
  },
  {
    title: 'Requests',
    id: 'requests',
    icon: 'CheckSuccess',
    to: Url.CrossChecks,
    use: NavLink,
    somePermissions: [
      IDAVE_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
      SAM_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
      DART_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
    ],
  },
  {
    title: 'Requests v3',
    id: 'requestsV3',
    icon: 'CheckSuccess',
    to: Url.XChecks,
    use: NavLink,
    permission: X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_LIST,
  },
  {
    title: 'SAM',
    id: 'sam',
    icon: 'Key',
    to: Url.Sam,
    use: NavLink,
    somePermissions: [
      SAM_PERMISSIONS.POLICIES_VIEW_LIST,
      SAM_PERMISSIONS.RESOURCES_VIEW_LIST,
    ],
  },
  {
    title: 'Data access',
    icon: 'LockClosed',
    to: Url.Dart,
    id: 'dart',
    use: NavLink,
    somePermissions: [
      DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST,
      DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST,
      DART_PERMISSIONS.EMPLOYEES_VIEW_LIST,
    ],
  },
  {
    title: 'Logs',
    icon: 'ArrowRepeat',
    id: 'logs',
    to: Url.AuditLogs,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST,
  },
  {
    title: 'System',
    id: 'system',
    icon: 'Gear',
    to: Url.System,
    use: NavLink,
    permission: IDAVE_PERMISSIONS.GROUPS_VIEW_LIST,
  },
]

export const useMenu = () => {
  const { hasPermission, hasSomePermissions } = usePermissions()

  return notNullableMap(MENU_CONFIG, (item) => {
    if (item.id === 'requestsV3' && !IS_DEVELOPMENT_ENV) {
      return undefined
    }

    if ('permission' in item) {
      return hasPermission(item.permission) ? omit(item, 'permission') : undefined
    }

    if ('somePermissions' in item) {
      return hasSomePermissions(...item.somePermissions)
        ? omit(item, 'somePermissions')
        : undefined
    }

    return undefined
  })
}
