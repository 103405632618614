import { useCurrentUser } from 'queries/idave/users'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { getAvatarUrl } from 'utils/url/avatarUrl'

export const useMenuAuth = () => {
  const { data: user } = useCurrentUser()
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()

  const navigate = useNavigate()
  const getUser = useCallback(
    () =>
      user
        ? { ...user, avatarUrl: getAvatarUrl({ user, avatars, type: 'imageMedium' }) }
        : null,
    [user, avatars],
  )

  const logout = useCallback(() => navigate(Url.Logout), [navigate])

  return useMemo(
    () => ({
      logout,
      getUser,
    }),
    [logout, getUser],
  )
}
