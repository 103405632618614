import { MoreBar, TableColumn, Text } from '@revolut/ui-kit'
import { SamTraining } from 'api/sam/trainings'
import { useQueryTrainingMap, useQueryTrainings } from 'queries/sam/resources'
import { QueryResult, mergeQueryStatuses } from 'utils/query'
import { useCallback, useMemo, useState } from 'react'
import { notNullableMap } from 'utils/array'
import { useSearchFilter } from 'hooks/useSearchFilter'
import {
  filterActiveIds,
  filterInactiveIds,
  getLoadingState,
} from 'components/EntitiesTable/utils'
import { EntitiesTable, SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import { uniq } from 'lodash'
import { SamPolicy } from 'api/sam/policies'
import { EmptyStatusWidget } from 'components/EmptyStatusWidget'

type SamPolicyTrainingProps = {
  policyTrainigns: SamPolicy['mandatoryTrainings']
  policyStatus?: QueryResult
  isPending?: boolean
  isActive: boolean
  showAddPermissions: boolean
  showDeletePermissions: boolean
  emptyStatusTitle?: string
  policyName: string
  updateTrainings: (trainings: SamPolicy['mandatoryTrainings']) => void
}

const COLUMNS: TableColumn<SamTraining & { id: string }>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'includesValue',
  },
  {
    Header: 'Code',
    accessor: 'code',
    filter: 'includesValue',
  },
]

export const SamPolicyTrainings = ({
  policyTrainigns,
  policyStatus = 'success',
  isPending,
  isActive,
  showAddPermissions,
  showDeletePermissions,
  emptyStatusTitle,
  policyName,
  updateTrainings,
}: SamPolicyTrainingProps) => {
  const {
    data: trainings,
    status: traingingStatus,
    fetchStatus: trainingsFS,
  } = useQueryTrainings()
  const { data: trainingMap = new Map<string, SamTraining>() } = useQueryTrainingMap()

  const status = mergeQueryStatuses({ qs: traingingStatus, fs: trainingsFS })
  const trainingList = useMemo(
    () =>
      (policyTrainigns || []).map((trainingId) => {
        const training = trainingMap.get(trainingId)
        if (training) {
          return { ...training, id: training.code }
        }

        return {
          id: trainingId,
          code: trainingId,
          name: 'Unknown training',
        }
      }),
    [policyTrainigns, trainingMap],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: trainingList,
  })

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((v) => !v),
    [setShowSelected],
  )
  const onRemoveClick = useCallback(() => {
    const mandatoryTrainings = filterInactiveIds(trainingList, selectedHash)
    updateTrainings(mandatoryTrainings)
  }, [updateTrainings, selectedHash, trainingList])

  const onAddClick = useCallback(
    (newTrainings: { name: string; code: string }[]) => {
      updateTrainings(uniq([...trainingList, ...newTrainings].map((i) => i.code)))
    },
    [trainingList, updateTrainings],
  )

  const [view, setView] = useState<'added' | 'available'>('added')
  const onShowAvailableClick = useCallback(() => {
    setView('available')
  }, [])
  const onShowAddedClick = useCallback(() => setView('added'), [])

  const availbaleTrainings = useMemo(
    () =>
      notNullableMap(trainings || [], (training) => {
        return trainingList.find((addedTraining) => addedTraining.code === training.code)
          ? undefined
          : { ...training, id: training.code }
      }),
    [trainings, trainingList],
  )

  const selectedCount = filterActiveIds(selectedHash).length
  const disableAddAction = trainingList.length >= (trainings?.length || 0)
  const showEmptyStatus = policyStatus === 'success' && !policyTrainigns?.length

  return (
    <>
      {view === 'available' && (
        <SelectEntitiesTableOverlay
          columns={COLUMNS}
          entitiesTypeLabel="Trainings"
          pluralForms={['training', 'trainings']}
          loadingState={getLoadingState(status, availbaleTrainings.length)}
          entities={availbaleTrainings}
          onClose={onShowAddedClick}
          title="Add trainings"
          onSelect={onAddClick}
          pending={isPending}
          SubtitleComponent={() => <Text>{policyName}</Text>}
        />
      )}

      {showEmptyStatus ? (
        <EmptyStatusWidget
          actionLabel="Add trainings"
          title={emptyStatusTitle || 'No trainings added to this policy'}
          imageCode="3D070"
          onClick={onShowAvailableClick}
          actionAllowed={showAddPermissions}
          disabled={false}
        />
      ) : (
        <EntitiesTable
          totalCount={trainingList.length}
          entitiesTypeLabel="Trainings"
          pluralForms={['training', 'trainings']}
          columns={COLUMNS}
          data={searched}
          loadingState={getLoadingState(status, trainingList.length)}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchAutoFocus
          selectedHash={selectedHash}
          switchShowSelected={switchShowSelected}
          showSelected={showSelected}
          setSelectedHash={isActive ? setSelectedHash : undefined}
          labelNoResults="No trainings added"
          renderActions={
            isActive
              ? getActionsRender({
                  selectedCount,
                  showAddPermissions,
                  showDeletePermissions,
                  disableAddAction,
                  onRemoveClick,
                  onAddClick: onShowAvailableClick,
                })
              : undefined
          }
        />
      )}
    </>
  )
}

const getActionsRender =
  (params: {
    selectedCount: number
    showAddPermissions: boolean
    showDeletePermissions: boolean
    disableAddAction: boolean
    onRemoveClick: () => void
    onAddClick: () => void
  }) =>
  () => {
    const {
      selectedCount,
      showAddPermissions,
      showDeletePermissions,
      disableAddAction,
      onAddClick,
      onRemoveClick,
    } = params

    return (
      <>
        {!!selectedCount && showDeletePermissions && (
          <MoreBar.Action variant="negative" onClick={onRemoveClick} useIcon="Delete">
            Remove
          </MoreBar.Action>
        )}

        {!selectedCount && showAddPermissions && (
          <MoreBar.Action useIcon="Plus" onClick={onAddClick} disabled={disableAddAction}>
            Add trainings
          </MoreBar.Action>
        )}
      </>
    )
  }
