import { CrossCheckDetail, DartAccessModifierExecutableParam } from 'api/types/crossCheck'
import { CustomerCompany } from 'api/dart/customerCompanies'
import { UserListItem } from 'api/idave/user'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { CrossCheckValueTab } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/types'
import { getRows } from 'view/Dart/CustomerCompany/components/AllowedUsers/AllowedUserList/utils'
import { EmployeeRevoDescriptionGetter } from 'hooks/useGetEmployeeRevoDescription'
import { getChangeRowsInfo } from '../../../../utils'
import { EmployeeRow } from './columns'
import { getAccessModifiersInfo } from '../../utils'

type GetAccessModifierRowsParams = {
  crossCheck: CrossCheckDetail
  entity?: CustomerCompany
  userMap: Map<string, UserListItem>
  tabs: CrossCheckValueTab[]
  getEmployeeDescription: EmployeeRevoDescriptionGetter
}

export const getAccessModifierRows = (params: GetAccessModifierRowsParams) => {
  const { crossCheck, entity, userMap, getEmployeeDescription, tabs } = params

  const { accessModifiers: newAccessModifiers, actionType } =
    getAccessModifiersInfo(crossCheck)

  const ids = newAccessModifiers.map((v) => 'employeeId' in v && v.employeeId) as string[]

  const rows = getRows({
    userMap,
    customerCompany: entity,
    employeesAccesses: [],
    getEmployeeDescription,
  })

  const added = actionType === 'added' ? ids : []
  const deleted = actionType === 'removed' ? ids : []
  const values: string[] = []
  const current = rows.map((v) => v.id)

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    userMap,
    customerCompany: entity,
    getEmployeeDescription,
    newAccessModifiers,
  })
}

const makeMapValue =
  ({
    userMap,
    deletedHash = {},
    addedHash = {},
    customerCompany,
    getEmployeeDescription,
    newAccessModifiers,
  }: {
    userMap: Map<string, UserListItem>
    deletedHash?: Record<string, boolean>
    addedHash?: Record<string, boolean>
    customerCompany?: CustomerCompany
    getEmployeeDescription: EmployeeRevoDescriptionGetter
    newAccessModifiers: DartAccessModifierExecutableParam[]
  }) =>
  (employeeId: string): EmployeeRow => {
    const user = userMap.get(employeeId)
    const allowedEmployee = customerCompany?.allowedEmployees.find(
      (v) => v.id === employeeId,
    )

    const removedStatus = deletedHash[employeeId] ? 'removed' : undefined
    const addedStatus = addedHash[employeeId] ? 'added' : undefined

    const newAccessModifier = newAccessModifiers.find(
      (v) => 'employeeId' in v && v.employeeId === employeeId,
    )

    return {
      id: employeeId,
      email: user?.email || EMPTY_VALUE_FALLBACK,
      team: user
        ? getEmployeeDescription(user.id)?.team?.name || EMPTY_VALUE_FALLBACK
        : EMPTY_VALUE_FALLBACK,
      state: user?.state,
      expirationDate:
        newAccessModifier?.accessModifier.expirationDate ||
        allowedEmployee?.allowedAccessModifier.expirationDate,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyTableLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? 'No added or deleted users'
    : 'No user has access to the customer company'
