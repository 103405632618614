import { Action } from 'components/HeaderActions/types'
import { Url } from 'routing'
import { addActionsToPages } from './utils'

const ROOT_PAGE_ACTIONS: Action[] = [{ type: 'info', page: 'rootPage' }]
const ROOT_PAGES = [
  Url.Users,
  Url.Clients,
  Url.Roles,
  Url.Permissions,
  Url.Organisations,
  Url.CrossChecksToReview,
  Url.CrossChecksAll,
  Url.CrossChecksRequested,
  Url.SamPolicies,
  Url.SamResources,
  Url.SamTasks,
  Url.DartCustomerCompanies,
  Url.DartEmployeeCompanies,
  Url.DartUserAccesses,
  Url.DartUserRestrictions,
  Url.DartAuditLogs,
  Url.AuditLogs,
  Url.System,
]

const USER_PAGE_ACTIONS: Action[] = [{ type: 'info', page: 'userPage' }]
const USER_PAGES = [
  Url.UserProfile,
  Url.UserRoles,
  Url.UserPolicies,
  Url.UserPermissions,
  Url.UserDataAccess,
  Url.UserAuditLogs,
  Url.UserDataLogs,
  Url.UserToxicAlerts,
]

const PERMISSION_PAGE_ACTIONS: Action[] = [{ type: 'info', page: 'permissionPage' }]
const PERMISSION_PAGES = [
  Url.PermissionDetails,
  Url.PermissionRoles,
  Url.PermissionRoles,
  Url.PermissionUsers,
  Url.PermissionAuditLogs,
]

const CLIENT_PAGE_ACTIONS: Action[] = [{ type: 'info', page: 'clientPage' }]
const CLIENT_PAGES = [Url.ClientDetails, Url.ClientPermissions, Url.ClientAuditLogs]

export const ACTION_CONFIG: Record<string, Action[]> = {
  // Root info
  ...addActionsToPages(ROOT_PAGES, ROOT_PAGE_ACTIONS),
  // Create pages info
  [Url.SamPoliciesCreate]: [{ type: 'info', page: 'createPolicy', autoOpen: true }],
  [Url.SamPoliciesCreateCopy]: [{ type: 'info', page: 'createPolicy', autoOpen: true }],
  [Url.AddRole]: [{ type: 'info', page: 'createRole', autoOpen: true }],
  [Url.ClientCreate]: [{ type: 'info', page: 'createClient', autoOpen: true }],

  // Edit pages info
  [Url.PermissionEdit]: [{ type: 'info', page: 'updatePermission', autoOpen: true }],
  [Url.SamPoliciesEdit]: [{ type: 'info', page: 'updatePolicy', autoOpen: true }],
  [Url.EditRole]: [{ type: 'info', page: 'updateRole', autoOpen: true }],
  [Url.ClientEdit]: [{ type: 'info', page: 'updateClient', autoOpen: true }],

  // Details
  ...addActionsToPages(USER_PAGES, USER_PAGE_ACTIONS),
  [Url.UserRoles]: [{ type: 'info', page: 'userRoles' }],
  ...addActionsToPages(PERMISSION_PAGES, PERMISSION_PAGE_ACTIONS),
  ...addActionsToPages(CLIENT_PAGES, CLIENT_PAGE_ACTIONS),

  // Other
  [Url.CrossCheckIdaveDetails]: [{ type: 'info', page: 'xCheckAssignRole' }],
}
