import { useMemo } from 'react'
import { Box, Subheader } from '@revolut/ui-kit'
import { DetailRow, DetailsGroup } from 'components/DetailsGroup'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { MAXIMAL_DETAILS_WIDTH } from 'constants/ui'
import { QuerySwitch } from 'components/QuerySwitch'
import { CopyableText } from 'components/CopyableText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { makeSubjectTypeRows } from './utils'
import { SamPolicyDetailsActions } from './SamPolicyDetailsActions'

type SamPolicyDetailsProps = {
  id: string
  isActive: boolean
}

export const SamPolicyDetails = ({ id, isActive }: SamPolicyDetailsProps) => {
  return (
    <>
      {isActive && <SamPolicyDetailsActions id={id} />}
      <Box maxWidth={MAXIMAL_DETAILS_WIDTH}>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <Details id={id} />
      </Box>
    </>
  )
}

const Details = ({ id }: { id: string }) => {
  const { data: policy, refetch, status, fetchStatus } = useQuerySamPolicy(id)
  const mainDetails = useMemo<DetailRow[]>(
    () => [
      ['Name', policy?.policyName],
      ['Description', policy?.businessReason || EMPTY_VALUE_FALLBACK],
      ...makeSubjectTypeRows(policy?.subject),
      ['Policy ID', <CopyableText value={id} key={id} />],
    ],
    [policy, id],
  )

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={policy}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={() => <DetailsGroup rows={mainDetails} />}
    />
  )
}
