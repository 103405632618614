import { XCheckDetails } from 'api/types/xChecks'
import { ExpandableCell, List } from '@revolut/ui-kit'
import { Data, EntityComponent } from '../../../types'
import { DefaultEntityView } from '../../../utils'

type Props = {
  xCheck: Data<XCheckDetails>
  Entity?: EntityComponent
}

export const Entities = ({ xCheck, Entity = DefaultEntityView }: Props) => {
  if (xCheck.status === 'loading') {
    return null
  }

  if (xCheck.status === 'error' || xCheck.status === 'forbidden') {
    return null
  }

  if (!xCheck.data.entities.length) {
    return null
  }

  return (
    <ExpandableCell variant="header">
      <ExpandableCell.Title>Entities</ExpandableCell.Title>
      <ExpandableCell.Note>
        <List variant="compact" space="s-16">
          {xCheck.data.entities.map((entity) => (
            <>
              <List.Item key={entity.id}>
                <Entity entity={entity} context="entityList" />
              </List.Item>
            </>
          ))}
        </List>
      </ExpandableCell.Note>
    </ExpandableCell>
  )
}
